/*eslint-disable*/
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { removeUser } from "../Actions/authAction";
import {
  Layout,
  Menu,
  Avatar,
  Dropdown,
  Breadcrumb,
  Affix,
  Typography,
  Image,
} from "antd";
import {
  SettingOutlined,
  ApartmentOutlined,
  TeamOutlined,
  HomeOutlined,
  UserOutlined,
  DashboardOutlined,
  FullscreenExitOutlined,
  UserAddOutlined,
  InboxOutlined,
  ProjectOutlined,
  PropertySafetyOutlined,
  BoxPlotOutlined,
  ShopOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import Dashboard from "../pages/Dashboard";
import Clients from "../pages/clients";
import Properties from "../pages/properties";
import Areas from "../pages/areas";
import Leads from "../pages/leads";
import Tasks from "../pages/tasks";
import InBoundProperties from "../pages/inboundProperty";
import EmployeeUsers from "../pages/employeeUsers";
import ResidentialProjects from "../pages/ResidentialProjects";
import CommercialProjects from "../pages/CommercialProjects";
import LandProjects from "../pages/LandProjects";
import RentResidentialProperties from "../pages/RentResidentialProperties";
import BunglowProperties from "../pages/BunglowProperties";
import FlatProperties from "../pages/FlatProperties";
import RentCommercialProperties from "../pages/RentCommercialProperties";
import CommercialProperties from "../pages/CommercialProperties";
import LandProperties from "../pages/LandProperties";
import Activity from "../pages/Activity";
import BunglowDeveloperProperties from "../pages/BunglowDeveloperProperties";
import FlatDeveloperProperties from "../pages/FlatDeveloperProperties";
import CommercialDevelporProperies from "../pages/CommercialDevelporProperies";
import LandDeveloperProperties from "../pages/LandDeveloperProperties";
import { ReactComponent as DeveloperIcon } from "../assets/images/developer.svg";
import { ReactComponent as BuilderIcon } from "../assets/images/builder.svg";
import { getCurrentUser } from "../Actions/usersAction";
import useResizeObserver from "./useResizeObserver";
import { debounce } from "lodash";
import { useBreakpoints } from "./ResponsiveBreakPoints";

const { Header, Content, Sider } = Layout;
export default (props) => {
  const { itemKeyValue } = props;
  const { isMobile, isTablet, isDesktop } = useBreakpoints();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth);
  const { currentUser } = useSelector((state) => {
    const { currentUser } = state.users;
    return { currentUser };
  });
  const [collapsed, setCollapsed] = useState(false);
  const { role } = currentUser;
  const { logout, ...p } = useAuth0();
  const token = localStorage.getItem("access_token");
  // const {}
  useEffect(() => {
    if (token) {
      dispatch(getCurrentUser());
    }
  }, [token]);

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
    localStorage.removeItem("access_token");
    dispatch(removeUser());
  };

  const [itemKey, setItemKey] = useState(itemKeyValue ? itemKeyValue : "1");
  const [size, setSize] = useState({ width: 0, height: 0 });
  // const menuItemAlign = { display: 'flex', alignItems: 'center', fontSize: 18 };

  const items = [
    {
      key: "1",
      label: <Typography onClick={handleLogout}>Logout</Typography>,
    },
  ];
  const { SubMenu } = Menu;
  const menuItems = [
    {
      label: "Dashboard",
      key: "1",
      icon: <DashboardOutlined />,
    },
    {
      label: "Clients",
      key: "2",
      icon: <TeamOutlined />,
    },
    {
      label: "Developers",
      key: "23",
      icon: <DeveloperIcon />,
    },
    {
      label: "Properties",
      key: "3",
      icon: <PropertySafetyOutlined />,
      children: [
        {
          label: "Bunglow",
          key: "4",
          icon: <HomeOutlined />,
        },
        {
          label: "Flat",
          key: "5",
          icon: <ShopOutlined />,
        },
        {
          label: "Rent Residential",
          key: "6",
          icon: <HomeOutlined />,
        },
        {
          label: "Commercial",
          key: "7",
          icon: <ShopOutlined />,
        },
        {
          label: "Rent Commercial",
          key: "8",
          icon: <HomeOutlined />,
        },
        {
          label: "Land",
          key: "9",
          icon: <BoxPlotOutlined />,
          role: "Admin",
        },
      ],
    },
    {
      label: "Builder",
      key: "18",
      icon: <BuilderIcon />,

      children: [
        {
          label: "Bunglow",
          key: "19",
          icon: <HomeOutlined />,
        },
        {
          label: "Flat",
          key: "20",
          icon: <ShopOutlined />,
        },

        {
          label: "Commercial",
          key: "21",
          icon: <ShopOutlined />,
        },

        {
          label: "Land",
          key: "22",
          icon: <BoxPlotOutlined />,
          role: "Admin",
        },
      ],
    },
    {
      label: "Leads",
      key: "10",
      icon: <ApartmentOutlined />,
    },
    {
      label: "Projects",
      key: "11.1",
      icon: <ProjectOutlined />,
      children: [
        {
          label: "Residential Projects",
          key: "11",
          icon: <HomeOutlined />,
        },
        {
          label: "Commercial Projects",
          key: "12",
          icon: <ShopOutlined />,
        },
        {
          label: "Land Projects",
          key: "13",
          icon: <BoxPlotOutlined />,
        },
      ],
    },
    {
      label: "Settings",
      key: "14.1",
      icon: <SettingOutlined />,
      children: [
        {
          label: "Inbound Property",
          key: "14",
          icon: <InboxOutlined />,
        },
        {
          label: "Areas",
          key: "15",
          icon: <FullscreenExitOutlined />,
        },
        {
          label: "Users",
          key: "16",
          icon: <UserAddOutlined />,
        },
        {
          label: "Activity",
          key: "17",
          icon: <FileTextOutlined />,
        },
      ],
    },
  ];

  const filteredMenuItems = menuItems.map(item => ({
    ...item,
    children: item.children?.filter(child => !(child.label === "Users" && currentUser.role === "Employee"))
  }));

  const handleResize = useCallback(   
    debounce(() => {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;

      if (newWidth !== size.width || newHeight !== size.height) {
        setSize({ width: newWidth, height: newHeight });
      }
    }, 200),
    [size]
  );
  useResizeObserver(handleResize);

  return (
    <Layout ref={ref}>
      <Affix offsetTop={0} style={{ position: "sticky", zIndex: 10 }}>
        <Sider
          width={200}
          className="sider customeScrollbar"
          breakpoint="lg"
          collapsedWidth="0"
          collapsed={collapsed}
          onCollapse={(collapsed) => setCollapsed(collapsed)}
        >
          {!collapsed && <div className="logo" />}
          <Menu
            onClick={(e) => setItemKey(e.key)}
            mode="inline"
            selectedKeys={[itemKey]}
            style={{ height: "fit-content", borderRight: 0 }}
            items={filteredMenuItems}
          ></Menu>
        </Sider>
      </Affix>
      <Layout>
        <Affix offsetTop={0} style={{ position: "sticky", zIndex: 10 }}>
          <Header className="site-layout-sub-header-background">
            <Dropdown menu={{ items }} trigger={["click"]}>
              <Avatar
                src={user?.user?.picture}
                size="large"
                icon={<UserOutlined />}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  float: "right",
                  marginTop: 15,
                }}
              />
            </Dropdown>
          </Header>
        </Affix>
        <Content className="content_view">
          <div className="site-layout-background">
            {itemKey === "1" && <Dashboard />}
            {itemKey === "2" && <Clients currentUser={currentUser}/>}
            {/* {itemKey === "3" && <Properties />} */}
            {itemKey === "4" && <BunglowProperties />}
            {itemKey === "5" && <FlatProperties />}
            {itemKey === "6" && <RentResidentialProperties />}
            {itemKey === "7" && <CommercialProperties />}
            {itemKey === "8" && <RentCommercialProperties />}
            {itemKey === "9" && <LandProperties />}
            {itemKey === "10" && <Leads {...props} currentUser={currentUser}/>}
            {itemKey === "11" && <ResidentialProjects />}
            {itemKey === "12" && <CommercialProjects />}
            {itemKey === "13" && <LandProjects />}
            {itemKey === "14" && <InBoundProperties />}
            {itemKey === "15" && <Areas />}
            {itemKey === "16" && <EmployeeUsers currentUser={currentUser}/>}
            {itemKey === "17" && <Activity />}
            {itemKey === "19" && (
              <BunglowDeveloperProperties itemKey={itemKey} />
            )}
            {itemKey === "20" && <FlatDeveloperProperties itemKey={itemKey} />}
            {itemKey === "21" && (
              <CommercialDevelporProperies itemKey={itemKey} />
            )}
            {itemKey === "22" && <LandDeveloperProperties itemKey={itemKey} />}
            {itemKey === "23" && <Clients itemKey={itemKey} currentUser={currentUser}/>}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
