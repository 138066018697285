import API from "../Utils/API";

export const getAllProperties = (type) => (dispatch) => {
  dispatch({ type: "GET_PROPERTIES_REQUEST" });
  API.get(`/properties?type=${type}`)
    .then((response) => {
      dispatch({ type: "GET_PROPERTIES_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_PROPERTIES_FAILURE",
        payload: error.response?.data,
      });
    });
};

export const getAllInBoundProperties = (inbound_property) => (dispatch) => {
  dispatch({ type: "GET_PROPERTIES_REQUEST" });
  API.get(`/properties/?inbound_property=${inbound_property}`)
    .then((response) => {
      dispatch({ type: "GET_PROPERTIES_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_PROPERTIES_FAILURE",
        payload: error.response.data,
      });
    });
};

export const getSearchProperties = (lead_id) => (dispatch) => {
  dispatch({ type: "GET_SEARCH_PROPERTIES_REQUEST" });
  API.get(`/properties/search_properties?lead_id=${lead_id}`)
    .then((response) => {
      dispatch({
        type: "GET_SEARCH_PROPERTIES_SUCCESS",
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: "GET_SEARCH_PROPERTIES_FAILURE",
        payload: error.response?.data,
      });
    });
};
export const createPropery =
  (data, type, successCreateProperty, failureCreateProperty) => (dispatch) => {
    dispatch({ type: "CREATE_PROPERTY_REQUEST", payload: data });
    API.post("/properties", data)
      .then((response) => {
        dispatch({ type: "CREATE_PROPERTY_SUCCESS", payload: response });
        dispatch(getAllProperties(type));
        const SPA = successCreateProperty ? successCreateProperty() : null;
      })
      .catch((error) => {
        try {
          dispatch({
            type: "CREATE_PROPERTY_FAILURE",
            payload: error.response.data,
          });

          const FPA = failureCreateProperty ? failureCreateProperty() : null;
        } catch {}
      });
    // .catch(error => {
    //     dispatch({ type: "CREATE_PROPERTY_FAILURE", payload: error.response.data })
    //     const FCP = failureCallBack ? failureCallBack() : null;
    // })
  };

export const publishedProperty =
  (data, publishedBoolean, type) => (dispatch) => {
    dispatch({ type: "PUBLISHED_PROJECT_REQUEST", payload: data });
    API.put(`/published/property/${data}`, { published: publishedBoolean })
      .then((response) => {
        dispatch({ type: "PUBLISHED_PROJECT_SUCCESS", payload: response });
        dispatch(getAllProperties(type));
      })
      .catch((error) => {
        dispatch({
          type: "PUBLISHED_PROJECT_FAILURE",
          payload: error.response.data,
        });
      });
  };

export const updatePropery =
  (data, type, successUpdateProperty, failureUpdateProperty) => (dispatch) => {
    dispatch({ type: "UPDATE_PROPERTY_REQUEST", payload: data });
    API.put(`/properties/${data.id}`, data)
      .then((response) => {
        dispatch({ type: "UPDATE_PROPERTY_SUCCESS", payload: response });
        dispatch(getAllProperties(type));
        const SPA = successUpdateProperty ? successUpdateProperty() : null;
      })
      .catch((error) => {
        try {
          dispatch({
            type: "UPDATE_PROPERTY_FAILURE",
            payload: error.response.data,
          });

          const FPA = failureUpdateProperty ? failureUpdateProperty() : null;
        } catch {}
      });
    // .catch(error => {
    //     dispatch({ type: "UPDATE_PROPERTY_FAILURE", payload: error.response.data })
    //     const FCP = failureCallBack ? failureCallBack() : null;
    // })
  };

export const deletePropery =
  (property_id, type, successDeleteProperty, failureDeleteProperty) =>
  (dispatch) => {
    dispatch({ type: "DELETE_PROPERTY_REQUEST" });
    API.delete(`/properties/${property_id}`)
      .then((response) => {
        dispatch({ type: "DELETE_PROPERTY_SUCCESS", payload: response });
        dispatch(getAllProperties(type));
        const SPA = successDeleteProperty ? successDeleteProperty() : null;
      })
      .catch((error) => {
        try {
          dispatch({
            type: "DELETE_PROPERTY_FAILURE",
            payload: error.response.data,
          });

          const FPA = failureDeleteProperty ? failureDeleteProperty() : null;
        } catch {}
      });
    // .catch(error => {
    //     dispatch({ type: "DELETE_PROPERTY_FAILURE", payload: error.response.data })
    //     const FCP = failureCallBack ? failureCallBack() : null;
    // })
  };
