import React from "react";
import { Row, Col, Typography, Card } from "antd";
import "../components/propertyservices.css";
import homeProperty from "../assets/images/homeProperty.svg";
import nriProperty from "../assets/images/nriProperty.svg";
import rent from "../assets/images/rent.svg";
import { useBreakpoints } from "./ResponsiveBreakPoints";

const PropertyServices = (props) => {
  const { isSuperLarge } = useBreakpoints(); 

  const propertyServices = [
    {
      icon: homeProperty,
      title: "Property Management",
      description:
        "Simplify your property ownership experience with our comprehensive property management solutions. From tenant screening and lease management to property maintenance and rent collection, we handle it all. Our goal is to maximize your returns while ensuring your property is well-maintained and compliant with local regulations.",
    },
    {
      icon: nriProperty,
      title: "NRI Property Management",
      description:
        "Managing your property from abroad can be challenging—let us simplify it for you. Our NRI Property Management services provide comprehensive solutions to oversee and maintain your assets in your absence. From tenant management and regular maintenance to legal assistance and property updates, we ensure your investments are in safe hands.",
    },
    {
      icon: rent,
      title: "Rent Agreement and Contract",
      description:
        "Ensure peace of mind with our professional Rent Agreement and Contract services. We handle the drafting, reviewing, and legal formalities to create transparent and secure agreements for landlords and tenants alike.",
    },
  ];

  return (
    <>
      <Row id="service" className="propertyContainer" justify="center">
        <Col xs={22} sm={22} md={22} lg={18} xl={16} xxl={14} style={{padding:'50px 0px', maxWidth:isSuperLarge?'40%':''}}>
          <Row justify="center" gutter={[0, 50]}>
            <Col span={24}>
              <Row justify="center" gutter={[0, 10]}>
                <Col span={24}>
                  <Typography className="propertySearch" style={{textAlign:'center'}}>
                    Property Services
                  </Typography>
                </Col>

                <Col span={24}>
                  <Typography
                    className="propertyServices"
                    style={{textAlign:'center'}}
                  >
                    One-stop destination for all your property-related needs
                  </Typography>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[30,30]} style={{textAlign:'center'}}>
                {propertyServices.map((service, index) => {
                  return (
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} key={index}>
                      <Row gutter={[20,20]}>
                        <Col span={24}>
                          <img src={service.icon} alt={service.title} />
                        </Col>
                        <Col span={24}>
                          <Typography className="typography1">{service.title}</Typography>
                        </Col>
                        <Col span={24}>
                          <Typography className="propertyServices">
                            {service.description}
                          </Typography>
                        </Col>
                      </Row>
                    </Col>
                  );
                })}
              </Row>
            </Col>
            {/* <Col span={24}>
              <Row justify="space-around" gutter={[16, 24]}>
                <Col xl={8} lg={8} md={8}>
                  <Card className="card">
                    <p className="heading">Property Management</p>
                    <p className="details">
                      Choose from a wide range of tailor-made packages from our
                      trusted service partners for a perfect at-home service
                      experience.
                    </p>
                  </Card>
                </Col>
                <Col xl={8} lg={8} md={8}>
                  <Card className="card">
                    <p className="heading">NRI Property Management</p>
                    <p className="details">
                      Choose from a wide range of tailor-made packages from our
                      trusted service partners for a perfect at-home service
                      experience.
                    </p>
                  </Card>
                </Col>
                <Col xl={8} lg={8} md={8}>
                  <Card className="card">
                    <p className="heading">Rent Agreement and Contract</p>
                    <p className="details">
                      Choose from a wide range of tailor-made packages from our
                      trusted service partners for a perfect at-home service
                      experience.
                    </p>
                  </Card>
                </Col>
              </Row>
            </Col> */}
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default PropertyServices;
