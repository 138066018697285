// ResizeContext.js
import { createContext, useContext, useEffect, useState } from "react";
import debounce from "lodash/debounce";

const ResizeContext = createContext(null);

export const ResizeProvider = ({ children }) => {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = debounce(() => {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    }, 200);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <ResizeContext.Provider value={size}>{children}</ResizeContext.Provider>
  );
};

export const useResize = () => useContext(ResizeContext);
