/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Input,
  Typography,
  Button,
  Modal,
  Space,
  Spin,
  Select,
  Row,
} from "antd";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { getUsers } from "../Actions/usersAction";
import { createTask } from "../Actions/tasksAction";
import "./styles/clients.css";

const schema = Yup.object().shape({
  due_date: Yup.string().required("Required"),
  note: Yup.string().required("Required"),
});
const { TextArea } = Input;
const { Text } = Typography;
export const CreateTask = ({
  attachment_id,
  attachment_type,
  setPopoverVisible,
}) => {
  const { users, isCreateTaskLoading } = useSelector((state) => {
    const { isCreateTaskLoading } = state.tasks;
    const { users, isGetUsersLoading } = state.users;
    return {
      users,
      isGetUsersLoading,
      isCreateTaskLoading,
    };
  });
  useEffect(() => {
    dispatch(getUsers());
  }, []);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { handleSubmit, errors, control, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const handleCreateTask = (data, event) => {
    event.preventDefault();
    data.attachment_id = attachment_id;
    data.attachment_type = attachment_type;
    data.is_completed =false;
    dispatch(createTask(data));
    setOpen(false);
    reset();
  };
  const handleCancel = () => {
    setOpen(false);
    reset();
  };
  const handleOpenAssignTask = () => {
    setPopoverVisible(false);
    setOpen(true);
  };
  return (
    <>
      <Button
        type="link"
        style={{ cursor: "pointer" }}
        onClick={() => handleOpenAssignTask()}
      >
        AssignTask
      </Button>
      {/* <CopyOutlined 
          style={{ cursor: "pointer" }}
          onClick={() => setOpen(true)}
        /> */}
      <Modal
        title="Create Task"
        open={open}
        onCancel={() => handleCancel()}
        footer={null}
        maskClosable={false}
      >
        <form onSubmit={handleSubmit(handleCreateTask)}>
        <Spin spinning={isCreateTaskLoading}> 
          <Col>
            <Row gutter={[10, 10]}>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Text>Employee Name</Text>
                <Controller
                  as={Select}
                  showSearch
                  control={control}
                  placeholder='Select employee name'
                  options={
                    users &&
                    users.length > 0 &&
                    users.map((val, index) => {
                      return { label: val.first_name, value: val.id };
                    })
                  }
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: "100%" }}
                  name="user_id"
                />
                {errors.user_id && (
                  <span className="error_style">Employee name is required</span>
                )}
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Text>Due Date</Text>
                <Controller
                  as={Input}
                  name="due_date"
                  control={control}
                  placeholder="Due Date"
                  type="date"
                />
                {errors.due_date && (
                  <span className="error_style">Due Date is required</span>
                )}
              </Col>

              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Text>Communication Date</Text>
                <Controller
                  as={Input}
                  name="communication_date"
                  control={control}
                  placeholder="Communication Date"
                  type="date"
                />
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Text>Follow up Date</Text>
                <Controller
                  as={Input}
                  name="follow_up_date"
                  control={control}
                  placeholder="Follow up Date"
                  type="date"
                />
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Text>Site visit Date</Text>
                <Controller
                  as={Input}
                  name="site_visit_date"
                  control={control}
                  placeholder="Site visit Date"
                  type="date"
                />
              </Col>

              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Text>Note</Text>
                <Controller
                  as={TextArea}
                  name="note"
                  control={control}
                  rows={4}
                  showCount
                  maxLength={100}
                />
                {errors.note && (
                  <span className="error_style">Note is required</span>
                )}
              </Col>

              <Col span={24}>
                <Row justify="end">
                  <Col>
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      style={{ marginTop: 15, top:5 }}
                    >
                      Create Task
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          </Spin>
        </form>
        
      </Modal>
    </>
  );
};
