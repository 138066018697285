/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { useForm, Controller } from "react-hook-form";
import { Row, Col, Layout, Typography } from "antd";
import "./bannerimage.css";
import { getAllAreas } from "../Actions/areasAction";
import { getSearchPostProperties } from "../Actions/postPropertyAction";

const PropertyType = [
  { value: 0, label: "Flat" },
  { value: 1, label: "Pent House" },
  { value: 2, label: "Bunglow" },
  { value: 3, label: "Tenament" },
  { value: 4, label: "Duplex" },
  { value: 5, label: "Row House" },
  { value: 6, label: "Fam House" },
  { value: 7, label: "Office" },
  { value: 8, label: "Shop" },
  { value: 9, label: "Showroom" },
  { value: 10, label: "Godown" },
  { value: 11, label: "Wear House" },
  { value: 12, label: "Residential" },
  { value: 13, label: "Commercial" },
  { value: 14, label: "Agriculture" },
  { value: 15, label: "NonAgriculture" },
  { value: 16, label: "Redevelopment" },
];
const { Text, Title } = Typography;
const { Header, Content, Footer } = Layout;
const BannerImage = (props) => {
  const { setAdvanceSearch } = props;
  const dispatch = useDispatch();
  const { isGetSearchPostPropertiesLoading, searchPostProperties } =
    useSelector((state) => state.postProperties);
  const { isGetPostPropertiesLoading, postProperties } = useSelector(
    (state) => state.postProperties
  );

  const { areas } = useSelector((state) => state.areas);


  const { handleSubmit, control, reset, watch } = useForm({});
  const watching = watch();
  const { property_type, area_id } = watching;
  const handleSearchProperty = (data) => {
    // const { property_type, area_id } = data;
    // const property_type = data.property_type && data.property_type === 0 ? property_type : null
    const property_type = data?.property_type
      ? data?.property_type
      : data?.property_type === 0
      ? data?.property_type
      : null;
    const area_id = data?.area_id ? data?.area_id : null;
    dispatch(getSearchPostProperties(property_type, area_id));
    reset();
  };

  return (
    <>
      <div className="heroContainer">
        <Row justify="center" align="middle" className="heroContent">
          <Col span={20} className="textContainer">
            <Typography className="heroTitle">Best properties for</Typography>
            <Typography className="heroTitle">Everyone</Typography>
            <Typography className="heroSubtitle">
              We provide a complete service for the sale, purchase, or rental of
              real estate.
            </Typography>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default BannerImage;
