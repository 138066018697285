import constants from "../Utils/constants";
const initialState = {
  isGetProjectsLoading: false,
  isCreateProjectLoading: false,
  isUpdateProjectLoading: false,
  isPublishedProjectLoading: false,
  isDeleteProjectLoading: false,
  isDeleteImageLoading: false,
  projects: [],
  commercialProject: [],
  residentialProject: [],
  landProject: [],
  landProjectPreLaunch:[],
  residentialPreLaunch:[],
  commercialPreLaunch:[],
  preLeaseProjects:[],
  allProjects:[]
};

const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_PROJECTS_REQUEST:
      return {
        ...state,
        isGetProjectsLoading: true,
      };
    case constants.GET_PROJECTS_SUCCESS:
      if (action.getType === "type=Residential") {
        return {
          ...state,
          isGetProjectsLoading: false,
          projects: action.payload,
          residentialProject: action.payload,
        };
      } else if (action.getType === "type=Residential&pre_launch=true") {
        return {
          ...state,
          isGetProjectsLoading: false,
          projects: action.payload,
          residentialPreLaunch: action.payload,
        };
      } else if (action.getType === "type=Commercial") {
        return {
          ...state,
          isGetProjectsLoading: false,
          projects: action.payload,
          commercialProject: action.payload,
        };
      } else if (action.getType === "type=Commercial&pre_launch=true") {
        return {
          ...state,
          isGetProjectsLoading: false,
          projects: action.payload,
          commercialPreLaunch: action.payload,
        };
      } else if (action.getType === "type=Land") {
        return {
          ...state,
          isGetProjectsLoading: false,
          projects: action.payload,
          landProject: action.payload,
        };
      } 
      else if (action.getType === "type=Land&pre_launch=true") {
        return {
          ...state,
          isGetProjectsLoading: false,
          projects: action.payload,
          landProjectPreLaunch: action.payload,
        };
      }else if (action.getType === "pre_lease=true") {
        return {
          ...state,
          isGetProjectsLoading: false,
          projects: action.payload,
          preLeaseProjects: action.payload,
        };
      } else {
        return {
          ...state,
          isGetProjectsLoading: false,
          projects: action.payload,
        };
      }
    case constants.GET_PROJECTS_FAILURE:
      return {
        ...state,
        isGetProjectsLoading: false,
      };

    case constants.GET_PROJECT_BY_ID_REQUEST:
      return {
        ...state,
        isGetProjectByIdLoading: true,
      };
    case constants.GET_PROJECT_BY_ID_SUCCESS:
      return {
        ...state,
        isGetProjectByIdLoading: false,
      };
    case constants.GET_PROJECT_BY_ID_FAILURE:
      return {
        ...state,
        isGetProjectByIdLoading: false,
      };

    case constants.CREATE_PROJECT_REQUEST:
      return {
        ...state,
        isCreateProjectLoading: true,
      };
    case constants.CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        isCreateProjectLoading: false,
      };
    case constants.CREATE_PROJECT_FAILURE:
      return {
        ...state,
        isCreateProjectLoading: false,
      };

    case constants.UPDATE_PROJECT_REQUEST:
      return {
        ...state,
        isUpdateProjectLoading: true,
      };
    case constants.UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        isUpdateProjectLoading: false,
      };
    case constants.UPDATE_PROJECT_FAILURE:
      return {
        ...state,
        isUpdateProjectLoading: false,
      };
    case constants.PUBLISHED_PROJECT_REQUEST:
      return {
        ...state,
        isPublishedProjectLoading: true,
      };
    case constants.PUBLISHED_PROJECT_SUCCESS:
      return {
        ...state,
        isPublishedProjectLoading: false,
      };
    case constants.PUBLISHED_PROJECT_FAILURE:
      return {
        ...state,
        isPublishedProjectLoading: false,
      };

    case constants.DELETE_PROJECT_REQUEST:
      return {
        ...state,
        isDeleteProjectLoading: true,
      };
    case constants.DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        isDeleteProjectLoading: false,
      };
    case constants.DELETE_PROJECT_FAILURE:
      return {
        ...state,
        isDeleteProjectLoading: false,
      };

    case constants.DELETE_IMAGE_REQUEST:
      return {
        ...state,
        isDeleteImageLoading: true,
      };
    case constants.DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        isDeleteImageLoading: false,
      };
    case constants.DELETE_IMAGE_FAILURE:
      return {
        ...state,
        isDeleteImageLoading: false,
      };
      case constants.GET_PROJECTS_COUNT_REQUEST:
      return {
        ...state,
        isGetProjectsLoading: true,
      };
    case constants.GET_PROJECTS_COUNT_SUCCESS:
      return {
        ...state,
        isGetProjectsLoading: false,
        allProjects: action.payload,
      };
    case constants.GET_PROJECTS_COUNT_FAILURE:
      return {
        ...state,
        isGetProjectsLoading: false,
      };
    default:
      return state;
  }
};
export default projectsReducer;
