/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Typography, Button, Collapse } from "antd";
import "../components/projectgallery.css";
import ProjectCard from "../components/ProjectCard";
import { Carousel } from "antd";
import { useBreakpoints } from "./ResponsiveBreakPoints";
import nextButton from "../assets/images/nextButton.svg";
import prevButton from "../assets/images/previousButton.svg";
import arrowIcon from "../assets/images/arrowIcon.svg";

const { Title } = Typography;

const contentStyle = {
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};
const Projects = (props) => {
  const { refTag, title, projects } = props;
  const { isSuperLarge } = useBreakpoints();
  const carouselRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slidesToShow, setSlidesToShow] = useState(3);
  const totalSlides = projects && projects.length;
  const { Panel } = Collapse;
  const [activeKeys, setActiveKeys] = useState([]);

  useEffect(() => {
    if (projects&&projects.length > 0) {
      setActiveKeys(["1"]);
    }
  }, [projects]);

  const handleNext = () => {
    if (currentSlide < totalSlides - settings.slidesToShow) {
      carouselRef.current.next();
      setCurrentSlide((prev) => prev + 1);
    }
  };

  const handlePrev = () => {
    if (currentSlide > 0) {
      carouselRef.current.prev();
      setCurrentSlide((prev) => prev - 1);
    }
  };

  useEffect(() => {
    const updateSlidesToShow = () => {
      if (window.innerWidth < 600) {
        setSlidesToShow(1);
      } else if (window.innerWidth < 1100) {
        setSlidesToShow(2);
      } else {
        setSlidesToShow(3);
      }
    };

    updateSlidesToShow();
    window.addEventListener("resize", updateSlidesToShow);

    return () => window.removeEventListener("resize", updateSlidesToShow);
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 1200,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    afterChange: (index) => setCurrentSlide(index),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div id="test">
        <Row justify="center" style={{ paddingTop: 40 }}>
          <Col
            xs={22}
            sm={22}
            md={22}
            lg={18}
            xl={16}
            xxl={14}
            style={{ maxWidth: isSuperLarge ? "40%" : "" }}
          >
            <Row justify="center" gutter={[0, 10]}>
              <Col span={24}>
                <Collapse
                  ghost
                  activeKey={activeKeys}
                  onChange={(keys) => setActiveKeys(keys)}
                  expandIcon={({ isActive }) => (
                    <img
                      src={arrowIcon}
                      style={{
                        transform: `rotate(${isActive ? 180 : 0}deg)`,
                        transition: "transform 0.3s ease",
                      }}
                    />
                  )}
                  expandIconPosition="end"
                  className="collapseClass"
                >
                  <Panel
                    header={<span className="propertySearch">{title}</span>}
                    key="1"
                  >
                    <Carousel {...settings} ref={carouselRef}>
                      {projects &&
                        projects.length > 0 &&
                        projects.map((rp, i) => (
                          <ProjectCard key={i} project={rp} />
                        ))}
                    </Carousel>
                    {projects && projects.length > 0 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "15px",
                          gap: "10px",
                        }}
                      >
                        {projects.length > slidesToShow && (
                          <>
                            <div
                              onClick={handlePrev}
                              disabled={currentSlide === 0}
                              style={{
                                opacity: currentSlide === 0 ? "0.5" : "1",
                                background: "none",
                                cursor:
                                  currentSlide === 0
                                    ? "not-allowed"
                                    : "pointer",
                                border: "none",
                              }}
                            >
                              <img src={prevButton} />
                            </div>

                            <div
                              onClick={handleNext}
                              style={{
                                opacity:
                                  currentSlide >= projects.length - slidesToShow
                                    ? "0.5"
                                    : "1",
                                background: "none",
                                cursor:
                                  currentSlide >= projects.length - slidesToShow
                                    ? "not-allowed"
                                    : "pointer",
                                border: "none",
                              }}
                            >
                              <img src={nextButton} />
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </Panel>
                </Collapse>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default Projects;
