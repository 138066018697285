/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import Highlighter from "react-highlight-words";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Input,
  Typography,
  Popover,
  Button,
  Modal,
  Spin,
  Space,
  Select,
  Row,
  Popconfirm,
  Divider,
  message,
  Checkbox,
} from "antd";
import {
  SearchOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { getAllAreas } from "../Actions/areasAction";
import {
  getAllProjects,
  createProject,
  deleteProject,
  updateProject,
  publishedProject,
} from "../Actions/projectsAction";
import MyTable from "../components/table";
import { ViewResidentialProjects } from "../pages/ViewResidentialProjects";
import { UpdateResidentialProjects } from "../pages/updateResidentialProjects";
import "./styles/clients.css";
import { useBreakpoints } from "../components/ResponsiveBreakPoints";
const Parking = [
  { value: 0, label: "No Parking" },
  { value: 1, label: "Parking 1" },
  { value: 2, label: "Parking 2" },
  { value: 3, label: "Parking 3" },
];
const schema = Yup.object().shape({
  property_name: Yup.string().required("Required"),
  tag_line: Yup.string().required("Required"),
  builder: Yup.string().required("Required"),
  no_of_floor: Yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Required"),
  possesion: Yup.string().required("Required"),
  parking: Yup.string().required("Required"),
});
const { Text, Title } = Typography;

const ResidentialProperties = () => {
  const dispatch = useDispatch();
  const { isMobile } = useBreakpoints();
  const {
    areas,
    projects,
    isGetProjectsLoading,
    isCreateProjectLoading,
    isUpdateProjectLoading,
    isDeleteProjectLoading,
  } = useSelector((state) => {
    const {
      projects,
      isGetProjectsLoading,
      isCreateProjectLoading,
      isUpdateProjectLoading,
      isDeleteProjectLoading,
    } = state.projects;
    const { areas } = state.areas;
    return {
      areas,
      projects,
      isGetProjectsLoading,
      isCreateProjectLoading,
      isUpdateProjectLoading,
      isDeleteProjectLoading,
    };
  });
  useEffect(() => {
    dispatch(getAllProjects("type=Residential"));
    dispatch(getAllAreas());
  }, []);

  const {
    register,
    handleSubmit,
    errors,
    clearErrors,
    control,
    reset,
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {pre_lease:false, pre_launch:false },
    mode: "onChange",
  });
  const [files, setFiles] = useState([]);
  const [name, setName] = useState("");

  const [createDialog, setCreateDialog] = useState(false);
  const [description, setDescription] = useState([
    { key: "", value: "", id: Math.random() },
  ]);
  const handleCreateProject = (data, event) => {
    // event.preventDefault();
    data.image_url = files.length > 0 ? files : null;
    data.project_type = "Residential";
    data.description = { description };
    dispatch(
      createProject(data, "type=Residential", successCreateArea, failureCreateArea)
    );
  };
  const successCreateArea = () => {
    setFiles([]);
    setName("");
    setDescription([{ key: "", value: "" }]);
    setCreateDialog(false);
    reset({
      property_name: "",
      no_of_floor: "",
      area_id: "",
      image_one: "",
      builder: "",
      tag_line: "",
      possesion: "",
      parking: "",
      pre_lease:false,
      pre_launch:false,
    });
    setTimeout(() => {
      message.success("You have successfully create residential projects.");
    }, 2000);
  };
  const failureCreateArea = () => {
    setTimeout(() => {
      message.info("Something went wrong.");
    }, 2000);
    setCreateDialog(false);
    reset();
  };
  const handleAdd = () => {
    const newDescription = description.concat([
      { key: "", value: "", id: Math.random() },
    ]);
    setDescription(newDescription);
  };
  const handleRemove = (id) => {
    const newDescription = description.filter((x) => x.id !== id);
    setDescription(newDescription);
  };
  const handleOnChange = (e, index) => {
    const { name, value } = e.target;
    const newDescription = description;
    const selectedObject = newDescription[index];
    selectedObject[name] = value;

    setDescription(newDescription);
  };
  const handleCancel = () => {
    reset({
      property_name: "",
      no_of_floor: "",
      area_id: "",
      image_one: "",
      builder: "",
      tag_line: "",
      possesion: "",
      parking: "",
      pre_lease:false,
      pre_launch:false,
    });
    setFiles([]);
    setName("");
    clearErrors([
      "property_name",
      "no_of_floor",
      "area_id",
      "image_one",
      "builder",
      "tag_line",
      "possesion",
      "parking",
      "pre_lease",
      "pre_launch",
    ]);
    setDescription([{ key: "", value: "" }]);
    setCreateDialog(false);
  };
  const handleImageChange = (event) => {
    const { value } = event.target;
    const files = Array.from(event.target.files);
    setName(value);
    setFiles(files);
  };

  return (
    <Spin
      spinning={
        isGetProjectsLoading ||
        isCreateProjectLoading ||
        isUpdateProjectLoading ||
        isDeleteProjectLoading
      }
    >
      <Modal
        title="Create Residential Project"
        open={createDialog}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
      >
        <form onSubmit={handleSubmit(handleCreateProject)}>
          <Row gutter={[8, 16]}>
            {files.length > 0 &&
              files?.map((image, index) => (
                <div key={index} style={{ margin: "10px" }}>
                  <img
                    src={URL.createObjectURL(image)}
                    alt={`image-${index}`}
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />
                </div>
              ))}
            <Col>
              <input
                name="image_url"
                value={name}
                onChange={(e) => handleImageChange(e)}
                multiple
                type="file"
              ></input>
            </Col>

            <Col span={24}></Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Row>
                <Text>Project Name</Text>
              </Row>
              <Controller
                as={Input}
                name="property_name"
                control={control}
                placeholder="Property Name."
              />
              {errors.property_name && (
                <span className="error_style">Property Name is required</span>
              )}
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Row>
                <Text>Builder</Text>
              </Row>
              <Controller
                as={Input}
                name="builder"
                control={control}
                placeholder="Builder"
              />
              {errors.builder && (
                <span className="error_style">Builder is required</span>
              )}
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Row>
                <Text>No of Floor</Text>
              </Row>
              <Controller
                as={Input}
                name="no_of_floor"
                control={control}
                placeholder="No of Floor"
              />
              {errors.no_of_floor && (
                <span className="error_style">No of Floor is required</span>
              )}
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Row>
                <Text>Area</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                showSearch
                options={
                  areas &&
                  areas.length > 0 &&
                  areas.map((val, index) => {
                    return { label: val.name, value: val.id };
                  })
                }
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                name="area_id"
                style={{ width: "100%" }}
              />
              {errors.area_id && (
                <span className="error_style">Area is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Row>
                <Text>Tag Line</Text>
              </Row>
              <Controller
                as={Input}
                name="tag_line"
                control={control}
                placeholder="Tag Lin"
              />
              {errors.tag_line && (
                <span className="error_style">Tag Lin is required</span>
              )}
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Row>
                <Text>Possesion</Text>
              </Row>
              <Controller
                as={Input}
                name="possesion"
                control={control}
                placeholder="Possesion"
              />
              {errors.possesion && (
                <span className="error_style">possesion is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[8, 16]} align="middle">
            <Col span={24}></Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Row>
                <Text>Parking ?</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                options={Parking}
                name="parking"
                style={{ width: "100%" }}
              />
              {errors.parking && (
                <span className="error_style">Parking is required</span>
              )}
            </Col>
            <Col style={{ marginTop: isMobile? '': '20px' }}>
              <Row gutter={[30, 0]}>
                <Col>
                  <Row gutter={[10, 0]}>
                    <Col>
                      <Text>Pre Launch</Text>
                    </Col>
                    <Col>
                      <Controller
                        name="pre_launch"
                        control={control}
                        render={(props) => (
                          <Checkbox
                            {...props}
                            defaultValue={false}
                            checked={props.value}
                            onChange={(e) => props.onChange(e.target.checked)}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row gutter={[10, 0]}>
                    <Col>
                      <Text>Pre Lease</Text>
                    </Col>
                    <Col>
                      <Controller
                        name="pre_lease"
                        control={control}
                        render={(props) => (
                          <Checkbox
                            {...props}
                            checked={props.value}
                            onChange={(e) => props.onChange(e.target.checked)}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Button onClick={handleAdd} type="primary" icon={<PlusOutlined />}>
              Add
            </Button>
          </Row>

          {description.map((des, index) => {
            return (
              <Row key={index} gutter={[8, 16]}>
                <Col span={24}></Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Row>
                    <Text>Key</Text>
                  </Row>
                  <Input
                    key={des.id}
                    name={`key`}
                    onChange={(e) => handleOnChange(e, index)}
                    placeholder="e.g. 3BHK, 4BHK Pent house"
                  />
                  {/* {errors.description && (
                  <span className="error_style">Type Of BHK is required</span>
                )} */}
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Row>
                    <Text>value</Text>
                  </Row>
                  <Input
                    key={des.id}
                    name={`value`}
                    onChange={(e) => handleOnChange(e, index)}
                    placeholder="e.g. 2500.Sqft"
                  />
                  {/* {errors.description && (
                  <span className="error_style">Type Of BHK is required</span>
                )} */}
                </Col>
                {index !== 0 && (
                  <Button type="link" onClick={() => handleRemove(des.id)}>
                    Remove
                  </Button>
                )}
              </Row>
            );
          })}

          <Row justify="end" gutter={[0, 16]}>
            <Col span={24}></Col>
            <Col>
              {isCreateProjectLoading ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 24,
                      }}
                      spin
                    />
                  }
                />
              ) : (
                <Button type="primary" htmlType="submit">
                  Create Project
                </Button>
              )}
            </Col>
          </Row>
        </form>
      </Modal>
      {projects && (
        <AllResidentialProperties
          data={projects}
          handleDialog={() => {
            setCreateDialog(true);
          }}
        />
      )}
      {/* {projects && <AllProperties data={projects} />} */}
    </Spin>
  );
};
export default ResidentialProperties;

const AllResidentialProperties = (props) => {
  const dispatch = useDispatch();
  const { areas } = useSelector((state) => state.areas);
  const { handleDialog } = props;
  const [showSearch, setShowSearch] = useState(false);
  const [currentProject, setCurrentProject] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const onRowSelect = (record) => {
    setCurrentProject(record);
    setShowSearch(true);
  };
  const onTaskRowClick = (task) => {};
  // useEffect(() => {
  //   if (createProject !== {}) {
  //     // dispatch() getTasks
  //     // dispatch() getSearch Properies
  //   }
  // }, [currentProject]);
  let searchInput = useRef("");
  const getColumnSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText(selectedKeys[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button>
          </Space>
        </div>
      ),

      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "",

      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    };
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const columns = (areas) => [
    {
      title: "Project Name",
      width: 120,
      dataIndex: "property_name",
      key: "1",
      // fixed: "left",
    },
    {
      title: "Tag Line",
      width: 150,
      dataIndex: "tag_line",
      key: "2",
      // fixed: "left",
    },
    {
      title: "Builder",
      width: 100,
      dataIndex: "builder",
      key: "3",
      // fixed: "left",
    },
    {
      title: "No Of Floor",
      width: 80,
      dataIndex: "no_of_floor",
      key: "4",
      // fixed: "left",
    },
    {
      title: "Possesion",
      dataIndex: "possesion",
      key: "5",
      width: 100,
    },
    {
      title: "Parking",
      dataIndex: "parking",
      key: "6",
      width: 150,
    },
    {
      title: "Area",
      dataIndex: "area",
      width: 100,
      key: "7",
      render: (record, object) => {
        return <div>{record.name || ""}</div>;
      },
      filters:
        areas &&
        areas.length > 0 &&
        areas.map((area, index) => {
          return { text: area.name, value: area.name };
        }),
      onFilter: (value, record) => {
        return record.area.name.indexOf(value) === 0;
      },
    },
    {
      title: "id",
      width: 90,
      dataIndex: "id",
      key: "8",
      ...getColumnSearchProps("id"),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      fixed: "right",
      width: 100,
      render: (id) => (
        <ActionComponent
          key={id}
          id={id}
          type="type=Residential"
          published={currentProject.published}
        />
      ),
    },
  ];
  return (
    <>
      <Row className="rowWithPadding" justify="space-between">
        <Col>
          <Title level={4}>All Residential Projects({props.data.length})</Title>
        </Col>
        <Col>
          <Button type="primary" onClick={() => handleDialog()}>
            Create Residential Project
          </Button>
        </Col>
      </Row>
      <MyTable
        columns={columns(areas)}
        data={props.data}
        onRowSelect={(record) => onRowSelect(record)}
      />
    </>
  );
};
const ActionComponent = ({ id, type, published }) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const dispatch = useDispatch();
  // const handleDeleteResidentialProject = (id,type) => {
  //   setTimeout(() => {
  //     message.success("Delete Residential Project successful.");
  //   }, 1000);
  //   dispatch(deleteProject(id,type));
  // };
  const handleDeleteResidentialProject = (project_id) => {
    project_id = id;
    dispatch(
      deleteProject(
        project_id,
        "type=Residential",
        successDeleteResidentialProject,
        failureDeleteResidentialProject
      )
    );
  };

  const successDeleteResidentialProject = () => {
    setTimeout(() => {
      message.success("You have successfully delete residential projects.");
    }, 2000);
  };
  const failureDeleteResidentialProject = () => {
    setTimeout(() => {
      message.info("Something went wrong.");
    }, 2000);
  };
  const handleUpdatePublished = () => {
    // event.preventDefault();
    setPopoverVisible(false);
    dispatch(publishedProject(id, type, true));
  };
  const handleUpdateUnPublished = () => {
    // event.preventDefault();
    setPopoverVisible(false);
    dispatch(publishedProject(id, type, false));
  };
  const content = (
    <div>
      {/* <ViewProperty id={id} />
      &nbsp;&nbsp; */}
      &nbsp;&nbsp;
      <ViewResidentialProjects id={id} setPopoverVisible={setPopoverVisible} />
      &nbsp;&nbsp;
      {published ? (
        <Button type="link" onClick={() => handleUpdateUnPublished()}>
          Un Published
        </Button>
      ) : (
        <Button type="link" onClick={() => handleUpdatePublished()}>
          Published
        </Button>
      )}
      &nbsp;&nbsp;
      <UpdateResidentialProjects
        id={id}
        setPopoverVisible={setPopoverVisible}
      />
      <Popconfirm
        title="Sure to delete?"
        // onConfirm={() => dispatch(deleteProject(id, type))}
        onConfirm={() => handleDeleteResidentialProject(id, type)}
      >
        <Button type="link" onClick={() => setPopoverVisible(false)}>
          Delete
        </Button>
      </Popconfirm>
      {/* <DeleteFilled
        onClick={() => dispatch(deletePropery(id))}
        style={{ cursor: "pointer", color: "red" }}
      /> */}
    </div>
  );
  return (
    <Popover
      content={content}
      open={popoverVisible}
      trigger="click"
      onOpenChange={setPopoverVisible}
    >
      <Button type="link" onClick={() => setPopoverVisible(!popoverVisible)}>
        Action
      </Button>
    </Popover>
  );
};
