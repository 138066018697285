/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import Highlighter from "react-highlight-words";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Input,
  Typography,
  Popover,
  Button,
  Modal,
  Spin,
  Space,
  Row,
  Popconfirm,
  message,
} from "antd";
import { DeleteFilled, EditTwoTone, SearchOutlined } from "@ant-design/icons";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  getAllAreas,
  createArea,
  updateArea,
  deleteArea,
} from "../Actions/areasAction";
import MyTable from "../components/table";
import "./styles/clients.css";
const schema = Yup.object().shape({
  name: Yup.string().required("Required"),
});
const { Text, Title } = Typography;

const Areas = () => {
  const dispatch = useDispatch();
  const {
    areas,
    isGetAreasLoading,
    isCreateAreaLoading,
    isUpdateAreaLoading,
    isDeleteAreaLoading,
  } = useSelector((state) => {
    const {
      areas,
      isGetAreasLoading,
      isCreateAreaLoading,
      isUpdateAreaLoading,
      isDeleteAreaLoading,
    } = state.areas;
    return {
      areas,
      isGetAreasLoading,
      isCreateAreaLoading,
      isUpdateAreaLoading,
      isDeleteAreaLoading,
    };
  });
  useEffect(() => {
    dispatch(getAllAreas());
  }, []);
  const { register, handleSubmit, errors, control, reset } = useForm({
    resolver: yupResolver(schema),
  });
  const [createDialog, setCreateDialog] = useState(false);
  const handleCreateArea = (data, event) => {
    // event.preventDefault();
    dispatch(createArea(data, successCreateArea, failureCreateArea));
  };
  const successCreateArea = () => {
    setCreateDialog(false);
    reset();
    setTimeout(() => {
      message.success("Create area successful.");
    }, 2000);
  };
  const failureCreateArea = () => {
    setCreateDialog(false);
    setTimeout(() => {
      message.info("Something went wrong.");
    }, 2000);
    reset();
  };
  const handleCancel = () => {
    setCreateDialog(false);
    reset();
  };
  return (
    <Spin
      spinning={
        isGetAreasLoading ||
        isCreateAreaLoading ||
        isUpdateAreaLoading ||
        isDeleteAreaLoading
      }
    >
      {/* <Button
        type="text"
        onClick={() => setCreateDialog(true)}
        style={{ fontWeight: 500, color: "#1890ff" }}
      >
        Create Area
      </Button> */}
      <Modal
        title="Create Area"
        visible={createDialog}
        onCancel={() => handleCancel()}
        footer={null}
        maskClosable={false}
      >
        <form onSubmit={handleSubmit(handleCreateArea)}>
          <Col>
            <Space direction="vertical" className="create_client_field">
              <Text>Area Name</Text>
              <Controller
                as={Input}
                name="name"
                control={control}
                placeholder="Area Name"
                style={{ width: 200 }}
              />
              {errors.name && (
                <span className="error_style">Area Name is required</span>
              )}
            </Space>
          </Col>
          {/* <Row justify='end'> */}
          <Col>
            <Button type="primary" htmlType="submit" block>
              Create Area
            </Button>
          </Col>
          {/* </Row> */}
        </form>
      </Modal>
      {areas && (
        <AllAreas data={areas} handleDialog={() => setCreateDialog(true)} />
      )}
      {/* {areas && <AllAreas data={areas} />} */}
    </Spin>
  );
};
export default Areas;

const AllAreas = (props) => {
  const { areas } = useSelector((state) => state.areas);
  const { handleDialog } = props;
  const [showTask, setShowTask] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [currentArea, setcurrentArea] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const onRowSelect = (record) => {
    setcurrentArea(record);
    setShowSearch(true);
    setShowTask(true);
  };
  // useEffect(() => {
  //   if (createArea !== {}) {
  //     // dispatch() getTasks
  //     // dispatch() getSearch Properies
  //   }
  // }, [currentArea]);
  let searchInput = useRef("");
  const getColumnSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText(selectedKeys[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button>
          </Space>
        </div>
      ),

      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "",

      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    };
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const columns = (areas) => [
    {
      title: "Area Name",
      dataIndex: "name",
      key: "1",
      width: 100,
      ...getColumnSearchProps("name"),
      render: (record, object) => {
        return (
          <Typography
           className="empText"
          >
            {object.name}{" "}
          </Typography>
        );
      },
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "id",
      width: 100,
      dataIndex: "id",
      key: "2",
      ...getColumnSearchProps("id"),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      // fixed: "right",
      width: 50,
      render: (id) => <ActionComponent id={id} />,
    },
  ];

  return (
    <>
      <Row className="rowWithPadding" justify="space-between">
        <Col>
          <Title level={4}>All Areas({props.data.length})</Title>
        </Col>
        <Col>
          <Button type="primary" onClick={() => handleDialog()}>
            Create Area
          </Button>
        </Col>
      </Row>
      <MyTable
        columns={columns(areas)}
        data={props.data}
        onRowSelect={(record) => onRowSelect(record)}
      />
      {showSearch && showTask && (
        <>
          {/* <Row>
        <Col span={12}>
        <Row className="rowWithPadding" justify="space-between">
        <Col>
          <Title level={4}>Matching Properties 
          for {currentArea.name} 
          </Title>
        </Col>
      </Row>
      <MyTable columns={columns( areas)} data={props.data} onRowSelect={(record) => onRowSelect(record)}/>
        </Col>
        <Col span={12}>
        <Row className="rowWithPadding" justify="space-between">
        <Col>
          <Title level={4}>Matching Leads 
          for {currentArea.name}
          </Title>
        </Col>
      </Row>
      <MyTable columns={columns( areas)} data={props.data} onRowSelect={(record) => onRowSelect(record)}/>
        </Col>
      </Row> */}
        </>
      )}
    </>
  );
};

const ActionComponent = ({ id }) => {
  const [popovervisible, setPopoverVisible] = useState(false);
  const dispatch = useDispatch();
  const handleDeleteArea = (area_id) => {
    area_id = id;
    dispatch(deleteArea(area_id, successDeleteArea, failureDeleteArea));
  };
  const successDeleteArea = () => {
    // setCreateDialog(false);
    setTimeout(() => {
      message.success("Delete Area successful.");
    }, 2000);
    // dispatch(getAllAreas());
  };
  const failureDeleteArea = () => {
    setTimeout(() => {
      message.info("Something went wrong.");
    }, 2000);
    // setCreateDialog(false);
  };
  const content = (
    <div>
      <UpdateArea id={id} setPopoverVisible={setPopoverVisible} />
      &nbsp;&nbsp;
      <Popconfirm
        title="Sure to delete?"
        // onConfirm={() => dispatch(deleteArea(id))}
        onConfirm={() => handleDeleteArea(id)}
      >
        <Button type="link" onClick={() => setPopoverVisible(false)}>
          Delete
        </Button>
      </Popconfirm>
      {/* <DeleteFilled
        onClick={() => dispatch(deleteArea(id))}
        style={{ cursor: "pointer", color: "red" }}
      /> */}
    </div>
  );
  return (
    <Popover content={content} visible={popovervisible} trigger="click">
      <Button type="link" onClick={() => setPopoverVisible(true)}>
        Action
      </Button>
    </Popover>
  );
};

const UpdateArea = ({ id, setPopoverVisible }) => {
  const dispatch = useDispatch();
  const { areas } = useSelector((state) => state.areas);
  const the_area = areas.filter((area) => area.id === id);
  const [open, setOpen] = useState(false);
  const { register, handleSubmit, errors, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: the_area[0],
  });
  // const handleUpdateArea = (data, event) => {
  //   event.preventDefault();
  //   data.id = id;
  //   dispatch(updateArea(data));
  //   setOpen(false);
  // };
  const handleUpdateArea = (data, event) => {
    data.id = id;
    // event.preventDefault();
    dispatch(updateArea(data, successUpdateArea, failureUpdateArea));
  };
  const successUpdateArea = () => {
    setOpen(false);
    setTimeout(() => {
      message.success("Update Area successful.");
    }, 2000);
  };
  const failureUpdateArea = () => {
    setTimeout(() => {
      message.info("Something went wrong.");
    }, 2000);
  };
  const handleOpenArea = () => {
    setPopoverVisible(false);
    setOpen(true);
  };
  return (
    <>
      <a style={{ cursor: "pointer" }} onClick={() => handleOpenArea()}>
        Edit{" "}
      </a>
      {/* <EditTwoTone
        style={{ cursor: "pointer" }}
        onClick={() => setOpen(true)}
      /> */}
      <Modal
        title="Edit Area details"
        visible={open}
        onCancel={() => setOpen(false)}
        footer={null}
        maskClosable={false}
      >
        <form onSubmit={handleSubmit(handleUpdateArea)}>
          <Col>
            <Space direction="vertical" className="create_client_field">
              <Text>Area Name</Text>
              <Controller
                as={Input}
                name="name"
                control={control}
                placeholder="Update Area Name"
                style={{ width: 200 }}
              />
              {errors.name && (
                <span className="error_style">Area Name is required</span>
              )}
            </Space>
          </Col>
          {/* <Row justify='end'> */}
          <Col>
            <Button type="primary" htmlType="submit" block>
              Update Area
            </Button>
          </Col>
          {/* </Row> */}
        </form>
      </Modal>
    </>
  );
};
