import API from '../Utils/API';
import { getAllInBoundProperties } from "../Actions/propertiesActions";

export const getPostProperties = () => dispatch => {
    dispatch({ type: "GET_POST_PROPERTIES_REQUEST" })
    API.get('/post/properties')
        .then(response => {
            dispatch({ type: "GET_POST_PROPERTIES_SUCCESS", payload: response.data })
        })
        .catch(error => {
            dispatch({ type: "GET_POST_PROPERTIES_FAILURE", payload: error.response?.data })
        })
}

export const getSearchPostProperties = (property_type, area_id) => dispatch => {
    dispatch({ type: "GET_SEARCH_POST_PROPERTIES_REQUEST" })
    API.get(`/search_post_properties?property_type=${property_type}&area_id=${area_id}`)
        .then(response => {
            dispatch({ type: "GET_SEARCH_POST_PROPERTIES_SUCCESS", payload: response.data })
        })
        .catch(error => {
            dispatch({ type: "GET_SEARCH_POST_PROPERTIES_FAILURE", payload: error.response.data })
        })
}

export const createPostPropery = (data, successCallBack, failureCallBack) => dispatch => {
    dispatch({ type: "CREATE_POST_PROPERTY_REQUEST"})
    API.post('/post/properties', data)
        .then(response => {
            dispatch({ type: "CREATE_POST_PROPERTY_SUCCESS", payload: response })
            successCallBack && successCallBack(response.data)
        })
        .catch(error => {
            dispatch({ type: "CREATE_POST_PROPERTY_FAILURE", payload: error.response.data })
            failureCallBack && failureCallBack(error.response.data)
        })
}

export const updatePostPropery = (data) => dispatch => {
    dispatch({ type: "UPDATE_POST_PROPERTY_REQUEST", payload: data })
    API.put(`/inbound/properties/${data.id}`, data)
        .then(response => {
            dispatch({ type: "UPDATE_POST_PROPERTY_SUCCESS", payload: response })
            dispatch(getAllInBoundProperties(data.inbound_property))
        })
        .catch(error => {
            dispatch({ type: "UPDATE_POST_PROPERTY_FAILURE", payload: error.response.data })
        })
}