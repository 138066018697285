/*eslint-disable*/
import React from "react";
import { Table, Row, Col } from "antd";
import "./styles/AppView.css";

export default (props) => {
  const { data, columns, onRowSelect, rowClassName } = props;
  return (
    <Row>
      <Col>
        <Table
        key={data}
          pagination={false}
          // onRow={(record, rowIndex) => {
          //   return {
          //     onClick: (event) => onRowSelect(record),
          //   };
          // }}

          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => onRowSelect(record),
            };
          }}
          columns={columns ? columns : columns}
          dataSource={data ? data : data}
          scroll={{
            x: 100, 
            y: data?.length > 10 ? 800 : undefined, 
          }}
          sticky
          size="small"
          rowClassName={rowClassName} 
        />
      </Col>
    </Row>
  );
};
