/*eslint-disable*/
import React, { useState, useEffect, useRef, useCallback } from "react";
import Highlighter from "react-highlight-words";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Input,
  Typography,
  Popover,
  Button,
  Modal,
  Spin,
  Space,
  Select,
  Row,
  message,
  Popconfirm,
} from "antd";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  getClients,
  createClient,
  deleteClient,
} from "../Actions/clientActions";
import MyTable from "../components/table";
import "./styles/clients.css";
import { UpdateClient } from "../pages/updateClient";
import { ViewClient } from "../pages/viewClient";
import { debounce } from "lodash";
import useResizeObserver from "../components/useResizeObserver";
import moment from "moment";
const Gender = [
  { value: 0, label: "Male" },
  { value: 1, label: "Female" },
  { value: 2, label: "Other" },
];
const ClientType = [
  { value: 0, label: "Buyer" },
  { value: 1, label: "Seller" },
  { value: 2, label: " Rent Owner" },
  { value: 3, label: "Rent Client" },
];
const PropertyType = [
  { value: 12, label: "Residential" },
  { value: 13, label: "Commercial" },
  { value: 17, label: "Land" },
];
const phoneRegExp = /^[0][1-9]\d{9}$|^[1-9]\d{9}$/;
const schema = Yup.object().shape({
  name: Yup.string().required("Required"),
  mobile_number: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
});
const { TextArea } = Input;
const { Text, Title } = Typography;

const Clients = (props) => {
  const { itemKey, currentUser } = props;
  const dispatch = useDispatch();

  const {
    clients,
    isGetClientsLoading,
    isCreateClientLoading,
    isUpdateClientLoading,
    isDeleteClientLoading,
  } = useSelector((state) => state.clients);
  useEffect(() => {
    if (itemKey === "23") {
      dispatch(getClients("is_developer=true"));
    } else {
      dispatch(getClients());
    }
  }, []);

  const { register, handleSubmit, errors, control, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      address: "",
      area: "",
      business: "",
      city: "",
      client_type: "",
      company: "",
      created_at: "",
      date_of_birth: "",
      designation: "",
      email: "",
      gender: "",
      home_number: "",
      landmark: "",
      mobile_number: "",
      name: "",
      note: "",
      office_number: "",
      property_type: "",
      reference: "",
    },
  });
  useEffect(() => {
    reset({
      address: "",
      area: "",
      business: "",
      city: "",
      client_type: "",
      company: "",
      created_at: "",
      date_of_birth: "",
      designation: "",
      email: "",
      gender: "",
      home_number: "",
      landmark: "",
      mobile_number: "",
      name: "",
      note: "",
      office_number: "",
      property_type: "",
      reference: "",
    });
  }, [reset]);
  // const { createNewClientDialog, setcreateNewClientDialog } = props;
  const [createDialog, setCreateDialog] = useState(false);
  const [size, setSize] = useState({ width: 0, height: 0 });

  // const handleCreateClient = (data, event) => {
  //   event.preventDefault();
  //   setCreateDialog(false);
  //   reset();
  //   setTimeout(() => {
  //         message.success("Create client successful.");
  //       }, 1000);
  //   dispatch(createClient(data));
  // };
  const handleCreateClient = (data, event) => {
    // event.preventDefault();
    if (itemKey === "23") {
      data.is_developer = true;
      data.client_type = 4;
    }
    dispatch(createClient(data, successCreateClient, failureCreateClient));
  };
  const successCreateClient = () => {
    setCreateDialog(false);
    if (itemKey === "23") {
      dispatch(getClients("is_developer=true"));
    } else {
      dispatch(getClients());
    }
    reset();
    setTimeout(() => {
      message.success(
        `You have successfully created ${
          itemKey === "23" ? "developer" : "client"
        }.`
      );
    }, 2000);
  };
  const failureCreateClient = (res) => {
    setCreateDialog(false);
    setTimeout(() => {
      if (res?.mobile_number?.length > 0) {
        message.error(`Mobile number ${res.mobile_number[0]}`);
      } else {
        message.info("Something went wrong.");
      }
    }, 500);
    reset();
  };

  const handleCancel = () => {
    setCreateDialog(false);
    reset();
  };

  const handleResize = useCallback(
    debounce(() => {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;

      if (newWidth !== size.width || newHeight !== size.height) {
        setSize({ width: newWidth, height: newHeight });
      }
    }, 200),
    [size]
  );
  useResizeObserver(handleResize);

  return (
    <Spin
      spinning={
        isGetClientsLoading ||
        isCreateClientLoading ||
        isUpdateClientLoading ||
        isDeleteClientLoading
      }
    >
      <Modal
        title={itemKey === "23" ? "Create Developer" : "Create Client"}
        open={createDialog}
        onCancel={() => handleCancel()}
        footer={null}
        width={700}
        maskClosable={false}
      >
        <form onSubmit={handleSubmit(handleCreateClient)}>
          <Row gutter={[16, 0]}>
            <Col xl={8} lg={8} md={8} sm={8} xs={24}>
              <Row>
                <Text>Name</Text>
              </Row>
              <Controller
                as={Input}
                name="name"
                control={control}
                placeholder={
                  itemKey === "23" ? "Developer name" : "Client name"
                }
              />
              {errors.name && (
                <span className="error_style">
                  {itemKey === "23" ? "Developer" : "Client"} Name is required
                </span>
              )}
            </Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={24}>
              <Row>
                <Text>E-mail</Text>
              </Row>
              <Controller
                as={Input}
                name="email"
                control={control}
                placeholder="E-mail"
              />
              {errors.email && (
                <span className="error_style">E-mail is required</span>
              )}
            </Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={24}>
              <Row>
                <Text>Reference</Text>
              </Row>
              <Controller
                as={Input}
                name="reference"
                control={control}
                placeholder="Reference"
              />
              {errors.reference && (
                <span className="error_style">Reference is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}></Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={24}>
              <Row>
                <Text>Mobile number</Text>
              </Row>
              <Controller
                as={Input}
                name="mobile_number"
                control={control}
                placeholder="Mobile number"
                minLength={10}
                maxLength={10}
              />
              {errors.mobile_number && (
                <span className="error_style">Mobile number is required</span>
              )}
            </Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={24}>
              <Row>
                <Text>Mobile 2</Text>
              </Row>
              <Controller
                as={Input}
                name="home_number"
                control={control}
                placeholder="Mobile 2"
              />
              {errors.home_number && (
                <span className="error_style">Home number is required</span>
              )}
            </Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={24}>
              <Row>
                <Text>Office number</Text>
              </Row>
              <Controller
                as={Input}
                name="office_number"
                control={control}
                placeholder="Office number"
              />
              {errors.office_number && (
                <span className="error_style">Office number is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}></Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Row>
                <Text>Address</Text>
              </Row>
              <Controller
                as={Input}
                name="address"
                control={control}
                placeholder="Address"
              />
              {errors.address && (
                <span className="error_style">Address is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}></Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={24}>
              <Row>
                <Text>Area</Text>
              </Row>
              <Controller
                as={Input}
                name="area"
                control={control}
                placeholder="Area"
              />
              {errors.area && (
                <span className="error_style">Area is required</span>
              )}
            </Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={24}>
              <Row>
                <Text>Landmark</Text>
              </Row>
              <Controller
                as={Input}
                name="landmark"
                control={control}
                placeholder="Landmark"
              />
              {errors.landmark && (
                <span className="error_style">Landmark is required</span>
              )}
            </Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={24}>
              <Row>
                <Text>City</Text>
              </Row>
              <Controller
                as={Input}
                name="city"
                control={control}
                placeholder="City"
              />
              {errors.city && (
                <span className="error_style">City is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}></Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={24}>
              <Row>
                <Text>Business</Text>
              </Row>
              <Controller
                as={Input}
                name="business"
                control={control}
                placeholder="Business"
              />
              {errors.business && (
                <span className="error_style">Business is required</span>
              )}
            </Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={24}>
              <Row>
                <Text>Designation</Text>
              </Row>
              <Controller
                as={Input}
                name="designation"
                control={control}
                placeholder="Designation"
              />
              {errors.designation && (
                <span className="error_style">Designation is required</span>
              )}
            </Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={24}>
              <Row>
                <Text>Company</Text>
              </Row>
              <Controller
                as={Input}
                name="company"
                control={control}
                placeholder="Company"
              />
              {errors.company && (
                <span className="error_style">Company is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}></Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={24}>
              <Row>
                <Text>Birthday</Text>
              </Row>
              <Controller
                as={Input}
                name="date_of_birth"
                control={control}
                placeholder="Birthday"
                type="date"
              />
              {errors.date_of_birth && (
                <span className="error_style">Birthday is required</span>
              )}
            </Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={24}>
              <Row>
                <Text>Marriage anniversary</Text>
              </Row>
              <Controller
                as={Input}
                name="date_of_anniversary"
                control={control}
                placeholder="Marriage anniversary"
                type="date"
              />
              {errors.date_of_anniversary && (
                <span className="error_style">
                  Marriage anniversary is required
                </span>
              )}
            </Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={24}>
              <Row>
                <Text>Created At</Text>
              </Row>
              <Controller
                as={Input}
                name="created_at"
                control={control}
                placeholder="Created Date"
                type="date"
              />
              {errors.created_at && (
                <span className="error_style">Created Date is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}></Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={24}>
              <Row>
                <Text>Gender</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                options={Gender}
                style={{ width: "100%" }}
                name="gender"
              />
              {errors.gender && (
                <span className="error_style">Gender is required</span>
              )}
            </Col>
            {itemKey === "23" ? (
              ""
            ) : (
              <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                <Row>
                  <Text>Client Type</Text>
                </Row>
                <Controller
                  as={Select}
                  control={control}
                  options={ClientType}
                  style={{ width: "100%" }}
                  name="client_type"
                />
                {errors.client_type && (
                  <span className="error_style">Client Type is required</span>
                )}
              </Col>
            )}
            <Col xl={8} lg={8} md={8} sm={24} xs={24}>
              <Row>
                <Text>Property Type</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                options={PropertyType}
                name="property_type"
                style={{ width: "100%" }}
              />
              {errors.property_type && (
                <span className="error_style">Property Type is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}></Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Row>
                <Text>Note</Text>
              </Row>
              <Controller
                as={TextArea}
                name="note"
                control={control}
                rows={4}
                showCount
                maxLength={100}
              />
              {errors.note && (
                <span className="error_style">Note is required</span>
              )}
            </Col>
          </Row>
          <Row justify="end" gutter={[0, 16]}>
            <Col span={24}></Col>
            <Col>
              {isCreateClientLoading ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 24,
                      }}
                      spin
                    />
                  }
                />
              ) : (
                <Button type="primary" htmlType="submit">
                  Create {itemKey === "23" ? "Developer" : "Client"}
                </Button>
              )}
            </Col>
          </Row>
        </form>
      </Modal>
      {clients && (
        <AllClients
          data={clients}
          handleDialog={() => setCreateDialog(true)}
          itemKey={itemKey}
          currentUser={currentUser}
        />
      )}
      {/* { clients && <AllClients data={clients} />} */}
    </Spin>
  );
};
export default Clients;

const AllClients = (props) => {
  const { clients } = useSelector((state) => state.clients);
  const { handleDialog, itemKey, currentUser } = props;
  const [showTask, setShowTask] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [currentClient, setcurrentClient] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  // const [open, setOpen] = useState(false)

  const onRowSelect = (record) => {
    setcurrentClient(record);
    setShowSearch(true);
    setShowTask(true);
  };
  // useEffect(() => {
  //   if (createClient !== {}) {
  //     // dispatch() getTasks
  //     // dispatch() getSearch Properies
  //   }
  // }, [currentClient]);
  let searchInput = useRef("");
  const getColumnSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText(selectedKeys[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button>
          </Space>
        </div>
      ),

      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "",

      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    };
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const columns = (clients) => {
    let cols = [
      {
        title: "Client Name",
        dataIndex: "name",
        key: "1",
        width: 130,
        ...getColumnSearchProps("name"),
        render: (record, object) => {
          const designation = object.designation
            ? `(${object.designation})`
            : "";
          return (
            <Typography style={{ fontSize: "11px" }}>
              {object.name} {designation}
            </Typography>
          );
        },
      },
      {
        title: "Mobile",
        dataIndex: "mobile_number",
        key: "2",
        width: 100,
        ...getColumnSearchProps("mobile_number"),
        // sorter: (a, b) => a.mobile_number - b.mobile_number,
      },
      {
        title: "Mobile 2",
        dataIndex: "home_number",
        key: "3",
        width: 100,
        ...getColumnSearchProps("home_number"),
        // sorter: (a, b) => a.home_number - b.home_number,
      },
      {
        title: "Client Type",
        dataIndex: "client_type",
        key: "4",
        width: 100,
        ...getColumnSearchProps("client_type"),
        // sorter: (a, b) => a.home_number - b.home_number,
      },
      {
        title: "Property Type",
        dataIndex: "property_type",
        key: "5",
        width: 100,
        ...getColumnSearchProps("property_type"),
        // sorter: (a, b) => a.home_number - b.home_number,
      },
      {
        title: "Created At",
        dataIndex: "created_at",
        key: "6",
        width: 80,
        render: (created_at) => {
          if (!created_at) return "-";
          const formattedDate = moment(created_at, [
            "YYYY-MM-DD",
            "DD/MM/YYYY",
            "MM/DD/YYYY",
            "YYYY-MM-DDTHH:mm:ss.SSSZ",
            "x",
            "X",
          ]).format("DD/MM/YYYY");

          return (
            <div>{formattedDate !== "Invalid date" ? formattedDate : "-"}</div>
          );
        },
      },
      {
        title: "id",
        dataIndex: "id",
        key: "7",
        width: 60,
        ...getColumnSearchProps("id"),
      },
      {
        title: "Action",
        dataIndex: "id",
        key: "id",
        fixed: "right",
        width: 100,
        render: (id) => (
          <ActionComponent
            id={id}
            itemKey={itemKey}
            currentUser={currentUser}
          />
        ),
      },
    ];

    if (currentUser.role === "Employee" && !(itemKey === "23")) {
      cols = cols.filter(
        (col) => !["Client Name", "Mobile", "Mobile 2"].includes(col.title)
      );
    }

    if (itemKey === "23") {
      cols.unshift({
        title: "Company Name",
        dataIndex: "company",
        key: "0",
        width: 150,
        render: (company) => {
          return (
            <Typography className="empText">{company || "N/A"}</Typography>
          );
        },
      });
    }

    return cols;
  };
  return (
    <>
      <Row
        className="rowWithPadding"
        justify="space-between"
        style={{ marginBottom: "10px " }}
      >
        <Col>
          <Title level={4} style={{ marginTop: "2px" }}>
            All {itemKey === "23" ? "Developers" : "Clients"}(
            {props.data.length})
          </Title>
        </Col>
        <Col>
          <Button type="primary" onClick={() => handleDialog()}>
            Create {itemKey === "23" ? "Developer" : "Client"}
          </Button>
        </Col>
      </Row>
      <MyTable
        columns={columns(clients)}
        data={props.data}
        onRowSelect={(record) => onRowSelect(record)}
      />
      {showSearch && showTask && (
        <>
          {/* <Row>
        <Col span={12}>
        <Row className="rowWithPadding" justify="space-between">
        <Col>
          <Title level={4}>Matching Properties 
                    for {currentClient.name} 
                    </Title>
        </Col>
      </Row>
      <MyTable columns={columns( clients)} data={props.data} onRowSelect={(record) => onRowSelect(record)}/>
        </Col>
        <Col span={12}>
        <Row className="rowWithPadding" justify="space-between">
        <Col>
          <Title level={4}>Task
                    for {currentClient.name}'s requirements
                    </Title>
        </Col>
      </Row>
      <MyTable columns={columns( clients)} data={props.data} onRowSelect={(record) => onRowSelect(record)}/>
        </Col>
      </Row> */}
        </>
      )}
    </>
  );
};

const ActionComponent = ({ id, itemKey, currentUser }) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const dispatch = useDispatch();
  // const handleDeleteClient = (id) => {
  //   setTimeout(() => {
  //     message.success("Client delete successful.");
  //   }, 1000);
  //   dispatch(deleteClient(id));
  // };
  const handleDeleteClient = (client_id) => {
    client_id = id;
    dispatch(deleteClient(client_id, successDeleteClient, failureDeleteClient));
  };
  const successDeleteClient = () => {
    // setCreateDialog(false);
    if (itemKey === "23") {
      dispatch(getClients("is_developer=true"));
    } else {
      dispatch(getClients());
    }
    setTimeout(() => {
      message.success(
        `You have successfully delete ${
          itemKey === "23" ? "developer" : "client"
        }.`
      );
    }, 2000);
    // dispatch(getAllAreas());
  };
  const failureDeleteClient = () => {
    setTimeout(() => {
      message.info("Something went wrong.");
    }, 2000);
    // setCreateDialog(false);
  };

  const content = (
    <div>
      <ViewClient
        id={id}
        setPopoverVisible={setPopoverVisible}
        itemKey={itemKey}
        currentUser={currentUser}
      />
      &nbsp;&nbsp;
      <UpdateClient
        id={id}
        setPopoverVisible={setPopoverVisible}
        itemKey={itemKey}
        currentUser={currentUser}
      />
      &nbsp;&nbsp;
      {currentUser?.role === "Admin" || itemKey ==='23' ?  (
        <Popconfirm
        title="Sure to delete?"
        onConfirm={() => handleDeleteClient(id)}
      >
        <Button type="link" onClick={() => setPopoverVisible(false)}>
          Delete
        </Button>
      </Popconfirm>
      ) :''}
      {/* <DeleteFilled onClick={()=>dispatch(deleteClient(id))} style={{ cursor: 'pointer', color: "red" }} /> */}
    </div>
  );
  return (
    <Popover
      content={content}
      open={popoverVisible}
      trigger="click"
      onOpenChange={setPopoverVisible}
    >
      <Button type="link" onClick={() => setPopoverVisible(!popoverVisible)}>
        Action
      </Button>
    </Popover>
  );
};
