import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./styles/Activity.css";
import {
  Timeline,
  Row,
  Col,
  Avatar,
  Pagination,
  Spin,
  Typography,
  Divider,
  Empty,
} from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { getActivities } from "../Actions/activitiesAction";
import moment from "moment";
const { Title } = Typography;
const Activity = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const { publicActivities, isGetPublicActivitiesLoading } = useSelector(
    (state) => {
      const { publicActivities, isGetPublicActivitiesLoading } =
        state.activities;
      return {
        publicActivities,
        isGetPublicActivitiesLoading,
      };
    }
  );
  const { total_length } = publicActivities || {};
  useEffect(() => {
    dispatch(getActivities(`public_activity?page=${currentPage}`));
  }, [currentPage]);
  const onChangeCurrentPage = (page) => {
    setCurrentPage(page);
  };
  const secondColor = "#" + Math.floor(Math.random() * 1677).toString(14);

  return (
    <Spin spinning={isGetPublicActivitiesLoading}>
      <Row className="rowWithPadding">
        <Col>
          <Title level={4}>Activities ({total_length})</Title>
        </Col>
      </Row>
      <Divider className="activityDivider" />
      {total_length > 0 ? (
        <>
          <Timeline>
            {publicActivities &&
              total_length > 0 &&
              publicActivities?.public_activities.map((activity, i) => {
                const {
                  owner,
                  key,
                  trackable_type,
                  created_at,
                  created_at_with_formate,
                  trackable,
                  parameters,
                } = activity || {};
                const { first_name = "Someone", last_name = "" } = owner || {};
                const cud = key.substring(key.indexOf(".") + 1);
                return (
                  <Timeline.Item
                    dot={
                      cud === "destroy" ? (
                        <DeleteOutlined className="timeline-icon" />
                      ) : cud === "update" ? (
                        <EditOutlined className="timeline-icon" />
                      ) : cud === "create" ? (
                        <PlusOutlined className="timeline-icon" />
                      ) : (
                        ""
                      )
                    }
                    key={i}
                  >
                    <Row gutter={[18, 0]}>
                      <Col xl={1} lg={1} md={1} sm={24} xs={24}>
                        {/* <Avatar
                          shape="circle"
                          size="large"
                          style={{ backgroundColor: secondColor }}
                        >
                          {first_name?.[0] + " " + last_name?.[0]}
                        </Avatar> */}
                      </Col>
                      <Col xl={23} lg={23} md={23} sm={24} xs={24}>
                        <Row justify="space-between">
                          <Col>
                            <p className="TimelineDetails1">
                              {first_name + " " + last_name} has {cud}
                              {cud === "destroy" ? "ed" : "d"} a{" "}
                              {trackable_type} on {created_at_with_formate}
                            </p>
                          </Col>
                          <Col className="timeDetail">
                            {moment(created_at).startOf("minute").fromNow()}
                            {/* {moment(created_at).fromNow(
                new Date().toTimeString()
              )} */}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {trackable_type === "Project" && (
                              <p className="TimelineDetails2">
                                {parameters?.project_type === "Residential"
                                  ? "Residential"
                                  : parameters?.project_type === "Commercial"
                                  ? "Commercial"
                                  : parameters?.project_type === "Land"
                                  ? "Land"
                                  : ""}{" "}
                                project name is{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {parameters?.property_name}
                                </span>
                                , Builder is{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {parameters?.builder}
                                </span>{" "}
                                and id is{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {parameters?.id}
                                </span>
                              </p>
                            )}
                            {trackable_type === "Property" && (
                              <p className="TimelineDetails2">
                                {parameters?.is_residential
                                  ? "Residential"
                                  : parameters?.is_commercial
                                  ? "Commercial"
                                  : parameters?.is_plot
                                  ? "Land"
                                  : ""}{" "}
                                property type is{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {parameters?.property_type}
                                </span>
                                , Property status is{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {parameters?.property_status}
                                </span>{" "}
                                and id is{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {parameters?.id}
                                </span>
                              </p>
                            )}
                            {trackable_type === "Lead" && (
                              <p className="TimelineDetails2">
                                Lead type is{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {parameters?.types_of_lead?.types?.join(", ") }
                                </span>
                                , Client type is{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {parameters?.client_type}
                                </span>
                                , Zone is{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {parameters?.zone}
                                </span>{" "}
                                and id is{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {parameters?.id}
                                </span>
                              </p>
                            )}
                            {trackable_type === "Area" && (
                              <p className="TimelineDetails2">
                                Area name is{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {parameters?.name}
                                </span>{" "}
                                and id is{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {parameters?.id}
                                </span>
                              </p>
                            )}
                            {trackable_type === "Task" && (
                              <p className="TimelineDetails2">
                                {parameters?.attachment_type === "Property"
                                  ? "Property"
                                  : parameters?.attachment_type === "Lead"
                                  ? "Lead"
                                  : ""}{" "}
                                task is{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {parameters?.note}
                                </span>
                              </p>
                            )}
                            {trackable_type === "User" && (
                              <p className="TimelineDetails2">
                                User name is{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {parameters?.first_name +
                                    " " +
                                    parameters?.last_name}
                                </span>{" "}
                                and id is{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {parameters?.id}
                                </span>
                              </p>
                            )}
                            {trackable_type === "Client" && (
                              <p className="TimelineDetails2">
                                Client name is{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {parameters?.name}
                                </span>
                                , Client type is{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {parameters?.client_type}
                                </span>{" "}
                                and id is{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {parameters?.id}
                                </span>
                              </p>
                            )}
                            {trackable_type === "Notification" && (
                              <p className="TimelineDetails2">
                                Notification is{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {parameters?.text}
                                </span>{" "}
                                and id is{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {parameters?.id}
                                </span>
                              </p>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Timeline.Item>
                );
              })}
          </Timeline>
        </>
      ) : (
        <Row align="middle" justify="center" style={{ height: "50vh" }}>
          <Col>
            <Empty description="No activities yet" />
          </Col>
        </Row>
      )}
      {publicActivities && total_length > 0 && (
        <Row justify="end" gutter={[0, 24]}>
          <Col>
            <Pagination
              responsive={true}
              size="small"
              current={currentPage}
              onChange={(e) => onChangeCurrentPage(e)}
              total={total_length}
            />
          </Col>
          <Col span={24}></Col>
        </Row>
      )}
    </Spin>
  );
};
export default Activity;
