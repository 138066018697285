/*eslint-disable*/
import React from "react";
import "../components/footer.css";
import { Row, Col, Layout, Typography, Image } from "antd";
import fb from "../assets/images/fb.svg";
import instagram from "../assets/images/instagram.svg";
import place from "../assets/images/place.svg";
import email from "../assets/images/email.svg";
import call from "../assets/images/call.svg";
import logo from "../assets/images/logo.svg";
import { useHistory, useLocation } from "react-router-dom";
import { useBreakpoints } from "./ResponsiveBreakPoints";

const HomeFooter = () => {
  const location = useLocation();
  const history = useHistory();
  const { isSuperLarge } = useBreakpoints();
  const scrollToSection = (section) => {
    if (location.pathname === "/") {
      const sectionElement = document.getElementById(section);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      history.push("/");
      setTimeout(() => {
        const sectionId = section.replace("/", "");
        document
          .getElementById(sectionId)
          ?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  };
  return (
    <>
      <Row justify="center" className="footerContainer">
        <Col
          xs={22}
          sm={22}
          md={22}
          lg={18}
          xl={16}
          xxl={14}
          style={{ maxWidth: isSuperLarge ? "40%" : "100%" }}
        >
          <Row gutter={[0, 30]}>
            <Col span={24} className="footerLogoContainer">
              <Row justify="center" gutter={[0, 10]}>
                <Col>
                  <img
                    src={logo}
                    alt="logo"
                    style={{ height: "100px", cursor: "pointer" }}
                    onClick={() => history.push("/")}
                  />
                </Col>
                <Col span={24}>
                  <Typography
                    className="typography1"
                    style={{ textAlign: "center" }}
                  >
                    Shree Vastu Developers and Management
                  </Typography>
                </Col>
                <Col className="centerItem">
                  <span
                    style={{ marginRight: "20px", cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        "https://www.instagram.com/shree_vastu_management/"
                      )
                    }
                  >
                    <img src={instagram} alt="instagram" />
                  </span>
                  <span
                    onClick={() =>
                      window.open("https://facebook.com/svmdproperty/")
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <img src={fb} alt="facebook" />
                  </span>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row gutter={[40, 30]}>
                <Col xs={24} sm={12} md={12}>
                  <Row gutter={[0, 10]}>
                    <Col span={24}>
                      <Typography className="contactUs">Contact Us</Typography>
                    </Col>
                    <Col span={24}>
                      <Typography className="contactDetails centerItem">
                        <span className="icon">
                          <img src={place} alt="location" />
                        </span>
                        121/1, 4th Floor, Left Wing, Nalanda Complex, Near Mansi
                        Circle, Vastrapur, Ahmedabad-380015
                      </Typography>
                    </Col>
                    <Col span={24}>
                      <Typography className="contactDetails centerItem">
                        <span className="icon">
                          <img src={email} alt="email" />
                        </span>
                        info@svmdproperty.com
                      </Typography>
                    </Col>
                    <Col span={24}>
                      <Typography className="contactDetails centerItem">
                        <span className="icon">
                          <img src={call} alt="call" />
                        </span>
                        9909956755
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <Row gutter={20}>
                    <Col span={24}>
                      <Row gutter={[0, 10]}>
                        <Col span={24}>
                          <Typography className="mainTitle">Company</Typography>
                        </Col>
                        <Col span={24}>
                          <Typography
                            className="links"
                            onClick={() => scrollToSection("aboutus")}
                          >
                            About us
                          </Typography>
                        </Col>
                        <Col span={24}>
                          <Typography className="links">Contact us</Typography>
                        </Col>
                        <Col span={24}>
                          <Typography
                            className="links"
                            onClick={() => scrollToSection("service")}
                          >
                            Property service
                          </Typography>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="copyright" justify="center">
        <Col xs={22} sm={22} md={22} lg={18} xl={16} xxl={14}>
          <Typography
            className="propertyServices"
            style={{ textAlign: "center" }}
          >
            Copyright © 2025 Shree Vastu Developers and Management. All Rights
            Reserved.
          </Typography>
        </Col>
      </Row>
    </>
  );
};
export default HomeFooter;
