import API from "../Utils/API";
import constants from "../Utils/constants";

export const getUsers = () => (dispatch) => {
  dispatch({ type: "GET_USERS_REQUEST" });
  API.get("/users")
    .then((response) => {
      dispatch({ type: "GET_USERS_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: "GET_USERS_FAILURE", payload: error.response.data });
    });
};
export const getAuth0User = () => (dispatch) => {
  dispatch({ type: constants.GET_AUTH0_USERS_REQUEST });
  API.get("/auth0_users")
    .then((response) => {
      dispatch({
        type: constants.GET_AUTH0_USERS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: constants.GET_AUTH0_USERS_FAILURE,
        payload: error.response.data,
      });
    });
};
export const auth0UserReconsile =
  (successAuth0UserReconsile, failureAuth0UserReconsile) => (dispatch) => {
    dispatch({ type: constants.GET_AUTH0_USERS_RECONSILE_REQUEST });
    API.post("/auth0_reconsile")
      .then((response) => {
        dispatch({
          type: constants.GET_AUTH0_USERS_RECONSILE_SUCCESS,
        });
        successAuth0UserReconsile && successAuth0UserReconsile();
      })
      .catch((error) => {
        dispatch({
          type: constants.GET_AUTH0_USERS_RECONSILE_FAILURE,
          payload: error.response.data,
        });
        failureAuth0UserReconsile && failureAuth0UserReconsile();
      });
  };
export const getCurrentUser = () => (dispatch) => {
  dispatch({ type: "GET_USER_BY_ID_REQUEST" });
  API.get("/users/1")
    .then((response) => {
      dispatch({ type: "GET_USER_BY_ID_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_USER_BY_ID_FAILURE",
        payload: error.response?.data,
      });
    });
};

export const createUser =
  (data, successCreateUser, failureCreateUser) => (dispatch) => {
    dispatch({ type: constants.CREATE_USER_REQUEST, payload: data });
    API.post("/users", data)
      .then((response) => {
        dispatch({
          type: constants.CREATE_USER_SUCCESS,
          payload: response.data,
        });
        dispatch(getUsers());
        const SUA = successCreateUser ? successCreateUser() : null;
      })
      .catch((error) => {
        try {
          dispatch({
            type: constants.CREATE_USER_FAILURE,
            payload: error.response.data,
          });

          const FUA = failureCreateUser ? failureCreateUser(error) : null;
        } catch {}
      });
    // .catch(error => {
    //     dispatch({ type: constants.CREATE_USER_FAILURE, payload: error.response.data })
    // })
  };

export const updateUser =
  (data, successUpdateUser, failureUpdateUser) => (dispatch) => {
    dispatch({ type: constants.UPDATE_USER_REQUEST, payload: data });
    API.put(`/users/${data.id}`, data)
      .then((response) => {
        dispatch({
          type: constants.UPDATE_USER_SUCCESS,
          payload: response.data,
        });
        dispatch(getUsers());
        const SUA = successUpdateUser ? successUpdateUser() : null;
      })
      .catch((error) => {
        try {
          dispatch({
            type: constants.UPDATE_USER_FAILURE,
            payload: error.response.data,
          });

          const FUA = failureUpdateUser ? failureUpdateUser() : null;
        } catch {}
      });
    // .catch(error => {
    //     dispatch({ type: constants.UPDATE_USER_FAILURE, payload: error.response.data })
    // })
  };

export const deleteUser =
  (user_id, successDeleteUser, failureDeleteUser) => (dispatch) => {
    dispatch({ type: constants.DELETE_USER_REQUEST });
    API.delete(`/users/${user_id}`)
      .then((response) => {
        dispatch({
          type: constants.DELETE_USER_SUCCESS,
          payload: response.data,
        });
        dispatch(getUsers());
        const SUA = successDeleteUser ? successDeleteUser() : null;
      })
      .catch((error) => {
        try {
          dispatch({
            type: constants.DELETE_USER_FAILURE,
            payload: error.response.data,
          });

          const FUA = failureDeleteUser ? failureDeleteUser() : null;
        } catch {}
      });
    // .catch(error => {
    //     dispatch({ type: constants.DELETE_USER_FAILURE, payload: error.response.data })
    // })
  };
