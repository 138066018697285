import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Input,
  Typography,
  Button,
  Modal,
  Select,
  Row,
  Divider,
  message,
  Spin,
  Checkbox,
} from "antd";
import {
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  deleteImage,
  getAllProjects,
  updateProject,
} from "../Actions/projectsAction";
import no_image from "../assets/images/no_image.png";
import "./styles/clients.css";
const schema = Yup.object().shape({});
const { Text } = Typography;
export const UpdateLandProjects = ({ id, setPopoverVisible }) => {
  const dispatch = useDispatch();
  const { areas } = useSelector((state) => state.areas);
  const { projects, isUpdateProjectLoading, isDeleteImageLoading } =
    useSelector((state) => state.projects);
  const the_project = projects.filter((project) => project.id === id);
  // const image =
  //   the_project[0].images && the_project[0].images.length > 0
  //     ? the_project[0].images[0].image_url
  //     : null;
  const [newFiles, setNewFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [removeImage, setRemoveImage] = useState(false);
  const [name, setName] = useState("");
  const oldDescription = the_project[0].description.description;
  const [description = oldDescription, setDes] = useState();

  const [open, setOpen] = useState(false);
  const { handleSubmit, errors, control, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: the_project[0],
  });

  useEffect(() => {
    if (the_project.length > 0) {
      setFiles(the_project[0].images);
    }
  }, [the_project, setFiles]);

  useEffect(() => {
    if (the_project?.length > 0) {
      reset(the_project[0]);
    }
  }, [JSON.stringify(the_project), reset]);

  const handleUpdateProject = (data, event) => {
    event.preventDefault();
    data.id = id;
    data.project_type = "Land";
    data.image_url = newFiles.length > 0 ? newFiles : null;
    data.description = { description };
    dispatch(
      updateProject(
        data,
        "type=Land",
        successUpdateLandProject,
        failureUpdateLandProject
      )
    );
  };
  const successUpdateLandProject = () => {
    setOpen(false);
    reset();
    setNewFiles("");
    setTimeout(() => {
      message.success("You have successfully update land project.");
    }, 2000);
  };
  const failureUpdateLandProject = () => {
    setTimeout(() => {
      message.info("Something went wrong.");
    }, 2000);
  };
  const handleImageChange = (event) => {
    const { value } = event.target;
    const newFiles = Array.from(event.target.files);
    setName(value);
    setNewFiles(newFiles);
  };
  const handleAdd = () => {
    const newDescription = description.concat([
      { key: "", value: "", id: Math.random() },
    ]);
    setDes(newDescription);
  };
  const handleRemove = (id) => {
    const newDescription = description.filter((x) => x.id !== id);
    setDes(newDescription);
  };
  const handleRemoveImage = () => {
    setRemoveImage(true);
  };
  const handleOnChange = (e, id) => {
    const { name, value } = e.target;
    // const newDescription = description
    const selectedObject = description.find((item) => item.id === id);

    selectedObject[name] = value;
    // the_project[0].description.description = newDescription;
    setDes([...description]);
  };
  const handleOpen = (data) => {
    setPopoverVisible(false);
    setOpen(true);
    reset(data);
  };
  const handleRemoveNewImage = (id) => {
    dispatch(deleteImage(id, successDeleteCallback));
  };

  const successDeleteCallback = () => {
    // setOpen(false)
    dispatch(getAllProjects("type=Land"));
    setNewFiles("");
  };
  return (
    <>
      <Button
        type="link"
        style={{ cursor: "pointer" }}
        onClick={() => handleOpen()}
      >
        Edit{" "}
      </Button>
      <Modal
        title="Edit Project details"
        visible={open}
        onCancel={() => setOpen(false)}
        footer={null}
        maskClosable={false}
      >
        <form onSubmit={handleSubmit(handleUpdateProject)}>
          <Spin spinning={isDeleteImageLoading}>
            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Row gutter={[30, 10]}>
                  {files?.length > 0
                    ? files.map((file) => (
                        <Col key={file.id} style={{ position: "relative" }}>
                          <img
                            className="pCardImage"
                            alt=""
                            src={file.image_url.url}
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                              borderRadius: "8px",
                            }}
                          />

                          <DeleteOutlined
                            onClick={() => handleRemoveNewImage(file.id)}
                            style={{
                              position: "absolute",
                              bottom: "10px",
                              marginLeft: "5px",
                            }}
                          />
                        </Col>
                      ))
                    : ""}

                  {newFiles.length > 0
                    ? newFiles.map((file, index) => (
                        <Col key={index} style={{ position: "relative" }}>
                          <img
                            className="pCardImage"
                            alt=""
                            src={URL.createObjectURL(file)}
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                              borderRadius: "8px",
                            }}
                          />
                          <DeleteOutlined
                            onClick={() =>
                              setNewFiles(
                                newFiles.filter((_, i) => i !== index)
                              )
                            }
                            style={{
                              position: "absolute",
                              bottom: "10px",
                              marginLeft: "5px",
                            }}
                          />
                        </Col>
                      ))
                    : ""}

                  <Col span={24}>
                    <input
                      name="image_url"
                      value=""
                      onChange={(e) => handleImageChange(e)}
                      multiple
                      type="file"
                    ></input>
                  </Col>
                </Row>

                <Row gutter={[8, 16]}>
                  <Col span={24}></Col>
                  <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                    <Row>
                      <Text>Builder</Text>
                    </Row>
                    <Controller
                      as={Input}
                      name="builder"
                      control={control}
                      placeholder="Builder"
                    />
                    {errors.builder && (
                      <span className="error_style">Builder is required</span>
                    )}
                  </Col>
                  <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                    <Row>
                      <Text>Project Name.</Text>
                    </Row>
                    <Controller
                      as={Input}
                      name="property_name"
                      control={control}
                      placeholder="Property Name."
                    />
                    {errors.property_name && (
                      <span className="error_style">
                        Property Name is required
                      </span>
                    )}
                  </Col>

                  <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                    <Row>
                      <Text>Area</Text>
                    </Row>
                    <Controller
                      as={Select}
                      control={control}
                      showSearch
                      options={
                        areas &&
                        areas.length > 0 &&
                        areas.map((val, index) => {
                          return { label: val.name, value: val.id };
                        })
                      }
                      filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      style={{ width: "100%" }}
                      name="area_id"
                    />
                    {errors.area_id && (
                      <span className="error_style">Area is required</span>
                    )}
                  </Col>
                </Row>
                <Row gutter={[8, 16]}>
                  <Col span={24}></Col>
                  <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                    <Row>
                      <Text>Tag Line</Text>
                    </Row>
                    <Controller
                      as={Input}
                      name="tag_line"
                      control={control}
                      placeholder="Tag Lin"
                    />
                    {errors.tag_line && (
                      <span className="error_style">Tag Lin is required</span>
                    )}
                  </Col>
                  <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                    <Row>
                      <Text>Possesion</Text>
                    </Row>
                    <Controller
                      as={Input}
                      name="possesion"
                      control={control}
                      placeholder="Possesion"
                    />
                    {errors.possesion && (
                      <span className="error_style">possesion is required</span>
                    )}
                  </Col>
                  <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                    <Row>
                      <Text>Google Link</Text>
                    </Row>
                    <Controller
                      as={Input}
                      name="google_link"
                      control={control}
                      placeholder="City"
                    />
                    {errors.google_link && (
                      <span className="error_style">
                        Google Link is required
                      </span>
                    )}
                  </Col>
                  <Col>
                    <Row gutter={[10, 0]}>
                      <Col>
                        <Text>Resell</Text>
                      </Col>
                      <Col>
                        <Controller
                          name="pre_launch"
                          control={control}
                          render={(props) => (
                            <Checkbox
                              {...props}
                              checked={props.value}
                              onChange={(e) => props.onChange(e.target.checked)}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Divider />
                <Row>
                  <Button
                    onClick={() => handleAdd()}
                    type="primary"
                    icon={<PlusOutlined />}
                  >
                    Add
                  </Button>
                </Row>

                {description.map((des, index) => {
                  return (
                    <Row key={index} gutter={[8, 16]}>
                      <Col span={24}></Col>
                      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Row>
                          <Text>Key</Text>
                        </Row>
                        <Input
                          key={des.id}
                          name={`key`}
                          value={des.key}
                          onChange={(e) => handleOnChange(e, des.id)}
                          placeholder="e.g. Land, Less, Carpet Ares SqFt"
                        />
                        {/* {errors.description && (
                  <span className="error_style">Type Of BHK is required</span>
                )} */}
                      </Col>
                      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Row>
                          <Text>value</Text>
                        </Row>
                        <Input
                          key={des.id}
                          name={`value`}
                          value={des.value}
                          onChange={(e) => handleOnChange(e, des.id)}
                          placeholder="e.g. 2500.Sqft"
                        />
                        {/* {errors.description && (
                  <span className="error_style">Type Of BHK is required</span>
                )} */}
                      </Col>
                      {index !== 0 && (
                        <Button
                          type="link"
                          onClick={() => handleRemove(des.id)}
                        >
                          Remove
                        </Button>
                      )}
                    </Row>
                  );
                })}

                <Row justify="end" gutter={[0, 16]}>
                  <Col span={24}></Col>
                  <Col>
                    {isUpdateProjectLoading  ? (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{
                            fontSize: 24,
                          }}
                          spin
                        />
                      }
                    />
                  ) : (
                    <Button type="primary" htmlType="submit">
                      Update
                    </Button>
                     )} 
                  </Col>
                </Row>
              </Col>
            </Row>
          </Spin>
        </form>
      </Modal>
    </>
  );
};
