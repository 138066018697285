// eslint-disable-next-line
import React, { useState, useEffect, useRef, useCallback } from "react";
import Highlighter from "react-highlight-words";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Input,
  Typography,
  Popover,
  Button,
  Modal,
  Spin,
  Space,
  Checkbox,
  Select,
  InputNumber,
  Row,
  Popconfirm,
  Steps,
  Divider,
  message,
  ConfigProvider,
  Segmented,
} from "antd";
import { EnvironmentFilled, LoadingOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { getAllAreas } from "../Actions/areasAction";
import { getClients } from "../Actions/clientActions";
import { getAllTasks, deleteTask } from "../Actions/tasksAction";
import {
  getAllProperties,
  createPropery,
  publishedProperty,
  deletePropery,
} from "../Actions/propertiesActions";
import MyTable from "../components/table";
import "./styles/clients.css";
import { UpdateBunglowProperties } from "../pages/UpdateBunglowProperties";
import { CreateTask } from "../pages/tasks";
import { ViewProperty } from "../pages/viewProperty";
import moment from "moment";
import _, { debounce } from "lodash";
import { CSVLink, CSVDownload } from "react-csv";
import useResizeObserver from "../components/useResizeObserver";

const PropertyType = [
  // { value: 1, label: "Flat" },
  // { value: 2, label: "Pent House" },
  { value: 3, label: "Bunglow" },
  { value: 4, label: "Tenament" },
  { value: 5, label: "Duplex" },
  { value: 6, label: "Row House" },
  { value: 18, label: "Residential Plot" },

  // { value: 7, label: "Fam House" },
  // { value: 17, label: "Redevelopment" },
];
const Furniture = [
  { value: "Furnished", label: "Furnished" },
  { value: "Semi", label: "Semi Furnished" },
  { value: "Naked", label: "Naked" },
];
const PropertyStatus = [
  { value: 0, label: "Available" },
  { value: 1, label: "Sold Out" },
  { value: 2, label: "On hold" },
];
const { Step } = Steps;
const steps = [
  {
    title: "Personal Details",
  },
  {
    title: "Property Details",
  },
];

const schema = Yup.object().shape({
  client_id: Yup.string().required("Required"),
  area_id: Yup.string().required("Required"),
  construction_year: Yup.string().required("Required"),
  property_type: Yup.number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Required"),
  cost: Yup.string().required("Required"),
});
const { TextArea } = Input;
const { Text, Title } = Typography;

const BunglowProperties = (props) => {
  const { itemKey } = props;
  const dispatch = useDispatch();
  const {
    clients,
    areas,
    properties,
    isGetPropertiesLoading,
    isCreatePropertyLoading,
    isUpdatePropertyLoading,
    isDeletePropertyLoading,
  } = useSelector((state) => {
    const {
      properties,
      isGetPropertiesLoading,
      isCreatePropertyLoading,
      isUpdatePropertyLoading,
      isDeletePropertyLoading,
    } = state.properties;
    const { clients } = state.clients;
    const { areas } = state.areas;
    return {
      clients,
      areas,
      properties,
      isGetPropertiesLoading,
      isCreatePropertyLoading,
      isUpdatePropertyLoading,
      isDeletePropertyLoading,
    };
  });
  const isNotRent = _.filter(properties, function (o) {
    return o.is_rent === false;
  });

  const bunglowProperties = _.filter(isNotRent, function (o) {
    return (
      o.property_type === "Bunglow" ||
      o.property_type === "Tenament" ||
      o.property_type === "Duplex" ||
      o.property_type === "RowHouse" ||
      o.property_type === "ResidentialPlot"
    );
  });
  useEffect(() => {
    dispatch(getAllProperties(`is_residential&is_developer=true`));
  }, []);
  useEffect(() => {
    dispatch(getClients("is_developer=true"));
  }, []);
  useEffect(() => {
    dispatch(getAllAreas());
  }, []);
  const {
    handleSubmit,
    errors,
    clearErrors,
    control,
    reset,
    getValues,
    trigger,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      is_fix: false,
      is_negotiable: false,
      inbound_property: false,
      available: false,
      is_web_visible: false,
      is_furnished: false,
      published: false,
      is_commercial: false,
      is_residential: false,
      is_plot: false,
      is_new: false,
      is_resell: false,
      is_rent: false,
      is_lease: false,
      fix_kitchen: false,
      mod_kitchen: false,
      fix_drawing_room: false,
      mod_drawing_room: false,
      fix_bedroom: false,
      mod_bedroom: false,
      air_condition: false,
      water_24hr: false,
      png: false,
      security: false,
      cctv: false,
      lift: false,
      common_parking: false,
      paid_parking: false,
      garden: false,
      children_play_area: false,
      club_house: false,
      gymnasium: false,
      common_plot: false,
      religious_place: false,
      park_garden: false,
      educational_institute: false,
      hospital_dispansary: false,
      mall_market: false,
      bus_stop: false,
      created_at: moment(new Date()),
    },
    mode: "all",
  });

  const watching = watch(["property_type", "is_furnished"]);
  const { property_type, is_furnished } = watching;
  const [createDialog, setCreateDialog] = useState(false);
  const [vastuValue, setVastuValue] = useState("Yes");
  const [current, setCurrent] = React.useState(0);
  const [size, setSize] = useState({ width: 0, height: 0 });
  const next = () => {
    const {
      client_id: cid,
      area_id: aid,
      property_type: PT,
      cost: cst,
    } = getValues([
      "client_id",
      "area_id",
      "construction_year",
      "property_type",
      "cost",
    ]);
    if (current === 0 && (!cid || !aid || !PT)) {
      trigger(["client_id", "area_id", "property_type", "construction_year"]);
    } else if (current === 1 && !cst) {
      trigger(["cost"]);
    } else {
      setCurrent(current + 1);
    }
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const handleCreatePropery = (data, event) => {
    data.is_residential = true;
    data.is_developer = true;
    data.vastu_complient = vastuValue === "Yes" ? 0 : 1;
    dispatch(
      createPropery(
        data,
        "is_residential&is_developer=true",
        successCreateBunglowProperty,
        failureCreateBunglowProperty
        // IsEmpty
      )
    );
  };
  const successCreateBunglowProperty = () => {
    setCreateDialog(false);
    setCurrent(0);
    reset();
    setTimeout(() => {
      message.success(
        "You have successfully create builder's bunglow property."
      );
    }, 2000);
    // dispatch(getAllProperties());
  };
  const failureCreateBunglowProperty = () => {
    setTimeout(() => {
      message.info("Something went wrong.");
    }, 2000);
    setCreateDialog(false);
    setCurrent(0);
    reset();
  };

  const handleCancel = () => {
    setCreateDialog(false);
    setCurrent(0);
    reset({
      client_id: null,
      area_id: null,
      construction_year: null,
      property_type: null,
      cost: null,
    });
    clearErrors();
  };
  const handleResize = useCallback(
    debounce(() => {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;

      if (newWidth !== size.width || newHeight !== size.height) {
        setSize({ width: newWidth, height: newHeight });
      }
    }, 200),
    [size]
  );
  useResizeObserver(handleResize);

  return (
    <Spin
      spinning={
        isGetPropertiesLoading ||
        isCreatePropertyLoading ||
        isUpdatePropertyLoading ||
        isDeletePropertyLoading
      }
    >
      <Modal
        title="Create Builder's Bunglow Residential Property"
        open={createDialog}
        onCancel={() => handleCancel()}
        footer={null}
        width={800}
        maskClosable={false}
      >
        <Row>
          <Steps
            current={current}
            size="small"
            responsive="true"
            labelPlacement="vertical"
          >
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </Row>
        <form onSubmit={handleSubmit(handleCreatePropery)}>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Developer</Text>
              </Row>
              <Controller
                as={
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    optionLabelProp="label"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    {clients &&
                      clients.length > 0 &&
                      clients.map((val) => {
                        const designation = val.designation
                          ? `(${val.designation})`
                          : "";
                        const displayName = val.company || val.name;

                        return (
                          <Select.Option
                            key={val.id}
                            value={val.id}
                            label={displayName}
                          >
                            <div>
                              <span>{val.company || "N/A"}</span>
                              <br />
                              <span style={{ fontSize: "13px" }}>
                                {val.name}
                              </span>
                              <span style={{ fontSize: "12px" }}>
                                {" "}
                                {designation}
                              </span>
                            </div>
                          </Select.Option>
                        );
                      })}
                  </Select>
                }
                control={control}
                name="client_id"
              />
              {errors.client_id && (
                <span className="error_style">Developer is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Property Type</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                options={PropertyType}
                name="property_type"
                style={{ width: "100%" }}
              />
              {errors.property_type && (
                <span className="error_style">Property Type is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Entry Date</Text>
              </Row>
              <Controller
                as={Input}
                name="created_at"
                control={control}
                placeholder="Entry Date"
                type="date"
                format="DD-MM-YYYY"
                // defaultValue={new Date()}
              />
              {errors.created_at && (
                <span className="error_style">Entry Date is required</span>
              )}
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Property No.</Text>
              </Row>
              <Controller
                as={Input}
                name="unique_number"
                control={control}
                placeholder="Property No."
              />
              {errors.unique_number && (
                <span className="error_style">Proprty No is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={16}
              lg={16}
              md={16}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Society Name</Text>
              </Row>
              <Controller
                as={Input}
                name="address"
                control={control}
                placeholder="Society Name"
              />
              {errors.unique_number && (
                <span className="error_style">Society Name is required</span>
              )}
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Landmark</Text>
              </Row>
              <Controller
                as={Input}
                name="landmark"
                control={control}
                placeholder="Landmark"
              />
              {errors.landmark && (
                <span className="error_style">Landmark is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Area</Text>
              </Row>
              <Controller
                as={Select}
                showSearch
                control={control}
                options={
                  areas &&
                  areas.length > 0 &&
                  areas.map((val, index) => {
                    return { label: val.name, value: val.id };
                  })
                }
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: "100%" }}
                name="area_id"
              />
              {errors.area_id && (
                <span className="error_style">Area is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Map</Text>
              </Row>
              <Controller
                as={Input}
                name="google_link"
                control={control}
                placeholder="City"
              />
              {errors.google_link && (
                <span className="error_style">google link is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Property Status</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                options={PropertyStatus}
                name="property_status"
                style={{ width: "100%" }}
              />
              {errors.property_status && (
                <span className="error_style">Property Status is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Communication Date</Text>
              </Row>
              <Controller
                as={Input}
                name="last_communication_date"
                control={control}
                placeholder="Last Communication Date"
                type="date"
                format="DD-MM-YYYY"
                // defaultValue={new Date()}
              />
              {errors.created_at && (
                <span className="error_style">
                  {" "}
                  Communication Date is required
                </span>
              )}
            </Col>
          </Row>
          <Row>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Divider plain>Property Area</Divider>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>SB Plot Yard</Text>
              </Row>
              <Controller
                as={Input}
                name="property_area_attributes[super_builtup_area_sqFt]"
                // type="number"
                control={control}
                placeholder="SB Plot Yard"
              />
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Less %</Text>
              </Row>
              <Controller
                as={Input}
                name="property_area_attributes[less_sqFt]"
                type="number"
                control={control}
                placeholder="Less %"
              />
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Carpet Plot Yard</Text>
              </Row>
              <Controller
                as={Input}
                name="property_area_attributes[carpet_area_sqFt]"
                type="number"
                control={control}
                placeholder="Carpet Plot Yard"
              />
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            {/* <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Plot in Yard</Text>
              </Row>
              <Controller
                as={Input}
                name="property_area_attributes[super_plot_area_sqFt]"
                type="number"
                control={control}
                placeholder="Plot in Yard"
              />
            </Col> */}
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Builtup area sqft</Text>
              </Row>
              <Controller
                as={Input}
                name="property_area_attributes[builtup_area_sqFt]"
                type="number"
                control={control}
                placeholder="Builtup area sqft"
              />
            </Col>
            {/* {(property_type === 1 || property_type === 3) &&
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Yard</Text>
              </Row>
              <Controller
                as={Input}
                name="property_area_attributes[land_area_sqFt]"
                type="number"
                control={control}
                placeholder="Yard"
              />
            </Col>
} */}
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Furniture</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                options={Furniture}
                name="property_furniture_attributes[furniture_status]"
                style={{ width: "100%" }}
              />
              {errors.property_type && (
                <span className="error_style">Furniture is required</span>
              )}
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Construction Yard</Text>
              </Row>
              <Controller
                as={Input}
                name="property_area_attributes[construction_yard]"
                control={control}
                placeholder="Enter construction yard"
              />
            </Col>
          </Row>
          <Row>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Divider plain>Cost</Divider>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Price per Yard </Text>
              </Row>
              <Controller
                name="cost_one"
                control={control}
                render={(props) => (
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      value
                        ? `₹ ${new Intl.NumberFormat("en-IN").format(value)}`
                        : "₹ "
                    }
                    parser={(value) => value.replace(/₹\s?|(,*)/g, "")}
                    {...props}
                  />
                )}
              />
              {errors.cost_one && (
                <span className="error_style">Price per Yard is required</span>
              )}
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>price per construction</Text>
              </Row>
              <Controller
                name="cost_two"
                control={control}
                render={(props) => (
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      value
                        ? `₹ ${new Intl.NumberFormat("en-IN").format(value)}`
                        : "₹ "
                    }
                    parser={(value) => value.replace(/₹\s?|(,*)/g, "")}
                    {...props}
                  />
                )}
              />
              {errors.cost_two && (
                <span className="error_style">
                  price per construction is required
                </span>
              )}
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Extra</Text>
              </Row>
              <Controller
                name="cost_three"
                control={control}
                render={(props) => (
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      value
                        ? `₹ ${new Intl.NumberFormat("en-IN").format(value)}`
                        : "₹ "
                    }
                    parser={(value) => value.replace(/₹\s?|(,*)/g, "")}
                    {...props}
                  />
                )}
              />
              {errors.cost_three && (
                <span className="error_style">Extra per Sqft is required</span>
              )}
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Total Cost </Text>
              </Row>
              <Controller
                name="cost"
                control={control}
                render={(props) => (
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      value
                        ? `₹ ${new Intl.NumberFormat("en-IN").format(value)}`
                        : "₹ "
                    }
                    parser={(value) => value.replace(/₹\s?|(,*)/g, "")}
                    {...props}
                  />
                )}
              />
              {errors.cost && (
                <span className="error_style">Total Cost is required</span>
              )}
            </Col>
          </Row>
          <Divider />
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Bhk</Text>
              </Row>
              <Controller
                as={Input}
                name="bhk"
                control={control}
                placeholder="Total Bhk"
              />
              {errors.bhk && (
                <span className="error_style">Bhk is required</span>
              )}
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Floor</Text>
              </Row>
              <Controller
                as={Input}
                name="floor"
                control={control}
                placeholder="Floor"
                type="number"
              />
              {errors.floor && (
                <span className="error_style">Floor is required</span>
              )}
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Total Floor</Text>
              </Row>
              <Controller
                as={Input}
                name="total_floor"
                control={control}
                placeholder="Total Floor"
                type="number"
              />
              {errors.total_floor && (
                <span className="error_style">Total Floor is required</span>
              )}
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Society Transfer Fee</Text>
              </Row>
              <Controller
                name="society_transfer_fee"
                control={control}
                render={(props) => (
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      value
                        ? `₹ ${new Intl.NumberFormat("en-IN").format(value)}`
                        : "₹ "
                    }
                    parser={(value) => value.replace(/₹\s?|(,*)/g, "")}
                    {...props}
                  />
                )}
              />
              {errors.society_transfer_fee && (
                <span className="error_style">
                  Society Transfer Fee is required
                </span>
              )}
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Construction Year(Age) </Text>
              </Row>
              <Controller
                as={Input}
                name="construction_year"
                control={control}
                placeholder="Construction Year"
                type="date"
              />
              {errors.construction_year && (
                <span className="error_style">
                  Construction Year is required
                </span>
              )}
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Visit Time</Text>
              </Row>
              <Controller
                as={Input}
                name="visit_time"
                control={control}
                placeholder="Visit Time"
                type="time"
              />
              {errors.visit_time && (
                <span className="error_style">Visit Time is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>About Key</Text>
              </Row>
              <Controller
                as={Input}
                name="about_key"
                control={control}
                placeholder="About Key"
              />
              {errors.about_key && (
                <span className="error_style">About Key is required</span>
              )}
            </Col>
            {/* <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Vastu Complient</Text>
              </Row>
              <Controller
                as={Input}
                name="vastu_complient"
                control={control}
                placeholder="Vastu Complient"
                type="number"
              />
              {errors.vastu_complient && (
                <span className="error_style">Vastu Complient is required</span>
              )}
            </Col> */}
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Typography>Vastu Compliance</Typography>
              <ConfigProvider
                theme={{
                  components: {
                    Segmented: {
                      itemActiveBg: "none",
                      itemColor: "none",
                      itemSelectedBg: "#c0cdf2",
                      itemSelectedColor: "#000",
                      itemHoverBg: "none",
                      itemHoverColor: "none",
                    },
                  },
                }}
              >
                <Segmented
                  options={["Yes", "No"]}
                  value={vastuValue}
                  onChange={setVastuValue}
                  block
                  className="segmentedForm"
                />
              </ConfigProvider>

              {errors.vastu_complient && (
                <span className="error_style">Vastu Complient is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Facing</Text>
              </Row>
              <Controller
                as={Input}
                name="facing"
                control={control}
                placeholder="Facing"
              />
              {errors.facing && (
                <span className="error_style">Facing is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 16]}>
                <Col span={24}></Col>
                <Col>
                  <Text>Fix</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_fix"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 16]}>
                <Col span={24}></Col>
                <Col>
                  <Text>Negotiable</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_negotiable"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 16]}>
                <Col span={24}></Col>
                <Col>
                  <Text>New</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_new"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 16]}>
                <Col span={24}></Col>
                <Col>
                  <Text>Resell</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_resell"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 16]}>
                <Col span={24}></Col>
                <Col>
                  <Text>Rent</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_rent"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 16]}>
                <Col span={24}></Col>
                <Col>
                  <Text>Lease</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_lease"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="notes_box"
            >
              <Row>
                <Text>Details</Text>
              </Row>
              <Controller
                as={TextArea}
                name="details"
                control={control}
                rows={4}
                showCount
                maxLength={100}
              />
              {errors.details && (
                <span className="error_style">Details is required</span>
              )}
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Divider plain>Property Furniture</Divider>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            {/* <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Fix Kitchen</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_furniture_attributes[fix_kitchen]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col> */}
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Furnished</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_furnished"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Modular Kitchen</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_furniture_attributes[mod_kitchen]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            {/* <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Fix Drawing Room</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_furniture_attributes[fix_drawing_room]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col> */}
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Modular Drawing Room</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_furniture_attributes[mod_drawing_room]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            {/* </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col> */}
            {/* <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Fix Bedroom</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_furniture_attributes[fix_bedroom]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col> */}
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Modular Bedroom</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_furniture_attributes[mod_bedroom]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Air Condition</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_furniture_attributes[air_condition]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Divider plain>Property Amenity</Divider>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Water 24hr</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[water_24hr]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Png</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[png]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Security</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[security]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>cctv</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[cctv]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Lift</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[lift]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Common Parking</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[common_parking]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Paid parking</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[paid_parking]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Garden</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[garden]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Children Play Area</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[children_play_area]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Club House</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[club_house]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Gymnasium</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[gymnasium]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Common Plot</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[common_plot]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Divider plain>Property Nearby</Divider>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Religious Place</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_nearby_attributes[religious_place]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Park Garden</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_nearby_attributes[park_garden]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Educational Institute</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_nearby_attributes[educational_institute]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Hospital/Dispansary</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_nearby_attributes[hospital_dispansary]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Mall/market</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_nearby_attributes[mall_market]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Bus Stop</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_nearby_attributes[bus_stop]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify="end" gutter={[8, 16]}>
            <Col span={24}></Col>
            {!isCreatePropertyLoading && current > 0 && (
              <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                Previous
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                Next
              </Button>
            )}

            {current === steps.length - 1 && (
              <>
                {isCreatePropertyLoading ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 24,
                        }}
                        spin
                      />
                    }
                  />
                ) : (
                  <Button type="primary" htmlType="submit">
                    Create
                  </Button>
                )}
              </>
            )}
          </Row>
        </form>
      </Modal>
      {bunglowProperties && (
        <AllResidentialProperties
          data={bunglowProperties}
          handleDialog={() => setCreateDialog(true)}
          itemKey={itemKey}
        />
      )}
    </Spin>
  );
};
export default BunglowProperties;

const AllResidentialProperties = (props) => {
  const dispatch = useDispatch();
  const { areas } = useSelector((state) => state.areas);
  const { clients } = useSelector((state) => state.clients);
  const { tasks, isGetTasksLoading } = useSelector((state) => state.tasks);
  const { users } = useSelector((state) => state.users);
  const { handleDialog, itemKey } = props;
  const [showTask, setShowTask] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [currentProperty, setCurrentProperty] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const onRowSelect = (record) => {
    setCurrentProperty(record);
    setShowSearch(true);
    setShowTask(true);
  };
  const onTaskRowClick = (task) => {};
  // useEffect(() => {
  //   if (createPropery !== {}) {
  //   }
  // }, [currentProperty]);

  useEffect(() => {
    if (currentProperty && Object.keys(currentProperty).length > 0) {
      dispatch(getAllTasks(currentProperty.id));
    }
  }, [currentProperty, dispatch]);

  let searchInput = useRef("");

  const getColumnSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText(selectedKeys[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button>
          </Space>
        </div>
      ),

      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "",

      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    };
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const headers = [
    { label: "Area", key: "area.name" },
    { label: "Property", key: "property_type" },
    { label: "Address", key: "address" },
    { label: "Property No.", key: "unique_number" },
    { label: "BHK", key: "bhk" },
    { label: "Construction Year(Age)", key: "construction_year" },
    { label: "Facing", key: "facing" },
    {
      label: "Furniture",
      key: "property_furniture_attributes.furniture_status",
    },
    { label: "SB Plot Yard", key: "property_area_attributes" },
    { label: "Less %", key: "property_area_attributes.less_sqFt" },
    {
      label: "Carpet Plot Yard",
      key: "property_area_attributes.carpet_area_sqFt",
    },
    {
      label: "Construction Yard",
      key: "property_area_attributes.construction_yard",
    },
    { label: "Price per Yard", key: "cost_one" },
    { label: "Extra", key: "cost_three" },
    { label: "Total", key: "cost" },
    { label: "Map", key: "google_link" },
    { label: "Client Name", key: "client_name" },
    { label: "Entry Date", key: "created_at" },
    { label: "Chat", key: "last_communication_date" },
    { label: "Property Status", key: "property_status" },
  ];

  const columns = (areas, clients) => [
    {
      title: "Area",
      dataIndex: "area",
      key: "1",
      width: 100,
      fixed: "left",
      render: (record, object) => {
        return <div>{record.name}</div>;
      },
      filters:
        areas &&
        areas.length > 0 &&
        areas.map((area, index) => {
          return { text: area.name, value: area.name };
        }),
      onFilter: (value, record) => {
        return record.area.name.indexOf(value) === 0;
      },
    },
    {
      title: "Property",
      dataIndex: "property_type",
      key: "2",
      width: 100,

      // ...getColumnSearchProps("property_type"),
      filters: [
        {
          text: "Bunglow",
          value: "Bunglow",
        },
        {
          text: "Tenament",
          value: "Tenament",
        },
        {
          text: "Duplex",
          value: "Duplex",
        },
        {
          text: "Row House",
          value: "RowHouse",
        },
        {
          text: "Residential Plot",
          value: "ResidentialPlot",
        },
      ],
      onFilter: (value, object, record) => {
        return object.property_type.indexOf(value) === 0;
      },
      // filters:
      // (record, object) => {
      //     return { text: object.property_type, value: object.property_type };
      //   },
      // onFilter: (value, record) => {
      //   return console.log({record});
      // },
    },
    {
      title: "Society Name",
      dataIndex: "address",
      key: "3",
      width: 100,
      // render: (record, object) => {
      //   return record ? _.sortBy(record.address) : "";
      // },
    },
    {
      title: "Property No.",
      dataIndex: "unique_number",
      key: "4",
      width: 100,
    },
    {
      title: "BHK",
      dataIndex: "bhk",
      key: "5",
      width: 80,
      sorter: (a, b) => a.bhk - b.bhk,
    },
    {
      title: "Construction Year(Age)",
      // dataIndex: moment("construction_year", "YYYYMMDD").fromNow(),
      key: "6",
      width: 140,
      render: (object) => {
        const Age = moment(object ? object.construction_year : "").format(
          "YYYYMMDD"
        );
        return <>{object ? moment(Age, "YYYYMMDD").fromNow() : ""}</>;
      },
    },
    {
      title: "Facing",
      dataIndex: "facing",
      key: "7",
      width: 100,
    },
    {
      title: "Furniture",
      dataIndex: "property_furniture_attributes",
      key: "8",
      width: 100,
      render: (record, object) => {
        return <>{record ? record.furniture_status : ""}</>;
      },
    },

    {
      title: "SB Plot Yard",
      dataIndex: "property_area_attributes",
      key: "9",
      width: 100,
      render: (record, object) => {
        return <>{record ? record.super_builtup_area_sqFt : ""}</>;
      },
      sorter: (a, b) => a.floor - b.floor,
    },
    {
      title: "Less %",
      dataIndex: "property_area_attributes",
      key: "10",
      width: 100,
      render: (record, object) => {
        return <>{record ? record.less_sqFt : ""}</>;
      },
      sorter: (a, b) => a.floor - b.floor,
    },
    {
      title: "Carpet Plot Yard",
      dataIndex: "property_area_attributes",
      key: "11",
      width: 120,
      render: (record, object) => {
        return <>{record ? record.carpet_area_sqFt : ""}</>;
      },
      sorter: (a, b) => a.floor - b.floor,
    },
    {
      title: "Construction yard",
      dataIndex: "property_area_attributes",
      key: "12",
      width: 100,
      render: (record, object) => {
        return <>{record ? record.construction_yard : ""}</>;
      },
    },
    {
      title: "Price per Yard",
      dataIndex: "cost_one",
      key: "13",
      width: 100,
      render: (cost_one) => {
        if (cost_one == null) return null;
        const formattedCost = new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 0,
        }).format(cost_one);

        return <div>{formattedCost.replace("₹", "₹ ")}</div>;
      },
      sorter: (a, b) => a.cost_one - b.cost_one,
    },
    {
      title: "Extra",
      dataIndex: "cost_three",
      key: "14",
      width: 100,
      render: (cost_three) => {
        if (cost_three == null) return null;
        const formattedCost = new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 0,
        }).format(cost_three);

        return <div>{formattedCost.replace("₹", "₹ ")}</div>;
      },
      sorter: (a, b) => a.cost_three - b.cost_three,
    },
    {
      title: "Total",
      dataIndex: "cost",
      key: "15",
      width: 100,
      render: (cost) => {
        if (cost == null) return null;
        const formattedCost = new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 0,
        }).format(cost);

        return <div>{formattedCost.replace("₹", "₹ ")}</div>;
      },
      sorter: (a, b) => a.cost - b.cost,
    },
    {
      title: "Map",
      dataIndex: "google_link",
      key: "16",
      width: 60,
      render: (id) => (
        <Button type="link" href={id} target="_blank">
          <EnvironmentFilled></EnvironmentFilled>
        </Button>
      ),
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "17",
      width: 100,
      ...getColumnSearchProps("client_name"),
      render: (record, object) => {
        return <Typography className="propertyText">{record}</Typography>;
      },
    },
    {
      title: "Entry Date",
      dataIndex: "created_at",
      key: "18",
      width: 100,
      sorter: (a, b) => a.created_at - b.created_at,
      render: (created_at) => {
        if (!created_at) return "-";
        const formattedDate = moment(created_at, [
          "YYYY-MM-DD",
          "DD/MM/YYYY",
          "MM/DD/YYYY",
          "YYYY-MM-DDTHH:mm:ss.SSSZ",
          "x",
          "X",
        ]).format("DD/MM/YYYY");

        return (
          <div>{formattedDate !== "Invalid date" ? formattedDate : "-"}</div>
        );
      },
    },
    {
      title: "Chat",
      dataIndex: "last_communication_date",
      key: "19",
      width: 100,
      render: (last_communication_date) => {
        if (!last_communication_date) return "-";
        const formattedDate = moment(last_communication_date, [
          "YYYY-MM-DD",
          "DD/MM/YYYY",
          "MM/DD/YYYY",
          "YYYY-MM-DDTHH:mm:ss.SSSZ",
          "x",
          "X",
        ]).format("DD/MM/YYYY");

        return (
          <div>{formattedDate !== "Invalid date" ? formattedDate : "-"}</div>
        );
      },
    },
    {
      title: "Property Status",
      dataIndex: "property_status",
      key: "20",
      width: 100,
      render: (record) => {
        return <>{record ? record : ""}</>;
      },
    },
    {
      title: "id",
      width: 100,
      dataIndex: "id",
      key: "21",
      ...getColumnSearchProps("id"),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      fixed: "right",
      width: 100,
      render: (id) => (
        <ActionComponent
          id={id}
          published={currentProperty.published}
          type="is_residential&is_developer=true"
          itemKey={itemKey}
        />
      ),
    },
  ];

  const Taskcolumns = (users) => [
    {
      title: "User Name",
      dataIndex: "user_name",
      key: "7",
      width: 150,
      ...getColumnSearchProps("user_name"),
      render: (record, object) => {
        return <Button type="link">{record}</Button>;
      },
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      key: "due_date",
      width: 100,
      sorter: (a, b) => a.due_date - b.due_date,
    },
    {
      title: "Communcation Date",
      dataIndex: "communication_date",
      key: "communication_date",
      width: 130,
      sorter: (a, b) => a.communication_date - b.communication_date,
      render: (communication_date) => {
        return <div>{communication_date || "-"}</div>;
      },
    },
    {
      title: "Follow up Date",
      dataIndex: "follow_up_date",
      key: "follow_up_date",
      width: 100,
      sorter: (a, b) => a.follow_up_date - b.follow_up_date,
      render: (follow_up_date) => {
        return <div>{follow_up_date || "-"}</div>;
      },
    },
    {
      title: "Site visit Date",
      dataIndex: "site_visit_date",
      key: "site_visit_date",
      width: 100,
      sorter: (a, b) => a.site_visit_date - b.site_visit_date,
      render: (site_visit_date) => {
        return <div>{site_visit_date || "-"}</div>;
      },
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      width: 100,
      filters: [
        {
          text: "note",
          value: "note",
        },
      ],
      onFilter: (value, record) => record.note.indexOf(value) === 0,
    },
    {
      title: "id",
      width: 100,
      dataIndex: "id",
      key: "21",
      ...getColumnSearchProps("id"),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      fixed: "right",
      width: 100,
      render: (id) => (
        <TaskActionComponent id={id} currentProperty={currentProperty} />
      ),
    },
  ];
  return (
    <>
      <Row
        className="rowWithPadding"
        justify="space-between"
        style={{ marginBottom: "10px " }}
      >
        <Col>
          <Title level={4} style={{ marginTop: "2px" }}>
            All Builder's Bunglow Properties({props.data.length})
          </Title>
        </Col>
        <Col>
          <Row gutter={[16, 0]} align="middle">
            <Col>
              <CSVLink
                data={props.data}
                headers={headers}
                filename={`residential_${new Date().toISOString()}.csv`}
              >
                Download
              </CSVLink>
            </Col>
            <Col>
              <Button type="primary" onClick={() => handleDialog()}>
                Create Builder's Bunglow Property
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <MyTable
        columns={columns(areas, clients)}
        data={props.data}
        onRowSelect={(record) => onRowSelect(record)}
        rowClassName={(record) =>
          record.property_status === "Onhold" ? "onHoldRow" : ""
        }
      />
      {showSearch && showTask && (
        <>
          <Row>
            <Col span={24}>
              <Row className="rowWithPadding" justify="space-between">
                <Col>
                  <Title level={4}>Task({tasks.length})</Title>
                </Col>
              </Row>
              <Spin spinning={isGetTasksLoading}>
                <MyTable
                  className="bunglowtableheading"
                  columns={Taskcolumns(users)}
                  data={tasks}
                  onRowSelect={(record) => onTaskRowClick(record)}
                />
              </Spin>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
const ActionComponent = ({ id, published, type, itemKey }) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const dispatch = useDispatch();
  const [createDialog, setCreateDialog] = useState(false);

  const [current, setCurrent] = React.useState(0);
  // const handleDeleteBunglow = (id,type) => {
  //   setTimeout(() => {
  //     message.success("Delete Bunglow Property successful.");
  //   }, 1000);
  //   dispatch(deletePropery(id,type));
  // };
  const handleDeleteBunglow = (property_id, type) => {
    property_id = id;
    dispatch(
      deletePropery(
        property_id,
        "is_residential&is_developer=true",
        successDeleteBunglowProperty,
        failureDeleteBunglowProperty
      )
    );
  };
  const successDeleteBunglowProperty = () => {
    setTimeout(() => {
      message.success(
        "You have successfully delete builder's bunglow property."
      );
    }, 2000);
    // dispatch(deletePropery(id,type));
  };
  const failureDeleteBunglowProperty = () => {
    setTimeout(() => {
      message.info("Something went wrong.");
    }, 2000);
    // setCreateDialog(false);
    // setCurrent(0);
    // reset();
  };
  const handleUpdatePublished = () => {
    setPopoverVisible(false);
    dispatch(publishedProperty(id, true, type));
  };
  const handleUpdateUnPublished = () => {
    setPopoverVisible(false);
    dispatch(publishedProperty(id, false, type));
  };
  const content = (
    <div>
      <CreateTask
        attachment_id={id}
        attachment_type="Property"
        setPopoverVisible={setPopoverVisible}
      />
      &nbsp;&nbsp;
      <ViewProperty id={id} setPopoverVisible={setPopoverVisible} />
      &nbsp;&nbsp;
      <UpdateBunglowProperties
        id={id}
        setPopoverVisible={setPopoverVisible}
        itemKey={itemKey}
      />
      &nbsp;&nbsp;
      {published ? (
        <Button type="link" onClick={() => handleUpdateUnPublished()}>
          Un Published
        </Button>
      ) : (
        <Button type="link" onClick={() => handleUpdatePublished()}>
          Published
        </Button>
      )}
      &nbsp;&nbsp;
      <Popconfirm
        title="Sure to delete?"
        // onConfirm={() => dispatch(deletePropery(id, type))}
        onConfirm={() => handleDeleteBunglow(id, type)}
      >
        <Button type="link" onClick={() => setPopoverVisible(false)}>
          Delete
        </Button>
      </Popconfirm>
    </div>
  );
  return (
    <Popover
      content={content}
      open={popoverVisible}
      trigger="click"
      onOpenChange={setPopoverVisible}
    >
      <Button type="link" onClick={() => setPopoverVisible(!popoverVisible)}>
        Action
      </Button>
    </Popover>
  );
};

const TaskActionComponent = ({ id, currentProperty }) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const dispatch = useDispatch();
  const content = (
    <div>
      <Popconfirm
        title="Sure to delete?"
        onConfirm={() =>
          dispatch(deleteTask(id, { attachment_id: currentProperty.id }))
        }
      >
        <Button type="link" onClick={() => setPopoverVisible(false)}>
          Delete
        </Button>
      </Popconfirm>
    </div>
  );
  return (
    <Popover
      content={content}
      open={popoverVisible}
      trigger="click"
      onOpenChange={setPopoverVisible}
    >
      <Button type="link" onClick={() => setPopoverVisible(!popoverVisible)}>
        Action
      </Button>
    </Popover>
  );
};
