import API from "../Utils/API";

export const getAllProjects = (type) => (dispatch) => {
  const projectType = type ? `?${type}` : "";
  dispatch({ type: "GET_PROJECTS_REQUEST" });
  API.get(`/projects${projectType}`)
    .then((response) => {
      dispatch({
        type: "GET_PROJECTS_SUCCESS",
        payload: response.data,
        getType: type,
      });
    })
    .catch((error) => {
      dispatch({ type: "GET_PROJECTS_FAILURE", payload: error.response?.data });
    });
};

export const createProject =
  (data, type, successCreateProject, failureCreateProject) => (dispatch) => {
    dispatch({ type: "CREATE_PROJECT_REQUEST", payload: data });
    var formData = new FormData();

    data?.image_url &&
      data?.image_url?.forEach((file) => {
        formData.append("image_url[]", file);
      });
    data.project_type &&
      formData.append("project[project_type]", data.project_type);
    data.description &&
      formData.append("project[description]", JSON.stringify(data.description));
    data.property_name &&
      formData.append("project[property_name]", data.property_name);
    data.builder && formData.append("project[builder]", data.builder);
    data.no_of_floor &&
      formData.append("project[no_of_floor]", data.no_of_floor);
    data.area_id && formData.append("project[area_id]", data.area_id);
    data.tag_line && formData.append("project[tag_line]", data.tag_line);
    data.possesion && formData.append("project[possesion]", data.possesion);
    data.pre_lease && formData.append("project[pre_lease]", data.pre_lease);
    data.pre_launch && formData.append("project[pre_launch]", data.pre_launch);
    data.google_link &&
      formData.append("project[google_link]", data.google_link);
    data.parking && formData.append("project[parking]", data.parking);
    // formData.append("imageable_id", data.id);
    formData.append("imageable_type", "Project");

    API.post("/projects", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        dispatch({ type: "CREATE_PROJECT_SUCCESS", payload: response });
        dispatch(getAllProjects(type));
        const SPA = successCreateProject ? successCreateProject() : null;
      })
      .catch((error) => {
        try {
          dispatch({
            type: "CREATE_PROJECT_FAILURE",
            payload: error.response.data,
          });

          const FPA = failureCreateProject ? failureCreateProject() : null;
        } catch {}
      });
    // .catch(error => {
    //     dispatch({ type: "CREATE_PROJECT_FAILURE", payload: error.response.data })
    // })
  };

export const updateProject =
  (data, type, successUpdateProject, failureUpdateProject) => (dispatch) => {
    dispatch({ type: "UPDATE_PROJECT_REQUEST", payload: data });
    var formData = new FormData();

    const appendIfDefined = (key, value) => {
      if (value !== undefined && value !== null) {
        formData.append(key, value);
      }
    };
    data?.image_url &&
      data?.image_url?.forEach((file) => {
        appendIfDefined("image_url[]", file);
      });

    appendIfDefined("project[project_type]", data.project_type);
    appendIfDefined("project[description]", JSON.stringify(data.description));
    appendIfDefined("project[property_name]", data.property_name);
    appendIfDefined("project[builder]", data.builder);
    appendIfDefined("project[no_of_floor]", data.no_of_floor);
    appendIfDefined("project[area_id]", data.area_id);
    appendIfDefined("project[tag_line]", data.tag_line);
    appendIfDefined("project[possesion]", data.possesion);
    appendIfDefined("project[pre_lease]", data.pre_lease);
    appendIfDefined("project[pre_launch]", data.pre_launch);
    appendIfDefined("project[google_link]", data.google_link);
    appendIfDefined("project[parking]", data.parking);
    API.put(`/projects/${data.id}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        dispatch({ type: "UPDATE_PROJECT_SUCCESS", payload: response });
        dispatch(getAllProjects(type));
        const SPA = successUpdateProject ? successUpdateProject() : null;
      })
      .catch((error) => {
        try {
          dispatch({
            type: "UPDATE_PROJECT_FAILURE",
            payload: error.response.data,
          });

          const FPA = failureUpdateProject ? failureUpdateProject() : null;
        } catch {}
      });
    // })
    // .catch(error => {
    //     dispatch({ type: "UPDATE_PROJECT_FAILURE", payload: error.response.data })
    // })
  };

export const publishedProject =
  (data, type, publishedBoolean) => (dispatch) => {
    dispatch({ type: "PUBLISHED_PROJECT_REQUEST", payload: data });
    API.put(`/published/project/${data}`, { published: publishedBoolean })
      .then((response) => {
        dispatch({ type: "PUBLISHED_PROJECT_SUCCESS", payload: response });
        dispatch(getAllProjects(type));
      })
      .catch((error) => {
        dispatch({
          type: "PUBLISHED_PROJECT_FAILURE",
          payload: error.response.data,
        });
      });
  };

export const deleteProject =
  (project_id, type, successDeleteProject, failureDeleteProject) =>
  (dispatch) => {
    dispatch({ type: "DELETE_PROJECT_REQUEST" });
    API.delete(`/projects/${project_id}`)
      .then((response) => {
        dispatch({ type: "DELETE_PROJECT_SUCCESS", payload: response });
        dispatch(getAllProjects(type));
        const SPA = successDeleteProject ? successDeleteProject() : null;
      })
      .catch((error) => {
        try {
          dispatch({
            type: "DELETE_PROJECT_FAILURE",
            payload: error.response.data,
          });

          const FPA = failureDeleteProject ? failureDeleteProject() : null;
        } catch {}
      });
    // })
    // .catch(error => {
    //     dispatch({ type: "DELETE_PROJECT_FAILURE", payload: error.response.data })
    // })
  };
export const deleteImage =
  (id, successDeleteProject, failureDeleteProject) => (dispatch) => {
    dispatch({ type: "DELETE_IMAGE_REQUEST" });
    API.delete(`/images/${id}`)
      .then((response) => {
        dispatch({ type: "DELETE_IMAGE_SUCCESS", payload: response });
        // dispatch(getAllProjects(type));
        successDeleteProject && successDeleteProject(response);
      })
      .catch((error) => {
        try {
          dispatch({
            type: "DELETE_IMAGE_FAILURE",
            payload: error.response.data,
          });

          // const FPA = failureDeleteProject ? failureDeleteProject() : null;
        } catch {}
      });
  };

export const getProjectCount = () => (dispatch) => {
  dispatch({ type: "GET_PROJECTS_COUNT_REQUEST" });
  API.get(`/project_count`)
    .then((response) => {
      dispatch({
        type: "GET_PROJECTS_COUNT_SUCCESS",
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: "GET_PROJECTS_COUNT_FAILURE",
        payload: error.response?.data,
      });
    });
};
