/*eslint-disable*/
import React from "react";
import { Row, Col, Typography, Image, Card } from "antd";
import "../components/aboutus.css";
import skyScrappers from "../assets/images/skyScrappers.jpg";
import { useBreakpoints } from "./ResponsiveBreakPoints";

const AboutUs = ({areas, allProjects}) => {
 const { isSuperLarge } = useBreakpoints(); 
 const areaLength = areas && areas?.length;

  const aboutusData = [
    {
      number: allProjects || 0,
      title: "Total Properties available",
    },
    {
      number: areaLength || 0,
      title: "Area Served",
    },
    {
      number: "90%",
      title: "Client Satisfaction Rate",
    },
    {
      number: 20,
      title: "Years of experience",
    },
    
  ];
  return (
    <Row
      id="aboutus"
      justify="center"
      style={{ paddingTop: 120, paddingBottom: 120 }}
    >
      <Col xs={22} sm={22} md={22} lg={18} xl={16} xxl={14} style={{maxWidth:isSuperLarge?'40%':''}}>
        <Row
          style={{
            height: "100%",
          }}
          gutter={[20, 20]}
        >
          <Col xs={24} sm={24} md={0}>
            <img
              src={skyScrappers}
              alt="about us"
              width="100%"
              className="aboutusImage"
            />
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Row className="aboutusTextContainer" style={{ height: "100%" }}>
              <Col>
                <Row gutter={[0, 8]}>
                  <Col span={24}>
                    <Typography className="aboutusText">About us</Typography>
                  </Col>
                  <Col span={24}>
                    <Typography className="propertyServices">
                      SVMD Property is committed to delivering exceptional
                      services in buying, selling, and renting properties. With
                      a focus on understanding the unique needs of every client,
                      SVMD Property strives to provide tailored solutions that
                      ensure satisfaction and long-term value. Whether you are
                      seeking your dream home, a commercial investment
                      opportunity, or a rental property, their team of
                      experienced professionals is dedicated to guiding you
                      through every step of the process. With a reputation for
                      reliability and expertise, SVMD Property is your go-to
                      partner for seamless and successful real estate
                      transactions.
                    </Typography>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={12}>
            <Row gutter={[0, 18]}>
              <Col xs={0} sm={0} md={24}>
                <img
                  src={skyScrappers}
                  alt="about us"
                  width="100%"
                  className="aboutusImage"
                />
              </Col>
              <Col span={24}>
                <Row className="aboutusDataContainer" gutter={[2, 6]}>
                  {aboutusData.map((data, index) => {
                    return (
                      <Col span={12} key={index}>
                        <Row justify="center">
                          <Col>
                            <Typography className="aboutusNumber">
                              {data.number}
                            </Typography>
                          </Col>
                          <Col span={24}>
                            <Typography
                              className="propertyServices"
                              style={{ textAlign: "center" }}
                            >
                              {data.title}
                            </Typography>
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
                  <Col></Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default AboutUs;
