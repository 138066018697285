import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Input,
  Typography,
  Button,
  Modal,
  Checkbox,
  Select,
  InputNumber,
  Steps,
  Divider,
  Row,
  message,
  Spin,
  ConfigProvider,
  Segmented,
} from "antd";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { updatePropery, getAllProperties } from "../Actions/propertiesActions";
import "./styles/clients.css";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";

const PropertyType = [
  { value: 11, label: "Godown" },
  { value: 12, label: "Wear House" },
  { value: 13, label: "Residential" },
  { value: 14, label: "Commercial" },
  { value: 15, label: "Agriculture" },
  { value: 16, label: "NonAgriculture" },
  { value: 17, label: "Redevelopment" },
];
const ZoneType = [
  { value: 0, label: "R1" },
  { value: 1, label: "R2" },
  { value: 2, label: "R3" },
];
const PropertyStatus = [
  { value: 0, label: "Available" },
  { value: 1, label: "Sold Out" },
  { value: 2, label: "On hold" },
];
const { Step } = Steps;
const steps = [
  {
    title: "Personal Details",
    // content: 'First-content',
  },
  {
    title: "Property Details",
    // content: 'Second-content',
  },
];

const schema = Yup.object().shape({
  client_id: Yup.string().required("Required"),
  area_id: Yup.string().required("Required"),
  cost: Yup.string().required("Required"),
});
const { TextArea } = Input;
const { Text } = Typography;

export const UpdateLandProperties = ({ id, setPopoverVisible, itemKey }) => {
  const dispatch = useDispatch();
  const { properties, isUpdatePropertyLoading } = useSelector(
    (state) => state.properties
  );
  const { clients } = useSelector((state) => state.clients);
  const { areas } = useSelector((state) => state.areas);

  const the_property = properties.filter((property) => property.id === id);

  const newDate = the_property[0].created_at
    ? the_property[0].created_at.split("/")
    : {};

  const newDate1 = the_property[0].last_communication_date
    ? the_property[0].last_communication_date.split("/")
    : {};

  the_property[0].created_at =
    newDate.length > 2
      ? `${newDate[2]}-${newDate[1]}-${newDate[0]}`
      : newDate[0];

  the_property[0].last_communication_date =
    newDate1.length > 2
      ? `${newDate1[2]}-${newDate1[1]}-${newDate1[0]}`
      : newDate1[0];

  const [open, setOpen] = useState(false);
  const [createDialog, setCreateDialog] = useState(false);
  const [current, setCurrent] = React.useState(0);
  const [vastuValue, setVastuValue] = useState(
    the_property && the_property[0]?.vastu_complient === 0 ? "Yes" : "No"
  );
  the_property[0].area_id = the_property[0].area.id;
  the_property[0].client_id = the_property[0].client.id;

  const { handleSubmit, errors, control, reset, getValues, trigger, setValue } =
    useForm({
      resolver: yupResolver(schema),
      defaultValues: the_property[0],
      mode: "all",
    });

  useEffect(() => {
    if (the_property?.length > 0) {
      const constructionYear = the_property[0].construction_year;

      const formattedDate = constructionYear
        ? moment(constructionYear, moment.ISO_8601, true).isValid()
          ? moment(constructionYear).format("YYYY-MM-DD")
          : ""
        : "";

      const formattedData = {
        ...the_property[0],
        construction_year: formattedDate,
      };
      reset(formattedData);
    }
  }, [JSON.stringify(the_property), reset]);

  const next = () => {
    const {
      client_id: cid,
      area_id: aid,
      property_type: PT,
      cost: cst,
    } = getValues(["client_id", "area_id", "property_type", "cost"]);
    if (current === 0 && (!cid || !aid || !PT)) {
      trigger(["client_id", "area_id", "property_type"]);
    } else if (current === 1 && !cst) {
      trigger(["cost"]);
    } else {
      // if (Object.keys(errors).length === 0){
      setCurrent(current + 1);
      // }
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  // const handleUpdateProperty = (data, event) => {
  //   event.preventDefault();
  //   data.id = id;
  //   dispatch(updatePropery(data,"is_plot", UpdateCallBack));
  // };
  // const UpdateCallBack = (data) => {
  //   setCreateDialog(false);
  //   setCurrent(0);
  //   reset(data);
  //   setOpen(false);
  //   setTimeout(() => {
  //     message.success("Update Land property successful.");
  //   }, 2000);
  //   dispatch(getAllProperties());
  // };
  const handleUpdateProperty = (data, event) => {
    data.is_plot = true;
    data.id = id;
    data.vastu_complient = vastuValue === "Yes" ? 0 : 1;
    event.preventDefault();
    if (itemKey === "22") {
      dispatch(
        updatePropery(
          data,
          "is_plot&is_developer=true",
          successUpdateLandProperty,
          failureUpdateLandProperty
        )
      );
    } else {
      dispatch(
        updatePropery(
          data,
          "is_plot",
          successUpdateLandProperty,
          failureUpdateLandProperty
        )
      );
    }
  };
  const successUpdateLandProperty = () => {
    setOpen(false);
    // reset();
    setTimeout(() => {
      message.success(
        `You have successfully update ${
          itemKey === "22" ? "builder's" : ""
        } land property.`
      );
    }, 2000);
  };

  const failureUpdateLandProperty = () => {
    setTimeout(() => {
      message.info("Something went wrong.");
    }, 2000);
    setOpen(false);
    reset();
  };
  const handleCancel = () => {
    setOpen(false);
    setCurrent(0);
    reset();
  };
  const handleULP = () => {
    setPopoverVisible(false);
    setOpen(true);
  };
  return (
    <>
      <Button
        type="link"
        style={{ cursor: "pointer" }}
        onClick={() => handleULP()}
      >
        Edit
      </Button>
      {/* <EditTwoTone
          style={{ cursor: "pointer" }}
          onClick={() => setOpen(true)}
        /> */}
      <Modal
        title={`Edit ${
          itemKey === "22" ? "Builder's" : ""
        } Land Property details`}
        open={open}
        onCancel={() => handleCancel()}
        footer={null}
        width={800}
        maskClosable={false}
      >
        <Steps
          current={current}
          size="small"
          responsive="true"
          labelPlacement="vertical"
        >
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>

        <form onSubmit={handleSubmit(handleUpdateProperty)}>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>{itemKey === "22" ? "Developer" : "Client"}</Text>
              </Row>
              {itemKey === "22" ? (
                <Controller
                  as={
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      optionLabelProp="label"
                      filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                    >
                      {clients &&
                        clients.length > 0 &&
                        clients.map((val) => {
                          const designation = val.designation
                            ? `(${val.designation})`
                            : "";
                          const displayName = val.company || val.name;

                          return (
                            <Select.Option
                              key={val.id}
                              value={val.id}
                              label={displayName}
                            >
                              <div>
                                <span>{val.company || "N/A"}</span>
                                <br />
                                <span style={{ fontSize: "13px" }}>
                                  {val.name}
                                </span>
                                <span style={{ fontSize: "12px" }}>
                                  {" "}
                                  {designation}
                                </span>
                              </div>
                            </Select.Option>
                          );
                        })}
                    </Select>
                  }
                  control={control}
                  name="client_id"
                />
              ) : (
                <Controller
                  as={Select}
                  showSearch
                  control={control}
                  options={
                    clients &&
                    clients.length > 0 &&
                    clients.map((val, index) => {
                      return { label: val.name, value: val.id };
                    })
                  }
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: "100%" }}
                  name="client_id"
                />
              )}
              {errors.client_id && (
                <span className="error_style">
                  {itemKey === "22" ? "Developer" : "Client"} is required
                </span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Property Type</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                options={PropertyType}
                style={{ width: "100%" }}
                name="property_type"
              />
              {errors.property_type && (
                <span className="error_style">Property Type is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Entry Date</Text>
              </Row>
              <Controller
                as={Input}
                name="created_at"
                control={control}
                placeholder="Entry Date"
                type="date"
                format="DD-MM-YYYY"
                // defaultValue={new Date()}
              />
              {errors.created_at && (
                <span className="error_style">Entry Date is required</span>
              )}
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            {itemKey === "22" ? (
              ""
            ) : (
              <Col
                style={{ display: current === 0 ? "block" : "none" }}
                xl={8}
                lg={8}
                md={8}
                sm={24}
                xs={24}
              >
                <Row>
                  <Text>Property No.</Text>
                </Row>
                <Controller
                  name="unique_number"
                  as={Input}
                  control={control}
                  placeholder="Property No."
                />
                {errors.unique_number && (
                  <span className="error_style">Proprty No is required</span>
                )}
              </Col>
            )}
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              md={itemKey === "22" ? 24 : 16}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Address</Text>
              </Row>
              <Controller
                as={Input}
                name="address"
                control={control}
                placeholder="Address"
              />
              {errors.unique_number && (
                <span className="error_style">Address is required</span>
              )}
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Landmark</Text>
              </Row>
              <Controller
                as={Input}
                name="landmark"
                control={control}
                placeholder="Landmark"
              />
              {errors.landmark && (
                <span className="error_style">Landmark is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Area</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                showSearch
                options={
                  areas &&
                  areas.length > 0 &&
                  areas.map((val, index) => {
                    return { label: val.name, value: val.id };
                  })
                }
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: "100%" }}
                name="area_id"
              />
              {errors.area_id && (
                <span className="error_style">Area is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Google Link</Text>
              </Row>
              <Controller
                as={Input}
                name="google_link"
                control={control}
                placeholder="City"
              />
              {errors.google_link && (
                <span className="error_style">City is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            {/* <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Zone Type</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                options={ZoneType}
                name="zone_type"
                style={{ width: "100%" }}
              />
              {errors.zone_type && (
                <span className="error_style">Zone Type is required</span>
              )}
            </Col> */}
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Property Status</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                options={PropertyStatus}
                name="property_status"
                style={{ width: "100%" }}
              />
              {errors.property_status && (
                <span className="error_style">Property Status is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Last Communication Date</Text>
              </Row>
              <Controller
                as={Input}
                name="last_communication_date"
                control={control}
                placeholder="Last Communication Date"
                type="date"
                format="DD-MM-YYYY"
                // defaultValue={new Date()}
              />
              {errors.created_at && (
                <span className="error_style">
                  Last Communication Date is required
                </span>
              )}
            </Col>
          </Row>
          <Row>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Divider plain>Property Area</Divider>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Super plot area Yard</Text>
              </Row>
              <Controller
                as={Input}
                name="property_area_attributes[super_plot_area_sqFt]"
                type="number"
                control={control}
                placeholder="Super plot area Yard"
              />
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Super buildup area Yard</Text>
              </Row>
              <Controller
                as={Input}
                name="property_area_attributes[super_builtup_area_sqFt]"
                type="number"
                control={control}
                placeholder="Super buildup area Yard"
              />
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Less %</Text>
              </Row>
              <Controller
                as={Input}
                name="property_area_attributes[less_sqFt]"
                type="number"
                control={control}
                placeholder="Less %"
              />
            </Col>
            {/* <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Builtup Area Yard</Text>
              </Row>
              <Controller
                as={Input}
                name="property_area_attributes[builtup_area_sqFt]"
                type="number"
                control={control}
                placeholder="Builtup Area Yard"
              />
            </Col> */}
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Carpet area Yard</Text>
              </Row>
              <Controller
                as={Input}
                name="property_area_attributes[carpet_area_sqFt]"
                type="number"
                control={control}
                placeholder="Carpet area Yard"
              />
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Land area sqft</Text>
              </Row>
              <Controller
                as={Input}
                name="property_area_attributes[land_area_sqFt]"
                type="number"
                control={control}
                placeholder="Enter land area sqft"
              />
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Construction Yard</Text>
              </Row>
              <Controller
                as={Input}
                name="property_area_attributes[construction_yard]"
                control={control}
                placeholder="Enter construction yard"
              />
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Total plots</Text>
              </Row>
              <Controller
                as={Input}
                name="no_of_plots"
                control={control}
                placeholder="Enter total plots"
              />
            </Col>
          </Row>
          <Row>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Divider plain>Cost</Divider>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Cost per Yard</Text>
              </Row>
              <Controller
                name="cost_one"
                control={control}
                render={(props) => (
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      value
                        ? `₹ ${new Intl.NumberFormat("en-IN").format(value)}`
                        : "₹ "
                    }
                    parser={(value) => value.replace(/₹\s?|(,*)/g, "")}
                    {...props}
                  />
                )}
              />
              {errors.cost_one && (
                <span className="error_style">Cost per Yard is required</span>
              )}
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Construction per yard</Text>
              </Row>
              <Controller
                name="cost_two"
                control={control}
                render={(props) => (
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      value
                        ? `₹ ${new Intl.NumberFormat("en-IN").format(value)}`
                        : "₹ "
                    }
                    parser={(value) => value.replace(/₹\s?|(,*)/g, "")}
                    {...props}
                  />
                )}
              />
              {errors.cost_two && (
                <span className="error_style">
                  Construction per yard is required
                </span>
              )}
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Extra cost </Text>
              </Row>
              <Controller
                name="cost_three"
                control={control}
                render={(props) => (
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      value
                        ? `₹ ${new Intl.NumberFormat("en-IN").format(value)}`
                        : "₹ "
                    }
                    parser={(value) => value.replace(/₹\s?|(,*)/g, "")}
                    {...props}
                  />
                )}
              />
              {errors.cost_three && (
                <span className="error_style">Extra cost is required</span>
              )}
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Total Cost </Text>
              </Row>
              <Controller
                name="cost"
                control={control}
                render={(props) => (
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      value
                        ? `₹ ${new Intl.NumberFormat("en-IN").format(value)}`
                        : "₹ "
                    }
                    parser={(value) => value.replace(/₹\s?|(,*)/g, "")}
                    {...props}
                  />
                )}
              />
              {errors.cost && (
                <span className="error_style">Total Cost is required</span>
              )}
            </Col>
          </Row>
          <Divider />
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Facing</Text>
              </Row>
              <Controller
                as={Input}
                name="facing"
                control={control}
                placeholder="Facing"
              />
              {errors.facing && (
                <span className="error_style">Facing is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Typography>Vastu Compliance</Typography>
              <ConfigProvider
                theme={{
                  components: {
                    Segmented: {
                      itemActiveBg: "none",
                      itemColor: "none",
                      itemSelectedBg: "#c0cdf2",
                      itemSelectedColor: "#000",
                      itemHoverBg: "none",
                      itemHoverColor: "none",
                    },
                  },
                }}
              >
                <Segmented
                  options={["Yes", "No"]}
                  value={vastuValue}
                  onChange={setVastuValue}
                  block
                  className="segmentedForm"
                />
              </ConfigProvider>

              {errors.vastu_complient && (
                <span className="error_style">Vastu Complient is required</span>
              )}
            </Col>
            {/* <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Vastu Complient</Text>
              </Row>
              <Controller
                as={Input}
                name="vastu_complient"
                control={control}
                placeholder="Vastu Complient"
                type="number"
              />
              {errors.vastu_complient && (
                <span className="error_style">Vastu Complient is required</span>
              )}
            </Col> */}
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Construction Year</Text>
              </Row>
              <Controller
                as={Input}
                name="construction_year"
                control={control}
                placeholder="Construction Year"
                type="date"
              />
              {errors.construction_year && (
                <span className="error_style">
                  Construction Year is required
                </span>
              )}
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Visit Time</Text>
              </Row>
              <Controller
                as={Input}
                name="visit_time"
                control={control}
                placeholder="Visit Time"
                type="time"
              />
              {errors.visit_time && (
                <span className="error_style">Visit Time is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              md={itemKey === "22" ? 12 : 24}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>About Key</Text>
              </Row>
              <Controller
                as={Input}
                name="about_key"
                control={control}
                placeholder="About Key"
              />
              {errors.about_key && (
                <span className="error_style">About Key is required</span>
              )}
            </Col>
            {itemKey === "22" ? (
              <>
                {" "}
                <Col
                  style={{ display: current === 1 ? "block" : "none" }}
                  xl={6}
                  lg={6}
                  md={6}
                  sm={24}
                  xs={24}
                >
                  <Row>
                    <Text>Minimum size</Text>
                  </Row>
                  <Controller
                    as={Input}
                    name="min_size"
                    control={control}
                    placeholder="Enter minimum size"
                  />
                </Col>
                <Col
                  style={{ display: current === 1 ? "block" : "none" }}
                  xl={6}
                  lg={6}
                  md={6}
                  sm={24}
                  xs={24}
                >
                  <Row>
                    <Text>Maximum size</Text>
                  </Row>
                  <Controller
                    as={Input}
                    name="max_size"
                    control={control}
                    placeholder="Enter maximum size"
                  />
                </Col>
              </>
            ) : (
              ""
            )}
          </Row>

          <Row gutter={[0, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>New</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_new"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Resell</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_resell"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Rent</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_rent"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Lease</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_lease"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="notes_box"
            >
              <Row>
                <Text>Details</Text>
              </Row>
              <Controller
                as={TextArea}
                name="details"
                control={control}
                rows={4}
                showCount
                maxLength={100}
              />
              {errors.details && (
                <span className="error_style">Details is required</span>
              )}
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                display: current === 2 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Fix Drawing Room</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_furniture_attributes[fix_drawing_room]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display: current === 2 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Water 24hr</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[water_24hr]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display: current === 2 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Religious Place</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_nearby_attributes[religious_place]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row justify="end" gutter={[8, 16]}>
            <Col span={24}></Col>
            {!isUpdatePropertyLoading && current > 0 && (
              <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                Previous
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                Next
              </Button>
            )}

            {current === steps.length - 1 && (
              <>
                {isUpdatePropertyLoading ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 24,
                        }}
                        spin
                      />
                    }
                  />
                ) : (
                  <Button type="primary" htmlType="submit">
                    Update
                  </Button>
                )}
              </>
            )}
          </Row>
        </form>
      </Modal>
    </>
  );
};
