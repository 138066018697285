// eslint-disable-next-line
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Card, Typography, Button, Modal, Divider, Carousel } from "antd";
import "./styles/clients.css";
import no_image from "../assets/images/no_image.png";
import location2 from "../assets/images/location2.svg";
import site from "../assets/images/site.svg";
import price from "../assets/images/price.svg";
import areaSquare from "../assets/images/area.svg";
import whatsapp from "../assets/images/whatsapp.svg";
import googleMap from "../assets/images/googleMap.svg";

export const ViewResidentialProjects = ({ id, setPopoverVisible }) => {
  const { projects } = useSelector((state) => state.projects);
  const [open, setOpen] = useState(false);
  const [isPreview, setIsPreview] = useState(false);

  const the_project = projects.filter((project) => project.id === id);
  the_project[0].area_id = the_project[0].area.id;

  const defaultValues = the_project[0];

  const {
    images,
    builder,
    property_name,
    project_type,
    area,
    no_of_floor,
    possesion,
    parking,
    description,
    tag_line,
    published,
    google_link
  } = defaultValues;
  const image = images && images.length > 0 ? images[0].image_url : null;

  const handleOpenRProject = () => {
    setPopoverVisible(false);
    setOpen(true);
  };

  const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER;
  const defaultMessage = process.env.REACT_APP_WHATSAPP_MESSAGE;

  const handlePreview = () => {
    setIsPreview((prev) => !prev);
  };

  const openWhatsApp = () => {
    const encodedMessage = encodeURIComponent(defaultMessage);
    window.open(
      `https://wa.me/${whatsappNumber}?text=${encodedMessage}`,
      "_blank"
    );
  };

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <>
      <Button
        type="link"
        style={{ cursor: "pointer" }}
        onClick={() => handleOpenRProject()}
      >
        ViewProject{" "}
      </Button>
      <Modal
        title={
          <Col
            span={13}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <span>Residential Project Details</span>
            <Button type="primary" onClick={handlePreview}>
              {isPreview ? "Close Preview" : "Preview"}
            </Button>
          </Col>
        }
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        width={600}
      >
        {" "}
        {isPreview ? (
          <div
            style={{ padding: 10, display: "flex", justifyContent: "center" }}
          >
            <Card
              className="pCard"
              bodyStyle={{ padding: 10 }}
              hoverable
              cover={
                <div>
                  <Carousel autoplay>
                    {Array.isArray(images) && images.length > 0 ? (
                      images?.map((image, index) => (
                        <div key={index}>
                          <img
                            alt={`image-${index}`}
                            src={image.image_url.url || no_image}
                            title="All Images"
                            height={250}
                            width="100%"
                            className="projectImage"
                          />
                        </div>
                      ))
                    ) : (
                      <div>
                        <img
                          alt="no_image"
                          src={no_image}
                          title="View All Images"
                          width="100%"
                          height={250}
                          className="projectImage"
                        />
                      </div>
                    )}
                  </Carousel>
                </div>
              }
            >
              <Row gutter={[0, 4]}>
                <Col span={24}>
                  <Typography className="typography1">{builder}</Typography>
                </Col>
                <Col className="custom-scrollbar">
                  <Row>
                    <Col span={24}>
                      <Typography className="propertyServicesText">
                        <span className="projectLocation">
                          <img src={location2} alt="location" />
                          {area && area.name}
                        </span>
                      </Typography>
                    </Col>

                    <Col span={24}>
                      <Typography className="propertyServicesText">
                        <span className="projectLocation">
                          <img src={price} alt="Price" /> {property_name}
                        </span>
                      </Typography>
                    </Col>

                    {description && description?.description.length > 0 && (
                      <Col span={24}>
                        <Row
                          style={{
                            alignItems: "flex-start",
                            display: "flex",
                          }}
                        >
                          <img
                            src={areaSquare}
                            alt="area"
                            style={{
                              paddingTop: "3px",
                              marginRight: "8px",
                            }}
                          />
                          <span>
                            {description?.description?.map((d, i) => (
                              <Col
                                key={i}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                                span={24}
                              >
                                <Typography className="propertyServicesText">
                                  {d.key}
                                </Typography>
                                <Typography className="propertyServicesText">
                                  {d.value}{" "}
                                </Typography>
                              </Col>
                            ))}
                          </span>
                        </Row>
                      </Col>
                    )}
                    <Col span={24}>
                      <Typography className="propertyServicesText">
                        <span className="projectLocation">
                          <img src={site} /> {tag_line}
                        </span>{" "}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Divider style={{ margin: "10px 0px" }} />
                <Col span={24}>
                  <Row justify="center">
                    <Col style={{ marginRight: "10px" }}>
                      <img
                        src={whatsapp}
                        alt="Whatsapp"
                        onClick={() => openWhatsApp()}
                      />
                    </Col>
                    {isValidUrl(google_link) ? (
                      <Col>
                        <img
                          src={googleMap}
                          alt="Google Map"
                          onClick={() => window.open(google_link, "_blank")}
                          style={{ cursor: "pointer" }}
                        />
                      </Col>
                    ) : null}
                  </Row>
                </Col>
              </Row>
            </Card>
          </div>
        ) : (
          <>
            <Col span={24} style={{ padding: "15px 0px" }}>
              <Row gutter={[10, 10]}>
                {Array.isArray(images) && images.length > 0 ? (
                  images.map((image, index) => (
                    <Col key={index}>
                      <img
                        alt={`image-${index}`}
                        src={image?.image_url?.url || no_image}
                        title="View All Images"
                        height={200}
                        width="200px"
                        className="viewProjectImage"
                      />
                    </Col>
                  ))
                ) : (
                  <Col>
                    <img
                      alt="no_image"
                      src={no_image}
                      title="No Images Available"
                      height={200}
                      width="200px"
                      className="projectImage"
                    />
                  </Col>
                )}
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[0, 15]}>
                <Col xs={24} sm={12}>
                  <Typography className="viewProjectTitle">
                    Builder: <span className="viewProjectText">{builder}</span>
                  </Typography>
                </Col>

                <Col xs={24} sm={12}>
                  <Typography className="viewProjectTitle">
                    Property Name:{" "}
                    <span className="viewProjectText">{property_name}</span>
                  </Typography>
                </Col>
                <Col xs={24} sm={12}>
                  <Typography className="viewProjectTitle">
                    Project Type:{" "}
                    <span className="viewProjectText">{project_type}</span>
                  </Typography>
                </Col>
                <Col xs={24} sm={12}>
                  <Typography className="viewProjectTitle">
                    Area: <span className="viewProjectText">{area.name}</span>
                  </Typography>
                </Col>

                <Col xs={24} sm={12}>
                  <Typography className="viewProjectTitle">
                    No Of Floor:{" "}
                    <span className="viewProjectText">{no_of_floor}</span>
                  </Typography>
                </Col>
                <Col xs={24} sm={12}>
                  <Typography className="viewProjectTitle">
                    Possesion:{" "}
                    <span className="viewProjectText">{possesion}</span>
                  </Typography>
                </Col>
                <Col xs={24} sm={12}>
                  <Typography className="viewProjectTitle">
                    Parking: <span className="viewProjectText">{parking}</span>
                  </Typography>
                </Col>

                <Col span={24}>
                  {description && description.description && (
                    <Row gutter={[0, 15]}>
                      {description.description.map((d, i) => (
                        <>
                          <Col xs={24} sm={12}>
                            <Typography className="viewProjectTitle">
                              Type:{" "}
                              <span className="viewProjectText">{d.key}</span>
                            </Typography>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Typography className="viewProjectTitle">
                              SqFt.:{" "}
                              <span className="viewProjectText">{d.value}</span>
                            </Typography>
                          </Col>
                        </>
                      ))}
                    </Row>
                  )}
                </Col>

                <Col span={24}>
                  <Typography className="viewProjectTitle">
                    Tag Line:{" "}
                    <span className="viewProjectText">{tag_line}</span>
                  </Typography>
                </Col>
                <Col span={24}>
                  <Typography className="viewProjectTitle">
                    Published:{" "}
                    <span className="viewProjectText">
                      {published === true ? "Yes" : "No"}
                    </span>
                  </Typography>
                </Col>
              </Row>
            </Col>
          </>
        )}
      </Modal>
    </>
  );
};
