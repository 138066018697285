import { Grid } from "antd";
import { useEffect, useState } from "react";

export const useBreakpoints = () => {
  const screens = Grid.useBreakpoint();
  const [isXXXL, setIsXXXL] = useState(false);

  useEffect(() => {
    const updateXXXL = () => {
      setIsXXXL(window.innerWidth >= 2050);
    };

    updateXXXL(); 
    window.addEventListener("resize", updateXXXL);
    return () => window.removeEventListener("resize", updateXXXL);
  }, []);

  return {
    IsXS: screens.xs, 
    IsSM: screens.sm, 
    IsMD: screens.md,
    IsLG: screens.lg,
    IsXL: screens.xl, 
    IsXXL: screens.xxl,
    IsXXXL: isXXXL,

    isMobile:
      screens.xs ||
      (screens.sm && !screens.md && !screens.lg && !screens.xl && !screens.xxl), 
    isTablet: screens.md,
    isDesktop: screens.lg || screens.xl || screens.xxl,
    isExtraLarge: screens.xxl, 
    isSuperLarge: isXXXL,
  };
};
