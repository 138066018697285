import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Input,
  Typography,
  Button,
  Modal,
  Space,
  Select,
  Row,
  Checkbox,
} from "antd";
// import { EditTwoTone } from "@ant-design/icons";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  getAllPostTasks,
  getAllPreTasks,
  updateTask,
} from "../Actions/tasksAction";
import "./styles/clients.css";
import { useBreakpoints } from "../components/ResponsiveBreakPoints";
import moment from "moment";

const schema = Yup.object().shape({
  due_date: Yup.string().required("Required"),
  note: Yup.string().required("Required"),
});
const { TextArea } = Input;
const { Text } = Typography;
export const UpdatTask = ({ id, type, setPopoverVisible }) => {
  const { isMobile, isTablet } = useBreakpoints();
  const [taskType, setTaskType] = useState("");
  const dispatch = useDispatch();
  let allTasks = [];
  const { preTask, postTask, tasks } = useSelector((state) => state.tasks);
  if (type === "pre") {
    allTasks = preTask;
  } else if (type === "post") {
    allTasks = postTask;
  } else {
    allTasks = tasks;
  }

  const { users } = useSelector((state) => state.users);
  const the_task = allTasks.filter((task) => task.id === id);

  const [taskOpen, setTaskOpen] = useState(false);
  const [dueDateError, setDueDateError] = useState("");
  the_task[0].user_id = the_task[0].user.id;
  const newDate = the_task[0].due_date ? the_task[0].due_date.split("/") : {};
  const communicationDate = the_task[0].communication_date
    ? the_task[0].communication_date.split("/")
    : {};
  const followUpDate = the_task[0].follow_up_date
    ? the_task[0].follow_up_date.split("/")
    : {};
  const siteVisitDate = the_task[0].site_visit_date
    ? the_task[0].site_visit_date.split("/")
    : {};
  the_task[0].due_date =
    newDate.length > 2
      ? `${newDate[2]}-${newDate[1]}-${newDate[0]}`
      : newDate[0];
  the_task[0].communication_date =
    communicationDate.length > 2
      ? `${communicationDate[2]}-${communicationDate[1]}-${communicationDate[0]}`
      : communicationDate[0];
  the_task[0].follow_up_date =
    followUpDate.length > 2
      ? `${followUpDate[2]}-${followUpDate[1]}-${followUpDate[0]}`
      : followUpDate[0];
  the_task[0].site_visit_date =
    siteVisitDate.length > 2
      ? `${siteVisitDate[2]}-${siteVisitDate[1]}-${siteVisitDate[0]}`
      : siteVisitDate[0];
  const { handleSubmit, errors, control, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: the_task[0],
  });
  // const watching = watch();
  // const { area_id } = watch();
  const handleUpdateTask = (data, event = null) => {
    if (event) event.preventDefault();
    data.id = id;
    dispatch(updateTask(data, type));
    setTaskOpen(false);
    reset(data);
  };
  const handleOpenUT = () => {
    setPopoverVisible(false);
    setTaskOpen(true);
  };

  const handleTask = (type) => {
    const updatedTaskType = type === "post" ? true : false;
    const currentDate = moment().format("DD-MM-YYYY");
    const taskDueDate = moment(control.getValues("due_date")).format(
      "DD-MM-YYYY"
    );

    if (!updatedTaskType) {
      if (taskDueDate < currentDate) {
        setDueDateError(
          "You cannot open this task because the due date is in the past."
        );
        return;
      }
    }
    setDueDateError("");
    setTaskType(updatedTaskType);
    handleUpdateTask({ id, is_completed: updatedTaskType });
  };

  return (
    <>
      <Button
        type="link"
        style={{ cursor: "pointer" }}
        onClick={() => handleOpenUT()}
      >
        Edit
      </Button>
      {/* <EditTwoTone
        style={{ cursor: "pointer" }}
        onClick={() => setTaskOpen(true)}
      /> */}
      <Modal
        title="Edit Task details"
        visible={taskOpen}
        onCancel={() => setTaskOpen(false)}
        footer={null}
        maskClosable={false}
      >
        <form onSubmit={handleSubmit(handleUpdateTask)}>
          <Col>
            <Row gutter={[10, 10]}>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Text>Employee Name</Text>
                <Controller
                  as={Select}
                  showSearch
                  control={control}
                  options={
                    users &&
                    users.length > 0 &&
                    users.map((val, index) => {
                      return { label: val.first_name, value: val.id };
                    })
                  }
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: "100%" }}
                  name="user_id"
                />
                {errors.user_id && (
                  <span className="error_style">Employee name is required</span>
                )}
              </Col>

              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Text>Due Date</Text>
                <Controller
                  as={Input}
                  format="DD-MM-YYYY"
                  name="due_date"
                  control={control}
                  placeholder="Due Date"
                  type="date"
                />
                {errors.due_date && (
                  <>
                    <span className="error_style">Due Date is required</span>
                  </>
                )}
                {dueDateError && <span className="error_style">{dueDateError}</span>}
              </Col>

              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Text>Communication Date</Text>
                <Controller
                  as={Input}
                  format="DD-MM-YYYY"
                  name="communication_date"
                  control={control}
                  placeholder="Communication Date"
                  type="date"
                />
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Text>Follow up Date</Text>
                <Controller
                  as={Input}
                  format="DD-MM-YYYY"
                  name="follow_up_date"
                  control={control}
                  placeholder="Follow up Date"
                  type="date"
                />
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Text>Site visit Date</Text>
                <Controller
                  as={Input}
                  format="DD-MM-YYYY"
                  name="site_visit_date"
                  control={control}
                  placeholder="Site visit Date"
                  type="date"
                />
              </Col>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Text>Note</Text>
                <Controller
                  as={TextArea}
                  name="note"
                  control={control}
                  rows={4}
                  showCount
                  maxLength={100}
                />
                {errors.note && (
                  <span className="error_style">Note is required</span>
                )}
              </Col>
              <Col span={24}>
                <Row gutter={[10, 10]} justify="end">
                  <Col>
                    <Button
                      type="primary"
                      block
                      style={{ marginTop: 15, top: 5, background:type==='post'? '#EE4E4E':'#5B913B' }}
                      onClick={() => handleTask(type)}
                    >
                      {type === "post" ? "Close Task" : "Open Task"}
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      style={{ marginTop: 15, top: 5 }}
                    >
                      Update Task
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </form>
      </Modal>
    </>
  );
};
