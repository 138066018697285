/*eslint-disable*/
import React, { useState, useEffect, useRef, useCallback } from "react";
import Highlighter from "react-highlight-words";
import { useSelector, useDispatch } from "react-redux";
import { Anchor, ConfigProvider, Segmented, Tabs, Tooltip } from "antd";
const { Link } = Anchor;
import {
  Col,
  Input,
  Typography,
  Popover,
  Button,
  Modal,
  Spin,
  Space,
  Checkbox,
  Select,
  InputNumber,
  Table,
  Row,
  Popconfirm,
  Steps,
  Divider,
  message,
} from "antd";
import {
  DeleteFilled,
  DownOutlined,
  EditTwoTone,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { getAllAreas } from "../Actions/areasAction";
import { getClients } from "../Actions/clientActions";
import { getAllTasks, deleteTask } from "../Actions/tasksAction";
import { getSearchProperties } from "../Actions/propertiesActions";
import {
  getAllLeads,
  createLead,
  updateLead,
  deleteLead,
  getleadtype,
} from "../Actions/leadsActions";
import MyTable from "../components/table";
import "./styles/clients.css";
import { UpdateLead } from "../pages/updateLead";
import { CreateTask } from "../pages/tasks";
import { ViewLead } from "../pages/viewLead";
import Clients from "../pages/clients";
import { CreateInterested } from "../pages/interested";
import { createInterested } from "../Actions/interestedsAction";
import { CSVLink, CSVDownload } from "react-csv";
import useResizeObserver from "../components/useResizeObserver";
import { debounce, filter } from "lodash";
import { useBreakpoints } from "../components/ResponsiveBreakPoints";
import moment from "moment";
import { getAuth0User } from "../Actions/usersAction";

const LeadType = [
  { value: 1, label: "Flat" },
  { value: 2, label: "Pent House" },
  { value: 3, label: "Bunglow" },
  { value: 4, label: "Tenament" },
  { value: 5, label: "Duplex" },
  { value: 6, label: "Row House" },
  { value: 7, label: "Fam House" },
  { value: 8, label: "Office" },
  { value: 9, label: "Shop" },
  { value: 10, label: "Showroom" },
  { value: 11, label: "Godown" },
  { value: 12, label: "Wear House" },
  { value: 13, label: "Residential" },
  { value: 14, label: "Commercial" },
  { value: 15, label: "Agriculture" },
  { value: 16, label: "NonAgriculture" },
  { value: 17, label: "Redevelopment" },
];
const ClientType = [
  { value: 0, label: "Buyer" },
  { value: 1, label: "Seller" },
  { value: 2, label: "Rent Owner" },
  { value: 3, label: "Rent Client" },
];
const Zone = [
  { value: 1, label: "R1" },
  { value: 2, label: "R2" },
  { value: 3, label: "R3" },
];
const LeadAreaType = [
  { value: 1, label: "SqFt" },
  { value: 2, label: "Yard" },
];
const { Step } = Steps;

const steps = [
  {
    title: "Personal Details",
    // content: 'First-content',
  },
  {
    title: "Lead Details",
    // content: 'Second-content',
  },
  // {
  //   title: "Amenity",
  //   // content: 'Last-content',
  // },
];

const schema = Yup.object().shape({
  // client_id: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
  mobile_number: Yup.string().required("Required"),
  type_of_client: Yup.string().required("Required"),
  // area_ids: Yup.string().required("Required"),
  // types_of_lead: Yup
  //   .number()
  //   .transform((value) => (isNaN(value) ? undefined : value))
  //   .required("Required"),
  min_budget: Yup.string().required("Required"),
  max_budget: Yup.string().required("Required"),
  // priority: Yup.string().required("Required"),
  // gender: yup.string().required("Required"),
  // dete_of_birth: yup.string().required("Required"),
  // date_of_anniversary: yup.string().required("Required"),
  // address: yup.string().required("Required"),
  // landmark: yup.string().required("Required"),
  // area: yup.string().required("Required"),
  // city: yup.string().required("Required"),
  // budget: yup.string().required("Required"),
  // home_number: yup.string().matches(phoneRegExp).required("Required"),
  // office_number: yup.string().matches(phoneRegExp).required("Required"),
  // email: yup.string().email().required("Required"),
  // business: yup.string().required("Required"),
  // company: yup.string().required("Required"),
  // designation: yup.string().required("Required"),
  // reference: yup.string().required("Required"),
  // note: yup.string().required("Required"),
});
const { TextArea } = Input;
const { Text, Title } = Typography;

const Leads = (props) => {
  const { history, currentUser } = props;
  const dispatch = useDispatch();
  const { isMobile } = useBreakpoints();
  const {
    clients,
    areas,
    leads,
    isGetLeadsLoading,
    isCreateLeadLoading,
    isUpdateLeadLoading,
    isDeleteLeadLoading,
    lead_typs,
  } = useSelector((state) => {
    const {
      leads,
      isGetLeadsLoading,
      isCreateLeadLoading,
      isUpdateLeadLoading,
      isDeleteLeadLoading,
      lead_typs,
    } = state.leads;
    const { clients } = state.clients;
    const { areas } = state.areas;
    return {
      clients,
      areas,
      leads,
      isGetLeadsLoading,
      isCreateLeadLoading,
      isUpdateLeadLoading,
      isDeleteLeadLoading,
      lead_typs,
    };
  });

  const [isurgentLead, setIsUrgentLead] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectMultipleArea, setSelectMultipleArea] = useState([]);
  const [selectMultipleLeadTypes, SetselectMultipleLeadTypes] = useState([]);
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [vastuValue, setVastuValue] = useState("Yes");
  const [selectedValues, setSelectedValues] = useState(
    selectMultipleArea || []
  );
  const [allLeads, setAllLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);

  useEffect(() => {
    const fetchLeads = () => {
      const url = selectedUser
        ? `/leads?user_id=${selectedUser}`
        : `/leads`;

      dispatch(getAllLeads(url, successCallbackLeads));
    };

    fetchLeads();
  }, [selectedUser]);

  useEffect(() => {
    filterLeads(isurgentLead);
  }, [isurgentLead, allLeads]);


  const successCallbackLeads = (res) => {
    setAllLeads(res || []); 
    filterLeads(isurgentLead);
  };

  const filterLeads = (urgent) => {
    const filtered = allLeads.filter((lead) => lead.is_urgent === urgent);
    setFilteredLeads(filtered);
  };

  useEffect(() => {
    dispatch(getClients());
    dispatch(getAllAreas());
    dispatch(getleadtype());
  }, []);

  useEffect(() => {
    setSelectedValues(selectMultipleArea);
  }, [selectMultipleArea]);

  useEffect(() => {
    if (selectMultipleArea.length > 0) clearErrors("area_ids");
    if (selectMultipleLeadTypes.length > 0) clearErrors("types_of_lead");
  }, [selectMultipleArea, selectMultipleLeadTypes]);

  const allValues = areas?.map((val) => val.id) || [];
  const isAllSelected = selectedValues.length === allValues.length;

  const handleSelectChange = (values) => {
    if (values.includes("all")) {
      if (isAllSelected) {
        setSelectedValues([]);
        onMultipleSelectAreaHandler([]);
      } else {
        setSelectedValues(allValues);
        onMultipleSelectAreaHandler(allValues);
      }
    } else {
      setSelectedValues(values);
      onMultipleSelectAreaHandler(values);
    }
  };

  const {
    register,
    handleSubmit,
    errors,
    clearErrors,
    control,
    watch,
    reset,
    getValues,
    setValue,
    trigger,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      is_commercial: false,
      is_residential: false,
      is_plot: false,
    },
    mode: "onChange",
  });
  const watching = getValues([
    "mobile_number",
    "is_residential",
    "is_commercial",
    "is_plot",
    "client_id",
    "area_ids",
    "types_of_lead",
    "min_budget",
    "max_budget",
  ]);
  const { is_residential, is_commercial, is_plot } = watching;

  const [createDialog, setCreateDialog] = useState(false);
  // const [createNewClientDialog, setcreateNewClientDialog] = useState(false);
  const [current, setCurrent] = React.useState(0);
  const next = async () => {
    const {
      name: clientName,
      type_of_client: type_of_client,
      mobile_number: mobileNumber,
      client_id: cid,
      area_ids: aid,
      types_of_lead: lT,
      min_budget: minbgt,
      max_budget: maxbgt,
    } = getValues([
      "name",
      "type_of_client",
      "mobile_number",
      "client_id",
      "area_ids",
      "types_of_lead",
      "min_budget",
      "max_budget",
    ]);

    // Validate all fields at once
    const isValid = await trigger([
      "name",
      "type_of_client",
      "mobile_number",
      "client_id",
      "area_ids",
      "types_of_lead",
      "min_budget",
      "max_budget",
    ]);

    if (!isValid) {
      return; // Stop execution if validation fails
    }

    if (selectMultipleArea.length === 0) {
      setError("area_ids", {
        type: "required",
        message: "Area is required",
      });
    }

    if (selectMultipleLeadTypes.length === 0) {
      setError("types_of_lead", {
        type: "required",
        message: "Lead type is required",
      });
    }

    // Ensure all custom errors are checked
    if (Object.keys(errors).length > 0) {
      return;
    }

    // If no errors, proceed to the next step
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  // const handleCreateLead = (data, event) => {
  //   // event.preventDefault();
  //   dispatch(createLead(data, `/leads?is_urgent=${isurgentLead}`));
  //   setTimeout(() => {
  //     message.success("Create Lead successful.");
  //   }, 1000);
  //   setCreateDialog(false);
  //   setCurrent(0);
  //   reset();
  // };
  const handleCreateLead = (data, event) => {
    // data.is_lead = true;
    const area = selectMultipleArea.map((id) => String(id));
    data.type_of_client = parseInt(data.type_of_client, 10);
    data.area_ids = area;
    data.types_of_lead = { types: selectMultipleLeadTypes };
    data.vastu_complient = vastuValue === "Yes" ? 0 : 1;
    dispatch(
      createLead(
        data,
        `/leads`,
        successCreateLead,
        failureCreateLead
        // IsEmpty
      )
    );
  };

  const successCreateLead = () => {
    reset();
    setCurrent(0);
    setCreateDialog(false);
    SetselectMultipleLeadTypes([]);
    setSelectMultipleArea([]);
    clearErrors();
    setTimeout(() => {
      message.success("You have successfully create lead.");
    }, 2000);
    dispatch(getAllLeads(`/leads`, successCallback));
  };
  const successCallback = (res) => {
    setAllLeads(res || []); 
    filterLeads(isurgentLead);
  }

  const failureCreateLead = () => {
    setTimeout(() => {
      message.info("Something went wrong.");
    }, 2000);
    setCreateDialog(false);
    setCurrent(0);
    reset();
  };
  const handleCancel = () => {
    setCreateDialog(false);
    setCurrent(0);
    SetselectMultipleLeadTypes([]);
    setSelectMultipleArea([]);
    setValue("name", "");
    setValue("type_of_client", "");
    setValue("mobile_number", "");
    setValue("min_budget", "");
    setValue("max_budget", "");
    reset();
    clearErrors();
  };
  const onMultipleSelectAreaHandler = (data) => {
    setSelectMultipleArea(data);
    setError("area_ids", "");
  };

  const onMultipleSelectLeadTypeHandler = (data) => {
    SetselectMultipleLeadTypes(data);
  };

  const handleResize = useCallback(
    debounce(() => {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;

      if (newWidth !== size.width || newHeight !== size.height) {
        setSize({ width: newWidth, height: newHeight });
      }
    }, 200),
    [size]
  );
  useResizeObserver(handleResize);

  return (
    <Spin
      spinning={
        isGetLeadsLoading ||
        isCreateLeadLoading ||
        isUpdateLeadLoading ||
        isDeleteLeadLoading
      }
    >
      {/* <Button
				type="text"
				onClick={() => setCreateDialog(true)}
				style={{ fontWeight: 500, color: "#1890ff" }}
			>
				Create Lead
			</Button> */}
      <Modal
        title="Create Lead"
        visible={createDialog}
        onCancel={() => handleCancel()}
        footer={null}
        width={800}
        maskClosable={false}
      >
        <Row>
          {/* <Col 
           xl={24}
           lg={24}
           md={24}
           sm={24}
           xs={24}
         > */}
          <Steps
            current={current}
            size="small"
            responsive="true"
            labelPlacement="vertical"
          >
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          {/* </Col> */}
        </Row>
        <form onSubmit={handleSubmit(handleCreateLead)}>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Client name</Text>
              </Row>
              <Controller
                as={Input}
                name="name"
                control={control}
                placeholder="Enter client name"
              />
              {errors.name && (
                <span className="error_style">Client name is required</span>
              )}
            </Col>
            <Col
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
              style={{ display: current === 0 ? "block" : "none" }}
            >
              <Row>
                <Text>Client Type</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                options={ClientType}
                style={{ width: "100%" }}
                name="type_of_client"
              />
              {errors.type_of_client && (
                <span className="error_style">Client Type is required</span>
              )}
            </Col>
            <Col
              xl={8}
              lg={8}
              md={8}
              sm={8}
              xs={24}
              style={{ display: current === 0 ? "block" : "none" }}
            >
              <Row>
                <Text>Client Mobile number</Text>
              </Row>
              <Controller
                as={Input}
                name="mobile_number"
                control={control}
                placeholder="Mobile number"
                minLength={10}
                maxLength={10}
              />
              {errors.mobile_number && (
                <span className="error_style">Mobile number is required</span>
              )}
            </Col>
            {/* <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Client</Text>
              </Row>
              <Controller
                as={Select}
                showSearch
                control={control}
                options={
                  clients &&
                  clients.length > 0 &&
                  clients.map((val, index) => {
                    return { label: val.name, value: val.id };
                  })
                }
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: "100%" }}
                name="client_id"
              />
              {errors.client_id && (
                <span className="error_style">Client is required</span>
              )}
            </Col> */}
            {/* <Button type="link" onClick={() => setcreateNewClientDialog(true)}>Create New</Button>
        {createDialog && (
        <Clients  
        createNewClientDialog={createNewClientDialog}
        setcreateNewClientDialog={setcreateNewClientDialog} 
          // handleDialog={() => setCreateDialog(true)} 

           />
			)} */}
            {/* <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Client Type</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                options={ClientType}
                style={{ width: "100%" }}
                name="client_type"
              />
              {errors.client_type && (
                <span className="error_style">Client Type is required</span>
              )}
            </Col> */}
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Zone</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                options={Zone}
                name="zone"
                style={{ width: "100%" }}
              />
              {errors.zone && (
                <span className="error_style">Zone is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Areas</Text>
              </Row>
              <Controller
                control={control}
                defaultValue={[]}
                name="area_ids"
                render={({ field }) => (
                  <Select
                    mode="multiple"
                    value={selectedValues}
                    placeholder="Select areas"
                    style={{ width: "100%" }}
                    onChange={handleSelectChange}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    dropdownRender={(menu) => (
                      <>
                        <div
                          style={{
                            padding: "5px 12px",
                            borderBottom: "1px solid #f0f0f0",
                          }}
                        >
                          <Checkbox
                            onChange={() => handleSelectChange(["all"])}
                            checked={isAllSelected}
                          >
                            Select All
                          </Checkbox>
                        </div>
                        {menu}
                      </>
                    )}
                    options={[
                      ...(areas?.map((val) => ({
                        label: val.name,
                        value: val.id,
                      })) || []),
                    ]}
                  />
                )}
              />

              {errors.area_ids && (
                <span className="error_style">Area is required</span>
              )}
            </Col>
            {/* <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
            >
              <Space direction="vertical" className="create_client_field">
                <Text>Priority</Text>
                <Controller
                  as={Input}
                  name="priority"
                  type="number"
                  control={control}
                  placeholder="priority"
                  style={{ width: 200 }}
                />
                {errors.priority && (
                  <span className="error_style">Priority is required</span>
                )}
              </Space>
            </Col> */}
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Lead Types</Text>
              </Row>
              <Controller
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                  <Select
                    mode="multiple"
                    showSearch={false}
                    value={selectMultipleLeadTypes}
                    options={lead_typs}
                    placeholder="Select lead types"
                    style={{ width: "100%" }}
                    onChange={(value) => onMultipleSelectLeadTypeHandler(value)}
                  />
                )}
                name="types_of_lead"
              />
              {errors.types_of_lead && (
                <span className="error_style">Lead Type is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Last Communication Date</Text>
              </Row>
              <Controller
                as={Input}
                name="last_communication_date"
                control={control}
                placeholder="Last Communication Date"
                type="date"
                format="DD-MM-YYYY"
                // defaultValue={new Date()}
              />
              {errors.created_at && (
                <span className="error_style">
                  Last Communication Date is required
                </span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Entry Date</Text>
              </Row>
              <Controller
                as={Input}
                name="created_at"
                control={control}
                placeholder="Entry Date"
                type="date"
                format="DD-MM-YYYY"
                // defaultValue={new Date()}
              />
              {errors.created_at && (
                <span className="error_style">Entry Date1 is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Bhk</Text>
              </Row>
              <Controller
                as={Input}
                name="bhk"
                control={control}
                placeholder="Total Bhk"
                type="number"
              />
              {errors.bhk && (
                <span className="error_style">Bhk is required</span>
              )}
            </Col>
          </Row>
          {/* <Row>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_client_field">
                <Text>is Residential?</Text>
                <Controller
                  name="is_residential"
                  control={control}
                  // defaultValue={false}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => { 
                        setValue('is_commercial', false)
                        setValue('is_plot', false)
                        return props.onChange(e.target.checked)}}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_client_field">
                <Text>is Commercial?</Text>
                <Controller
                  name="is_commercial"
                  control={control}
                  // defaultValue={false}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => { 
                        setValue('is_residential', false)
                        setValue('is_plot', false)
                        return props.onChange(e.target.checked)}}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_client_field">
                <Text>is Plot?</Text>
                <Controller
                  name="is_plot"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => { 
                        setValue('is_residential', false)
                        setValue('is_commercial', false)
                        return props.onChange(e.target.checked)}}
                    />
                  )}
                />
              </Space>
            </Col>
          </Row> */}
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Facing</Text>
              </Row>
              <Controller
                as={Input}
                name="facing"
                control={control}
                placeholder="Facing"
              />
              {errors.facing && (
                <span className="error_style">Facing is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Minimum Budget</Text>
              </Row>
              <Controller
                name="min_budget"
                control={control}
                render={(props) => (
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      value
                        ? `₹ ${new Intl.NumberFormat("en-IN").format(value)}`
                        : "₹ "
                    }
                    parser={(value) => value.replace(/₹\s?|(,*)/g, "")}
                    {...props}
                  />
                )}
              />
              {errors.min_budget && (
                <span className="error_style">Minimum Budget is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Maximum Budget</Text>
              </Row>
              <Controller
                name="max_budget"
                control={control}
                render={(props) => (
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      value
                        ? `₹ ${new Intl.NumberFormat("en-IN").format(value)}`
                        : "₹ "
                    }
                    parser={(value) => value.replace(/₹\s?|(,*)/g, "")}
                    {...props}
                  />
                )}
              />
              {errors.max_budget && (
                <span className="error_style">Maximum Budget is required</span>
              )}
            </Col>
            {/* <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Vastu Complient</Text>
              </Row>
              <Controller
                as={Input}
                name="vastu_complient"
                control={control}
                placeholder="Vastu Complient"
                type="number"
              />
              {errors.vastu_complient && (
                <span className="error_style">Vastu Complient is required</span>
              )}
            </Col> */}
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Typography>Vastu Compliance</Typography>
              <ConfigProvider
                theme={{
                  components: {
                    Segmented: {
                      itemActiveBg: "none",
                      itemColor: "none",
                      itemSelectedBg: "#c0cdf2",
                      itemSelectedColor: "#000",
                      itemHoverBg: "none",
                      itemHoverColor: "none",
                    },
                  },
                }}
              >
                <Segmented
                  options={["Yes", "No"]}
                  value={vastuValue}
                  onChange={setVastuValue}
                  block
                  className="segmentedForm"
                />
              </ConfigProvider>

              {errors.vastu_complient && (
                <span className="error_style">Vastu Complient is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Construction After</Text>
              </Row>
              <Controller
                as={Input}
                name="construction_after"
                control={control}
                placeholder="Construction After"
              />
              {errors.construction_after && (
                <span className="error_style">
                  Construction After is required
                </span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={16}
              lg={16}
              md={16}
              sm={24}
              xs={24}
            >
              <Row gutter={[30, 0]}>
                {isMobile ? " " : <Col span={24}></Col>}
                <Col>
                  <Row gutter={[10, 0]}>
                    <Col>
                      <Text>New</Text>
                    </Col>
                    <Col>
                      <Controller
                        name="is_new"
                        control={control}
                        render={(props) => (
                          <Checkbox
                            {...props}
                            checked={props.value}
                            onChange={(e) => props.onChange(e.target.checked)}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row gutter={[10, 0]}>
                    <Col>
                      <Text>Urgent</Text>
                    </Col>
                    <Col>
                      <Controller
                        name="is_urgent"
                        control={control}
                        render={(props) => (
                          <Checkbox
                            {...props}
                            checked={props.value}
                            onChange={(e) => props.onChange(e.target.checked)}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row gutter={[10, 0]}>
                    <Col>
                      <Text>Resell</Text>
                    </Col>
                    <Col>
                      <Controller
                        name="is_resell"
                        control={control}
                        render={(props) => (
                          <Checkbox
                            {...props}
                            checked={props.value}
                            onChange={(e) => props.onChange(e.target.checked)}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <Row>
            <Col
              style={{
                display:
                  current === 1 && is_residential === true ? "block" : "none",
              }}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_client_field">
                <Text>Home Loan?</Text>
                <Controller
                  name="home_loan"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
          </Row> */}
          {/* <Row>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_client_field">
                <Text>is Rent?</Text>
                <Controller
                  name="is_rent"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_client_field">
                <Text>is Lease?</Text>
                <Controller
                  name="is_lease"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
          </Row> */}
          <Row>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              {" "}
              <Text>Details</Text>
              <Controller
                as={TextArea}
                name="details"
                control={control}
                rows={4}
                showCount
                maxLength={100}
              />
              {errors.details && (
                <span className="error_style">Details is required</span>
              )}
            </Col>
          </Row>
          <Row>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Divider plain>Lead Area</Divider>
            </Col>
          </Row>
          <Row gutter={[8, 24]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Minimum Area</Text>
              </Row>
              <Controller
                as={Input}
                name="lead_area_attributes[minimum_sqFt]"
                type="number"
                control={control}
                placeholder="Minimum Area"
              />
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Maximum Area</Text>
              </Row>
              <Controller
                as={Input}
                name="lead_area_attributes[maximum_sqFt]"
                type="number"
                control={control}
                placeholder="Maximum Area"
              />
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Lead Area Type</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                options={LeadAreaType}
                name="lead_area_type"
                style={{ width: "100%" }}
              />
              {errors.lead_area_type && (
                <span className="error_style">Lead Area Type is required</span>
              )}
            </Col>
          </Row>
          {/* <Button
								type="primary"
								htmlType="submit"
								block
								style={{ top: 10 }}
							>
								Create Lead
							</Button> */}
          <Row>
            <Col
              style={{
                display: current === 2 ? "block" : "none",
              }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Divider plain>Lead Furniture</Divider>
            </Col>
          </Row>
          <Row gutter={[8, 24]}>
            <Col span={24}></Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Fix Kitchen?</Text>
                <Controller
                  name="lead_furniture_attributes[fix_kitchen]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Modular Kitchen?</Text>
                <Controller
                  name="lead_furniture_attributes[mod_kitchen]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Fix Drawing Room?</Text>
                <Controller
                  name="lead_furniture_attributes[fix_drawing_room]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Modular Drawing Room?</Text>
                <Controller
                  name="lead_furniture_attributes[mod_drawing_room]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Fix Bedroom?</Text>
                <Controller
                  name="lead_furniture_attributes[fix_bedroom]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Modular Bedroom?</Text>
                <Controller
                  name="lead_furniture_attributes[mod_bedroom]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Air Condition?</Text>
                <Controller
                  name="lead_furniture_attributes[air_condition]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Divider plain>Lead Amenity</Divider>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Water 24hr?</Text>
                <Controller
                  name="lead_amenity_attributes[water_24hr]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Png?</Text>
                <Controller
                  name="lead_amenity_attributes[png]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Security?</Text>
                <Controller
                  name="lead_amenity_attributes[security]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>cctv?</Text>
                <Controller
                  name="lead_amenity_attributes[cctv]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
          </Row>

          <Row>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Lift?</Text>
                <Controller
                  name="lead_amenity_attributes[lift]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Common Parking?</Text>
                <Controller
                  name="lead_amenity_attributes[common_parking]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Paid parking?</Text>
                <Controller
                  name="lead_amenity_attributes[paid_parking]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Garden?</Text>
                <Controller
                  name="lead_amenity_attributes[garden]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Children Play Area?</Text>
                <Controller
                  name="lead_amenity_attributes[children_play_area]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Club House?</Text>
                <Controller
                  name="lead_amenity_attributes[club_house]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Gymnasium?</Text>
                <Controller
                  name="lead_amenity_attributes[gymnasium]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Common Plot?</Text>
                <Controller
                  name="lead_amenity_attributes[common_plot]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Divider plain>Lead Nearby</Divider>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Religious Place?</Text>
                <Controller
                  name="lead_nearby_attributes[religious_place]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Park Garden?</Text>
                <Controller
                  name="lead_nearby_attributes[park_garden]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Educational Institute?</Text>
                <Controller
                  name="lead_nearby_attributes[educational_institute]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
          </Row>

          <Row>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Hospital/Dispansary?</Text>
                <Controller
                  name="lead_nearby_attributes[hospital_dispansary]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>

            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Mall/market?</Text>
                <Controller
                  name="lead_nearby_attributes[mall_market]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Space direction="horizontal" className="create_checkbox">
                <Text>Bus Stop?</Text>
                <Controller
                  name="lead_nearby_attributes[bus_stop]"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Space>
            </Col>
          </Row>

          <Row justify="end" gutter={[0, 8]}>
            <Col span={24}></Col>
            {!isCreateLeadLoading && current > 0 && (
              <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                Previous
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <>
                {isCreateLeadLoading ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 24,
                        }}
                        spin
                      />
                    }
                  />
                ) : (
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                )}
              </>
            )}
          </Row>
        </form>
      </Modal>
      {leads && (
        <AllLeads
          data={leads}
          handleDialog={() => setCreateDialog(true)}
          isurgentLead={isurgentLead}
          setIsUrgentLead={setIsUrgentLead}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          currentUser={currentUser}
          allLeads={allLeads}
          filteredLeads={filteredLeads}
          setAllLeads={setAllLeads}
          filterLeads={filterLeads}
        />
      )}
    </Spin>
  );
};

const AllLeads = (props) => {
  const dispatch = useDispatch();
  const { TabPane } = Tabs;
  const { areas } = useSelector((state) => state.areas);
  const { clients } = useSelector((state) => state.clients);
  const { tasks, isGetTasksLoading } = useSelector((state) => state.tasks);
  const { searchProperties, isGetSearchPropertiesLoading } = useSelector(
    (state) => state.properties
  );
  const { users, auth0User } = useSelector((state) => state.users);
  const {
    handleDialog,
    isurgentLead,
    setIsUrgentLead,
    currentUser,
    selectedUser,
    setSelectedUser,
    allLeads,
    filteredLeads,
  setAllLeads, filterLeads  } = props;
  const [showTask, setShowTask] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [currentLead, setCurrentLead] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  useEffect(() => {
    dispatch(getAuth0User());
  }, []);

  const auth0db_user = auth0User
    ?.map((user) => {
      return user?.db_user;
    })
    .filter((dbUser) => dbUser !== null && dbUser !== undefined)
    .filter((dbUser) => dbUser.id !== currentUser.id);

  const handleTabChange = (key) => {
    setIsUrgentLead(key === "urgentLeads");
  };

  const onRowSelect = (record) => {
    setCurrentLead(record);
    setShowSearch(true);
    setShowTask(true);
  };
  const onMatchPropertyRowClick = (property) => {};
  const onTaskRowClick = (task) => {};
  useEffect(() => {
    if (currentLead.id) {
      dispatch(getAllTasks(currentLead.id));
      dispatch(getSearchProperties(currentLead.id));
    }
  }, [currentLead]);

  let searchInput = useRef("");
  const getColumnSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText(selectedKeys[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button>
          </Space>
        </div>
      ),

      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "",

      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    };
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const headers = [
    { label: "Client Name", key: "client_name" },
    // { label: "Client Type", key: "client_type" },
    { label: "Zone", key: "zone" },
    { label: "Area", key: "area" },
    { label: "Maximum Area", key: "lead_area_attributes" },
    { label: "Maximum Budget", key: "max_budget" },
    { label: "Entry Date", key: "created_at" },
    { label: "Last communication date", key: "last_communication_date" },
  ];

  const columns = (areas, clients) => [
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "1",
      width: 150,
      ...getColumnSearchProps("client_name"),
      // fixed: 'left',
      render: (record, object) => {
        return <Typography className="empText">{record} </Typography>;
      },
    },
    // {
    //   title: "Client Type",
    //   dataIndex: "client_type",
    //   key: "2",
    //   width: 100,
    //   ...getColumnSearchProps("client_type"),
    //   // sorter: (a, b) => a.home_number - b.home_number,
    // },
    {
      title: "Lead Type",
      dataIndex: "types_of_lead",
      key: "2",
      width: 100,
      // ...getColumnSearchProps("types_of_lead"),
      // sorter: (a, b) => a.home_number - b.home_number,
      render: (types_of_lead) => {
        return (
          <Typography style={{ fontSize: "10px" }}>
            {types_of_lead?.types.join(", ")}{" "}
          </Typography>
        );
      },
    },
    {
      title: "Zone",
      dataIndex: "zone",
      key: "3",
      width: 100,
      ...getColumnSearchProps("zone"),
    },
    {
      title: "Areas",
      dataIndex: "area",
      key: "2",
      width: 100,
      render: (record, object) => {
        const { areas_of_lead } = object;
        if (!areas_of_lead || areas_of_lead.length === 0) return null;

        const displayedAreas = areas_of_lead
          .slice(0, 3)
          .map((area) => area.area_name)
          .join(", ");
        const remainingAreas = areas_of_lead
          .slice(3)
          .map((area) => area.area_name);
        const remainingCount = areas_of_lead.length - 3;
        return (
          <div>
            {displayedAreas}
            {remainingCount > 0 && (
              <Tooltip title={remainingAreas.join(", ")}>
                <span style={{ color: "#1677ff", cursor: "pointer" }}>
                  {" "}
                  +{remainingCount} more
                </span>
              </Tooltip>
            )}
          </div>
        );
      },
      filters:
        areas &&
        areas.length > 0 &&
        areas.map((area, index) => {
          return { text: area.name, value: area.name };
        }),
      onFilter: (value, record) => {
        return record.area.name.indexOf(value) === 0;
      },
    },
    {
      title: "Maximum Area",
      dataIndex: "lead_area_attributes",
      key: "5",
      width: 100,
      render: (record, object) => {
        return <>{record ? record.maximum_sqFt : ""}</>;
      },
      sorter: (a, b) => a.maximum_sqFt - b.maximum_sqFt,
    },
    {
      title: "Maximum Budget",
      dataIndex: "max_budget",
      key: "6",
      width: 100,
      render: (max_budget) => {
        if (max_budget == null) return null;
        const formattedCost = new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 0,
        }).format(max_budget);

        return <div>{formattedCost.replace("₹", "₹ ")}</div>;
      },
      sorter: (a, b) => a.max_budget - b.max_budget,
    },
    {
      title: "Entry Date",
      dataIndex: "created_at",
      key: "7",
      width: 100,
      sorter: (a, b) => a.created_at - b.created_at,
      render: (created_at) => {
        if (!created_at) return "-";
        const formattedDate = moment(created_at, [
          "YYYY-MM-DD",
          "DD/MM/YYYY",
          "MM/DD/YYYY",
          "YYYY-MM-DDTHH:mm:ss.SSSZ",
          "x",
          "X",
        ]).format("DD/MM/YYYY");

        return (
          <div>{formattedDate !== "Invalid date" ? formattedDate : "-"}</div>
        );
      },
    },
    {
      title: "Last communication date",
      dataIndex: "last_communication_date",
      key: "8",
      width: 100,
      sorter: (a, b) => a.last_communication_date - b.last_communication_date,
      render: (last_communication_date) => {
        if (!last_communication_date) return "-";
        const formattedDate = moment(last_communication_date, [
          "YYYY-MM-DD",
          "DD/MM/YYYY",
          "MM/DD/YYYY",
          "YYYY-MM-DDTHH:mm:ss.SSSZ",
          "x",
          "X",
        ]).format("DD/MM/YYYY");

        return (
          <div>{formattedDate !== "Invalid date" ? formattedDate : "-"}</div>
        );
      },
    },
    {
      title: "id",
      width: 50,
      dataIndex: "id",
      key: "9",
      ...getColumnSearchProps("id"),
    },
    // {
    //   title: "Lead Type",
    //   // width: 250,
    //   dataIndex: "lead_type",
    //   key: "1",
    //   // fixed: "left",
    //   filters: [
    //     {
    //       text: "lead_type",
    //       value: "lead_type",
    //     },
    //   ],
    //   onFilter: (value, record) => record.lead_type.indexOf(value) === 0,
    // },
    // {
    //   title: "Total BHK",
    //   // width: 250,
    //   dataIndex: "bhk",
    //   key: "1",
    //   // fixed: "left",
    //   sorter: (a, b) => a.bhk - b.bhk,
    // },
    // {
    //   title: "Minimum Budget",
    //   dataIndex: "min_budget",
    //   key: "10",
    //   // width: 150,
    //   render: (min_budget) => {
    //     return <div>{`₹ ${min_budget}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>;
    //   },
    //   sorter: (a, b) => a.min_budget - b.min_budget,
    // },
    // {
    //   title: "Construction After",
    //   dataIndex: "construction_after",
    //   key: "10",
    //   // width: 150,
    //   filters: [
    //     {
    //       text: "construction_after",
    //       value: "construction_after",
    //     },
    //   ],
    //   onFilter: (value, record) => record.construction_after.indexOf(value) === 0,
    // },
    // {
    //   title: "Details",
    //   dataIndex: "details",
    //   key: "11",
    //   // width: 150,
    //   filters: [
    //     {
    //       text: "details",
    //       value: "details",
    //     },
    //   ],
    //   onFilter: (value, record) => record.details.indexOf(value) === 0,
    // },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      fixed: "right",
      width: 100,
      render: (id) => <ActionComponent id={id} isurgentLead={isurgentLead} filteredLeads={filteredLeads} setAllLeads={setAllLeads} filterLeads={filteredLeads} />,
    },
  ];

  const matchProperties = (areas, clients) => [
    {
      title: "Area",
      dataIndex: "area",
      key: "7",
      width: 100,
      render: (record, object) => {
        return <div>{record.name}</div>;
      },
      filters:
        areas &&
        areas.length > 0 &&
        areas.map((area, index) => {
          return { text: area.name, value: area.id };
        }),
      onFilter: (value, record) => {
        return record.area_id == value;
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "6",
      width: 100,
      filters: [
        {
          text: "address",
          value: "address",
        },
      ],
      onFilter: (value, record) => record.address.indexOf(value) === 0,
    },
    {
      title: "Property no",
      width: 80,
      dataIndex: "unique_number",
      key: "12",
    },
    {
      title: "Super builtup",
      dataIndex: "property_area_attributes",
      key: "4",
      width: 80,
      render: (property_area_attributes) => {
        return (
          <div>{property_area_attributes?.super_builtup_area_sqFt || ""}</div>
        );
      },
    },
    {
      title: "Less",
      dataIndex: "property_area_attributes",
      key: "4",
      width: 80,
      render: (property_area_attributes) => {
        if (!property_area_attributes?.less_sqFt) return null;
        const formattedCost = new Intl.NumberFormat("en-IN", {
          maximumFractionDigits: 0,
        }).format(property_area_attributes?.less_sqFt);

        return <div>{formattedCost}</div>;
      },
    },
    {
      title: "Carpet",
      dataIndex: "property_area_attributes",
      key: "4",
      width: 80,
      render: (property_area_attributes) => {
        if (!property_area_attributes?.carpet_area_sqFt) return null;
        const formattedCost = new Intl.NumberFormat("en-IN", {
          maximumFractionDigits: 0,
        }).format(property_area_attributes?.carpet_area_sqFt);

        return <div>{formattedCost}</div>;
      },
    },
    {
      title: "Cost per yard",
      dataIndex: "cost_one",
      key: "4",
      width: 80,
      render: (cost_one) => {
        if (!cost_one) return null;
        const formattedCost = new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 0,
        }).format(cost_one);

        return <div>{formattedCost}</div>;
      },
    },
    {
      title: "Total cost",
      dataIndex: "cost",
      key: "3",
      width: 100,
      render: (cost) => {
        if (cost == null) return null;
        const formattedCost = new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 0,
        }).format(cost);

        return <div>{formattedCost.replace("₹", "₹ ")}</div>;
      },
      sorter: (a, b) => a.cost - b.cost,
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "7",
      width: 100,
      ...getColumnSearchProps("client_name"),
      render: (record, object) => {
        return <Typography style={{ fontSize: "10px" }}>{record} </Typography>;
      },
    },
    // {
    //   title: "Property Type",
    //   width: 100,
    //   dataIndex: "property_type",
    //   key: "1",
    //   // fixed: "left",
    //   filters: [
    //     {
    //       text: "property_type",
    //       value: "property_type",
    //     },
    //   ],
    //   onFilter: (value, record) => record.property_type.indexOf(value) === 0,
    // },
    // {
    //   title: "Floor",
    //   width: 100,
    //   dataIndex: "floor",
    //   key: "1",
    //   // fixed: "left",
    //   sorter: (a, b) => a.floor - b.floor,
    // },
    // {
    //   title: "Total Floor",
    //   width: 100,
    //   dataIndex: "total_floor",
    //   key: "2",
    //   // fixed: "left",
    //   sorter: (a, b) => a.total_floor - b.total_floor,
    // },
    // {
    //   title: "Society Transfer Fee",
    //   dataIndex: "society_transfer_fee",
    //   key: "4",
    //   width: 100,
    //   render: (society_transfer_fee) => {
    //     if (society_transfer_fee == null) return null;
    //     const formattedCost = new Intl.NumberFormat("en-IN", {
    //       style: "currency",
    //       currency: "INR",
    //       maximumFractionDigits: 0,
    //     }).format(society_transfer_fee);

    //     return <div>{formattedCost.replace("₹", "₹ ")}</div>;
    //   },
    //   sorter: (a, b) => a.society_transfer_fee - b.society_transfer_fee,
    // },
    // {
    //   title: "Visit Time",
    //   dataIndex: "visit_time",
    //   key: "5",
    //   width: 100,
    //   sorter: (a, b) => a.visit_time - b.visit_time,
    // },
    // {
    //   title: "Vastu Complient",
    //   dataIndex: "vastu_complient",
    //   key: "5",
    //   width: 100,
    //   render: (vastu_complient) => {
    //     return <div>{vastu_complient === 0 ? "Yes" : "No"}</div>;
    //   },
    //   sorter: (a, b) => a.vastu_complient - b.vastu_complient,
    // },

    // {
    //   title: "Landmark",
    //   dataIndex: "landmark",
    //   key: "8",
    //   width: 100,
    //   filters: [
    //     {
    //       text: "landmark",
    //       value: "landmark",
    //     },
    //   ],
    //   onFilter: (value, record) => record.landmark.indexOf(value) === 0,
    // },
    // {
    //   title: "City",
    //   dataIndex: "city",
    //   key: "9",
    //   width: 100,
    //   filters: [
    //     {
    //       text: "city",
    //       value: "city",
    //     },
    //   ],
    //   onFilter: (value, record) => record.city.indexOf(value) === 0,
    // },
    // {
    //   title: "Construction Year",
    //   dataIndex: "construction_year",
    //   key: "10",
    //   width: 100,
    //   render: (construction_year) => {
    //     return <div>{moment(construction_year).format("DD/MM/YYYY")}</div>;
    //   },
    //   sorter: (a, b) => a.construction_year - b.construction_year,
    // },
    // {
    //   title: "About Key",
    //   width: 100,
    //   dataIndex: "about_key",
    //   key: "1",
    //   // fixed: "left",
    //   sorter: (a, b) => a.about_key - b.about_key,
    // },
    // {
    //   title: "Details",
    //   dataIndex: "details",
    //   key: "11",
    //   width: 100,
    //   filters: [
    //     {
    //       text: "details",
    //       value: "details",
    //     },
    //   ],
    //   onFilter: (value, record) => record.details.indexOf(value) === 0,
    // },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      fixed: "right",
      width: 100,
      render: (id) => (
        <MatchPropertyActionComponent id={id} currentLead={currentLead} />
      ),
    },
  ];
  const Taskcolumns = (users) => [
    {
      title: "Employee Name",
      dataIndex: "user_name",
      key: "7",
      width: 150,
      ...getColumnSearchProps("user_name"),
      // fixed: 'left',
      render: (record, object) => {
        return (
          <Button
            //  onClick={() => handleViewLead(record)}
            type="link"
          >
            {record}
          </Button>
        );
      },
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "7",
      width: 150,
      ...getColumnSearchProps("client_name"),
      // fixed: 'left',
      render: (record, object) => {
        return <Typography style={{ fontSize: "10px" }}>{record}</Typography>;
      },
    },
    {
      title: "Due Date",
      width: 100,
      dataIndex: "due_date",
      key: "due_date",
      sorter: (a, b) => a.due_date - b.due_date,
    },
    {
      title: "Communcation Date",
      width: 130,
      dataIndex: "communication_date",
      key: "communication_date",
      sorter: (a, b) => a.communication_date - b.communication_date,
      render: (communication_date) => {
        return <div>{communication_date || "-"}</div>;
      },
    },
    {
      title: "Follow up Date",
      width: 100,
      dataIndex: "follow_up_date",
      key: "follow_up_date",
      sorter: (a, b) => a.follow_up_date - b.follow_up_date,
      render: (follow_up_date) => {
        return <div>{follow_up_date || "-"}</div>;
      },
    },
    {
      title: "Site visit Date",
      width: 100,
      dataIndex: "site_visit_date",
      key: "site_visit_date",
      sorter: (a, b) => a.site_visit_date - b.site_visit_date,
      render: (site_visit_date) => {
        return <div>{site_visit_date || "-"}</div>;
      },
    },
    {
      title: "Note",
      width: 100,
      dataIndex: "note",
      key: "note",
      filters: [
        {
          text: "note",
          value: "note",
        },
      ],
      onFilter: (value, record) => record.note.indexOf(value) === 0,
    },
    {
      title: "id",
      width: 100,
      dataIndex: "id",
      key: "1",
      ...getColumnSearchProps("id"),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      fixed: "right",
      width: 100,
      render: (id) => <TaskActionComponent id={id} currentLead={currentLead} />,
    },
  ];

  const handleSelectedOption = (selectedOption) => {
    if (selectedOption) {
      setSelectedUser(selectedOption);
    } else {
      setSelectedUser("");
    }
  };

  const options = auth0db_user.map((user) => {
    return { value: user.id, label: `${user.first_name} ${user.last_name}` };
  });

  return (
    <>
      <Row className="rowWithPadding" justify="space-between">
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          {/* <Row gutter={[12, 0]}>
            <Col>
              <Checkbox checked={isurgentLead} onChange={isUrgentLeadonChange}>
                Urgent Lead ?
              </Checkbox>
            </Col>
            <Col span={24}>
              {isurgentLead ? (
                <Typography style={{ fontSize: "20px", fontWeight: 600 }}>
                  Urgent Leads({props.data.length})
                </Typography>
              ) : (
                <Typography style={{ fontSize: "20px", fontWeight: 600 }}>
                  Leads({props.data.length})
                </Typography>
              )}
            </Col>
          </Row> */}
          <Row gutter={[12, 0]}>
            <Col span={24}>
              <Tabs
                activeKey={isurgentLead ? "urgentLeads" : "leads"}
                onChange={(key) => setIsUrgentLead(key === "urgentLeads")}
                destroyInactiveTabPane
              >
                <TabPane
                  tab={`Leads (${
                    allLeads.filter((lead) => !lead.is_urgent).length
                  })`}
                  key="leads"
                >
                  {/* Render filtered leads */}
                  {filteredLeads.map((lead) => (
                    <div key={lead.id}>{lead.name}</div>
                  ))}
                </TabPane>
                <TabPane
                  tab={`Urgent Leads (${
                    allLeads.filter((lead) => lead.is_urgent).length
                  })`}
                  key="urgentLeads"
                >
                  {/* Render filtered leads */}
                  {filteredLeads.map((lead) => (
                    <div key={lead.id}>{lead.name}</div>
                  ))}
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </Col>

        {/* <Col>
          <Button type="primary" onClick={() => handleDialog()}>
            Create Lead
          </Button>
        </Col> */}
        <Col>
          <Row gutter={[16, 0]} align="middle">
            {currentUser?.role === "Admin" && (
              <Col>
                <Select
                  allowClear
                  placeholder="Select User"
                  style={{ width: "150px" }}
                  options={options}
                  onChange={(selectedOption) => {
                    handleSelectedOption(selectedOption);
                  }}
                />
              </Col>
            )}
            <Col>
              <CSVLink
                data={props.data}
                headers={headers}
                filename={`residential_${new Date().toISOString()}.csv`}
              >
                Download
              </CSVLink>
            </Col>
            <Col>
              <Button type="primary" onClick={() => handleDialog()}>
                Create Lead
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <MyTable
        columns={columns(areas, clients)}
        data={filteredLeads}
        onRowSelect={(record) => onRowSelect(record)}
      />
      {showSearch && showTask && (
        <>
          <Row gutter={[8, 0]}>
            <Col span={24}>
              <Row className="rowWithPadding" justify="space-between">
                <Col>
                  <Title level={4}>
                    Matching Properties({searchProperties.length}) for{" "}
                    {currentLead.client.name}
                  </Title>
                </Col>
              </Row>
              <Spin spinning={isGetSearchPropertiesLoading}>
                <MyTable
                  columns={matchProperties(areas, clients)}
                  data={searchProperties}
                  onRowSelect={(record) => onMatchPropertyRowClick(record)}
                />
              </Spin>
            </Col>
            <Col span={24}>
              <Row className="rowWithPadding" justify="space-between">
                <Col>
                  <Title level={4}>
                    Task({tasks.length})
                    {/* for {currentLead.client.name}'s requirements */}
                  </Title>
                </Col>
              </Row>
              <Spin spinning={isGetTasksLoading}>
                <MyTable
                  columns={Taskcolumns(users)}
                  data={tasks}
                  onRowSelect={(record) => onTaskRowClick(record)}
                />
              </Spin>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

const ActionComponent = ({ id, isurgentLead, filteredLeads, setAllLeads, filterLeads }) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const dispatch = useDispatch();

  const handleDeleteLead = (lead_id, event) => {
    // data.is_lead = true;
    setPopoverVisible(false); 
    lead_id = id;
    dispatch(
      deleteLead(
        id,
        `/leads`,
        successDeleteLead,
        failureDeleteLead
        // IsEmpty
      )
    );
  };
  const successDeleteLead = () => {
    // setCreateDialog(false);
    // setCurrent(0);
    // reset();
    setTimeout(() => {
      message.success("You have successfully delete lead.");
    }, 2000);
    dispatch(getAllLeads(`/leads`, successCallbackDelete));
  };

  const successCallbackDelete = (res) => {
    setAllLeads(res || []); 
    filterLeads(isurgentLead);
  }
  const failureDeleteLead = () => {
    setTimeout(() => {
      message.info("Something went wrong.");
    }, 2000);
    // setCreateDialog(false);
    // setCurrent(0);
    // reset();
  };
  const content = (
    <div>
      <CreateTask
        attachment_id={id}
        attachment_type="Lead"
        setPopoverVisible={setPopoverVisible}
      />
      &nbsp;&nbsp;
      <ViewLead id={id} setPopoverVisible={setPopoverVisible} />
      &nbsp;&nbsp;
      <UpdateLead
        id={id}
        isurgentLead={isurgentLead}
        setPopoverVisible={setPopoverVisible}
        filteredLeads={filteredLeads}
        filterLeads={filterLeads}
        setAllLeads={setAllLeads}
      />
      &nbsp;&nbsp;
      <Popconfirm
        title="Sure to delete?"
        // onConfirm={() => dispatch(deleteLead(id, `/leads?is_urgent=${isurgentLead}`))}
        onConfirm={() =>
          handleDeleteLead(id, `/leads`)
        }
      >
        <Button type="link" onClick={() => setPopoverVisible(false)}>
          Delete
        </Button>
      </Popconfirm>
      {/* <DeleteFilled
				onClick={() => dispatch(deleteLead(id))}
				style={{ cursor: "pointer", color: "red" }}
			/> */}
    </div>
  );
  return (
    <Popover
      content={content}
      open={popoverVisible}
      trigger="click"
      onOpenChange={setPopoverVisible}
    >
      <Button type="link" onClick={() => setPopoverVisible(!popoverVisible)}>
        Action
      </Button>
    </Popover>
  );
};

const TaskActionComponent = ({ id, currentLead }) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const dispatch = useDispatch();
  const content = (
    <div>
      <Popconfirm
        title="Sure to delete?"
        onConfirm={() =>
          dispatch(deleteTask(id, { attachment_id: currentLead.id }))
        }
      >
        <Button type="link" onClick={() => setPopoverVisible(false)}>
          Delete
        </Button>
      </Popconfirm>
    </div>
  );
  return (
    <Popover
      content={content}
      open={popoverVisible}
      trigger="click"
      onOpenChange={setPopoverVisible}
    >
      <Button type="link" onClick={() => setPopoverVisible(!popoverVisible)}>
        Action
      </Button>
    </Popover>
  );
};

const MatchPropertyActionComponent = ({ id, currentLead }) => {
  const dispatch = useDispatch();

  const handleCreateInterested = (data) => {
    // event.preventDefault();

    dispatch(createInterested({ property_id: id, lead_id: currentLead.id }));
  };
  // const dispatch = useDispatch();
  // const content = (
  //   <div>
  //     <CreateInterested property_id={id} lead_id={currentLead} />
  //   </div>
  // );
  return (
    <Button
      type="link"
      onClick={() => handleCreateInterested()}
      block
      // style={{ top: 10 }}
    >
      Interested
    </Button>
    // <Popover content={content} trigger="focus">
    //   <Button type="link">Action 2</Button>
    // </Popover>
  );
};

export default Leads;
