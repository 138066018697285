/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Layout,
  Typography,
  Input,
  Form,
  Card,
  Space,
  InputNumber,
  Select,
  Button,
  Checkbox,
  Segmented,
  ConfigProvider,
  Spin,
  message,
} from "antd";
// import "./styles/navbar.css";
import "../components/bannerimage.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { getAllAreas } from "../Actions/areasAction";
import { createPostPropery } from "../Actions/postPropertyAction";
// import "./styles/clients.css";
import { getPostProperties } from "../Actions/postPropertyAction";
import HomeFooter from "../components/Footer";
import HomeHeader from "../components/Header";
import BannerImage from "../components/BannerImage";
// import banner from "../assets/images/banner.png";
import { SuccessPage } from "../components/SuccessPage";
import { Color } from "antd/es/color-picker";
import { useBreakpoints } from "../components/ResponsiveBreakPoints";

const { Header, Content, Footer } = Layout;
const owner = [
  { label: "Dhruv", value: "Dhruv" },
  { label: "Pooja", value: "Pooja" },
];
const sale = [
  { label: "flat", value: "flat" },
  { label: "bunglow", value: "bunglow" },
];
const PropertyType = [
  { value: 0, label: "Flat" },
  { value: 1, label: "Pent House" },
  { value: 2, label: "Bunglow" },
  { value: 3, label: "Tenament" },
  { value: 4, label: "Duplex" },
  { value: 5, label: "Row House" },
  { value: 6, label: "Fam House" },
  { value: 7, label: "Office" },
  { value: 8, label: "Shop" },
  { value: 9, label: "Showroom" },
  { value: 10, label: "Godown" },
  { value: 11, label: "Wear House" },
  { value: 12, label: "Residential" },
  { value: 13, label: "Commercial" },
  { value: 14, label: "Agriculture" },
  { value: 15, label: "NonAgriculture" },
  { value: 16, label: "Redevelopment" },
];

const { TextArea } = Input;
const { Text, Title } = Typography;

const NewProperty = (props) => {
  const { postProperty } = props;
  const { isSuperLarge } = useBreakpoints();
  const dispatch = useDispatch();
  const history = useHistory();
  const { areas, postProperties, isCreatePostPropertyLoading } = useSelector(
    (state) => {
      const { postProperties, isCreatePostPropertyLoading } =
        state.postProperties;
      const { areas } = state.areas;
      return {
        areas,
        postProperties,
        isCreatePostPropertyLoading,
      };
    }
  );
  const [selectedItems, setSelectedItems] = useState([]);
  const [backendData, setBackendData] = useState({});
  const [vastuValue, setVastuValue] = useState("Yes");

  useEffect(() => {
    dispatch(getAllAreas());
    dispatch(getPostProperties());
  }, []);

  const [termsAgreed, setTermsAgreed] = useState({
    isChecked: false,
    message: null,
  });

  const handleCheckboxChange1 = (value) => {
    setSelectedItems(value);
  };

  const handleCheckboxChange = (e) => {
    setTermsAgreed({
      isChecked: e.target.checked,
      message: null,
    });
  };
  const schema = Yup.object().shape({
    client: Yup.object().shape({
      name: Yup.string().required("Client Name is required"),
      // email: Yup.string().email().required("Email is required"),
      mobile_number: Yup.string().required("Mobile Number is required"),
    }),
    property: Yup.object().shape({
      property_type: Yup.string().required("Property Type is required"),
      area_id: Yup.string().required("Area is required"),
      // cost: yup.number().required("Cost is required"),
      // construction_year: yup.date().required("Construction Year is required"),
    }),
  });

  const { register, handleSubmit, errors, control, reset } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  useEffect(() => {
    const updatedPropertyOptions = propertyOptions.map((option) => {
      return {
        ...option,
        value: selectedItems.includes(option.label),
      };
    });

    const formattedBackendData = updatedPropertyOptions.reduce(
      (acc, option) => {
        acc[option.label] = option.value;
        return acc;
      },
      {}
    );

    setBackendData(formattedBackendData);
  }, [selectedItems]);

  const [createDialog, setCreateDialog] = useState(false);

  const handlePostProperty = (data, event) => {
    if (data.property?.property_type) {
      data.property.property_type = Number(data.property.property_type);
    }
    data.property.vastu_complient = vastuValue === "Yes" ? 0 : 1;
    data.property = {
      ...data.property,
      ...backendData,
      property_furniture_attributes,
      property_amenity_attributes,
      property_nearby_attributes,
      property_area_attributes,
    };
    if (termsAgreed?.isChecked) {
      dispatch(createPostPropery(data, successCallBack, failureCallBack));
    } else {
      setTermsAgreed({
        message: "Please accepts the term and conditons",
      });
    }
    event.preventDefault();
  };
  const successCallBack = (response) => {
    setCreateDialog(true);
    reset();
    setSelectedItems([]);
  };

  const failureCallBack = (response) => {
    setTimeout(() => {
      message.info(`Client ${response?.client[0]}` || response?.error);
    }, 2000);
  };
  const handleReset = () => {
    reset();
    setSelectedItems([]);
  };

  const propertyOptions = [
    { label: "is_residential", value: false },
    { label: "is_commercial", value: false },
    { label: "is_plot", value: false },
    { label: "is_new", value: false },
    { label: "is_resell", value: false },
    { label: "is_rent", value: false },
    { label: "is_lease", value: false },
  ];

  const property_furniture_attributes = {
    air_condition: false,
    attachable_washroom: false,
    electronics: false,
    fix_bedroom: false,
    fix_drawing_room: false,
    fix_kitchen: false,
    mod_bedroom: false,
    mod_drawing_room: false,
    mod_kitchen: false,
  };

  const property_amenity_attributes = {
    children_play_area: false,
    club_house: false,
    common_parking: false,
    common_plot: false,
    garden: false,
    gymnasium: false,
    lift: false,
    paid_parking: false,
    png: false,
    security: false,
    water_24hr: false,
    water_supply: false,
  };

  const property_nearby_attributes = {
    educational_institute: false,
    hospital_dispansary: false,
    mall_market: false,
    park_garden: false,
    religious_place: false,
  };

  const property_area_attributes = {
    builtup_area_sqFt: null,
    carpet_area_sqFt: null,
    land_area_sqFt: null,
    less_sqFt: null,
    super_builtup_area_sqFt: null,
    super_plot_area_sqFt: null,
  };

  return (
    <>
      <Header className="header">
        <HomeHeader postProperty={true} />
      </Header>
      <div className="newPropertyContainer">
        <Row justify="center" align="middle" className="heroContent">
          <Col span={20} className="textContainer">
            <Typography className="heroTitle">Sell & Rent your</Typography>
            <Typography className="heroTitle">Property</Typography>
            <Typography className="heroSubtitle">
              We have build one stop solutions for you.
            </Typography>
          </Col>
        </Row>
      </div>
      <Spin spinning={isCreatePostPropertyLoading}>
        <Row justify="center" style={{ padding: "120px 0px" }}>
          <Col
            xs={22}
            sm={22}
            md={22}
            lg={18}
            xl={16}
            xxl={14}
            style={{ maxWidth: isSuperLarge ? "40%" : "100%" }}
          >
            <Row justify="center" gutter={[0, 10]}>
              <Col span={24}>
                <Typography className="formHeading">
                  <span
                    onClick={() => history.push("/")}
                    style={{ cursor: "pointer" }}
                  >
                    Home
                  </span>{" "}
                  / Post property
                </Typography>
              </Col>
              <Col span={24}>
                <form onSubmit={handleSubmit(handlePostProperty)}>
                  <Row justify="center" gutter={[0, 40]}>
                    <Col span={24}>
                      <Row className="personalDetailsContainer">
                        <Col span={24}>
                          <Typography className="typography1">
                            Personal Details
                          </Typography>
                        </Col>
                        <Col xs={22} sm={22} md={18}>
                          <Form.Item
                            style={{ margin: "8px" }}
                            className="formInputField"
                          >
                            <Typography className="formHeading">
                              Full name{" "}
                              <span style={{ color: "#f84d4d" }}>*</span>
                            </Typography>
                            <Controller
                              as={Input}
                              name="client[name]"
                              control={control}
                              placeholder="Enter your name"
                            />
                            {errors?.client?.name?.message && (
                              <span className="error_style">
                                {errors?.client?.name?.message}
                              </span>
                            )}
                          </Form.Item>
                        </Col>
                        <Col xs={22} sm={22} md={9}>
                          <Form.Item
                            style={{ margin: "8px" }}
                            className="formInputField"
                          >
                            <Typography className="formHeading">
                              Email Address
                            </Typography>
                            <Controller
                              as={Input}
                              name="client[email]"
                              control={control}
                              placeholder="Enter your Email"
                            />
                            {errors.priority && (
                              <span className="error_style">
                                Email-Id is required
                              </span>
                            )}
                          </Form.Item>
                        </Col>
                        <Col xs={22} sm={22} md={9}>
                          <Form.Item
                            style={{ margin: "8px" }}
                            className="formInputField"
                          >
                            <Typography className="formHeading">
                              Phone number{" "}
                              <span style={{ color: "#f84d4d" }}>*</span>
                            </Typography>
                            <Controller
                              as={Input}
                              name="client[mobile_number]"
                              type="number"
                              control={control}
                              placeholder="Enter your phone number"
                            />
                            {errors?.client?.mobile_number?.message && (
                              <span className="error_style">
                                {errors?.client?.mobile_number?.message}
                              </span>
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row className="personalDetailsContainer">
                        <Col xs={22} sm={22} md={18}>
                          <Row gutter={[20, 15]}>
                            {" "}
                            <Col span={24}>
                              <Typography className="typography1">
                                Property Details
                              </Typography>
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              style={{
                                textAlign: "left",
                              }}
                            >
                              <Typography className="formHeading">
                                Property type{" "}
                                <span style={{ color: "#f84d4d" }}>*</span>
                              </Typography>
                              <Controller
                                as={Select}
                                control={control}
                                options={PropertyType.map((val) => ({
                                  label: val.label,
                                  value: val.value + 1,
                                }))}
                                name="property[property_type]"
                                placeholder="Select Property Type"
                                className="formSelectField"
                                style={{ width: "100%", marginBottom: "8px" }}
                              />
                              {errors?.property?.property_type?.message && (
                                <span className="error_style">
                                  {errors?.property?.property_type?.message}
                                </span>
                              )}
                            </Col>
                            {/* <Col
                            xs={24}
                            sm={24}
                            md={12}
                            className="formInputField"
                          >
                            <Typography className="formHeading">
                              Total BHK(Bedroom, Hall, Kitchen)
                            </Typography>
                            <Controller
                              as={Input}
                              name="property[bhk]"
                              control={control}
                              placeholder="Enter total BHK"
                              type="number"
                            />
                            {errors.bhk && (
                              <span className="error_style">
                                Bhk is required
                              </span>
                            )}
                          </Col>
                          <Col
                            xs={24}
                            sm={24}
                            md={12}
                            className="formInputNumberField"
                          >
                            <Typography className="formHeading">
                              Cost <span style={{ color: "#f84d4d" }}>*</span>
                            </Typography>
                            <Controller
                              name="property[cost]"
                              control={control}
                              render={(props) => (
                                <InputNumber
                                  style={{ width: "100%" }}
                                  size="large"
                                  placeholder="₹  Enter cost"
                                  formatter={(value) =>
                                    `${value}`.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )
                                  }
                                  {...props}
                                />
                              )}
                            />
                            {errors?.property?.cost?.message && (
                              <span className="error_style">
                                {errors?.property?.cost?.message}
                              </span>
                            )}
                          </Col>
                          <Col
                            xs={24}
                            sm={24}
                            md={12}
                            className="formInputNumberField"
                          >
                            <Typography className="formHeading">
                              Society Transfer Fee
                            </Typography>

                            <Controller
                              name="property[society_transfer_fee]"
                              control={control}
                              render={(props) => (
                                <InputNumber
                                  style={{ width: "100%" }}
                                  size="large"
                                  placeholder="₹  Enter society transfer cost"
                                  formatter={(value) =>
                                    `${value}`.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )
                                  }
                                  {...props}
                                />
                              )}
                            />
                            {errors.society_transfer_fee && (
                              <span className="error_style">
                                Society Transfer Fee is required
                              </span>
                            )}
                          </Col>
                          <Col
                            xs={24}
                            sm={24}
                            md={12}
                            style={{ textAlign: "left" }}
                          >
                            <Typography className="formHeading">
                              Property listings
                            </Typography>
                            <Select
                              mode="multiple"
                              allowClear
                              placeholder="Select property listings"
                              value={selectedItems}
                              onChange={handleCheckboxChange1}
                              style={{ width: "100%" }}
                              maxTagCount={0}
                              size="large"
                              className="formMultiSelectField"
                              maxTagPlaceholder={() =>
                                `${selectedItems.length} selected`
                              }
                            >
                              <Option value="is_residential">
                                <Checkbox
                                  checked={selectedItems.includes(
                                    "is_residential"
                                  )}
                                >
                                  Residential
                                </Checkbox>
                              </Option>
                              <Option value="is_plot">
                                <Checkbox
                                  checked={selectedItems.includes("is_plot")}
                                >
                                  Plot
                                </Checkbox>
                              </Option>
                              <Option value="is_commercial">
                                <Checkbox
                                  checked={selectedItems.includes(
                                    "is_commercial"
                                  )}
                                >
                                  Commercial
                                </Checkbox>
                              </Option>
                              <Option value="is_new">
                                <Checkbox
                                  checked={selectedItems.includes("is_new")}
                                >
                                  New
                                </Checkbox>
                              </Option>
                              <Option value="is_resell">
                                <Checkbox
                                  checked={selectedItems.includes("is_resell")}
                                >
                                  Resell
                                </Checkbox>
                              </Option>
                              <Option value="is_rent">
                                <Checkbox
                                  checked={selectedItems.includes("is_rent")}
                                >
                                  Rent
                                </Checkbox>
                              </Option>
                              <Option value="is_lease">
                                <Checkbox
                                  checked={selectedItems.includes("is_lease")}
                                >
                                  Lease
                                </Checkbox>
                              </Option>
                            </Select>
                          </Col>
                          <Col xs={24} sm={24} md={12}>
                            <Typography className="formHeading">
                              Vastu Compliance
                            </Typography>
                            <ConfigProvider
                              theme={{
                                components: {
                                  Segmented: {
                                    itemActiveBg: "none",
                                    itemColor: "none",
                                    itemSelectedBg: "#c0cdf2",
                                    itemSelectedColor: "#000",
                                    itemHoverBg: "none",
                                    itemHoverColor: "none",
                                  },
                                },
                              }}
                            >
                              <Segmented
                                options={["Yes", "No"]}
                                value={vastuValue}
                                onChange={setVastuValue}
                                block
                                className="segmented"
                              />
                            </ConfigProvider>

                            {errors.vastu_complient && (
                              <span className="error_style">
                                Vastu Complient is required
                              </span>
                            )}
                          </Col>
                          <Col
                            xs={24}
                            sm={24}
                            md={12}
                            className="formInputField"
                          >
                            <Typography className="formHeading">
                              Construction year{" "}
                              <span style={{ color: "#f84d4d" }}>*</span>
                            </Typography>
                            <Controller
                              as={Input}
                              name="property[construction_year]"
                              control={control}
                              placeholder="Construction Year"
                              type="date"
                              style={{ fontFamily: "Roboto-400" }}
                            />
                            {errors?.property?.construction_year?.message && (
                              <span className="error_style">
                                {errors?.property?.construction_year?.message}
                              </span>
                            )}
                          </Col>
                          <Col
                            xs={24}
                            sm={24}
                            md={12}
                            className="formInputField"
                          >
                            <Typography className="formHeading">
                              Visit Time
                            </Typography>

                            <Controller
                              as={Input}
                              name="property[visit_time]"
                              control={control}
                              placeholder="Visit Time"
                              type="time"
                            />
                            {errors.visit_time && (
                              <span className="error_style">
                                Visit Time is required
                              </span>
                            )}
                          </Col>
                          <Col span={24} className="formInputField">
                            <Typography className="formHeading">Key</Typography>

                            <Controller
                              as={Input}
                              name="property[about_key]"
                              control={control}
                              placeholder="Enter about key details"
                            />
                            {errors.about_key && (
                              <span className="error_style">
                                About Key is required
                              </span>
                            )}
                          </Col>
                          <Col span={24} className="formInputField">
                            <Typography className="formHeading">
                              Facing
                            </Typography>

                            <Controller
                              as={Input}
                              name="property[facing]"
                              control={control}
                              placeholder="Enter about property facing"
                            />
                            {errors.facing && (
                              <span className="error_style">
                                Facing is required
                              </span>
                            )}
                          </Col> */}
                            <Col
                              xs={24}
                              sm={24}
                              md={12}
                              className="formInputField"
                            >
                              <Typography className="formHeading">
                                Address
                              </Typography>

                              <Controller
                                as={Input}
                                name="property[address]"
                                control={control}
                                placeholder="Enter address "
                              />
                              {errors.address && (
                                <span className="error_style">
                                  Address is required
                                </span>
                              )}
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={12}
                              className="formInputField"
                            >
                              <Typography className="formHeading">
                                Landmark
                              </Typography>

                              <Controller
                                as={Input}
                                name="property[landmark]"
                                control={control}
                                placeholder="Enter landmark"
                              />
                              {errors.landmark && (
                                <span className="error_style">
                                  Landmark is required
                                </span>
                              )}
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={12}
                              style={{ textAlign: "left" }}
                            >
                              <Typography className="formHeading">
                                Area <span style={{ color: "#f84d4d" }}>*</span>
                              </Typography>

                              <Controller
                                as={Select}
                                control={control}
                                options={
                                  areas &&
                                  areas.length > 0 &&
                                  areas.map((val, index) => {
                                    return { label: val.name, value: val.id };
                                  })
                                }
                                style={{ width: "100%", marginBottom: "8px" }}
                                name="property[area_id]"
                                className="formSelectField"
                                placeholder="Select area"
                              />
                              {errors.area_id && (
                                <span className="error_style">
                                  Area is required
                                </span>
                              )}
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={12}
                              className="formInputField"
                            >
                              <Typography className="formHeading">
                                City
                              </Typography>

                              <Controller
                                as={Input}
                                name="property[city]"
                                control={control}
                                placeholder="Enter city name"
                              />
                              {errors.city && (
                                <span className="error_style">
                                  City is required
                                </span>
                              )}
                            </Col>
                            <Col span={24} className="formTextAreaField">
                              <Typography className="formHeading">
                                Comments
                              </Typography>
                              <Controller
                                as={TextArea}
                                name="property[details]"
                                placeholder="Enter any other details about property"
                                control={control}
                                rows={4}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24} style={{ textAlign: "center" }}>
                      <Checkbox
                        className="propertyServices"
                        onChange={handleCheckboxChange}
                      >
                        I am agree with terms and conditions.
                      </Checkbox>
                      {termsAgreed?.message && (
                        <span
                          className="error_style"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {termsAgreed?.message}
                        </span>
                      )}
                    </Col>
                    {/* <Card className="formcard">
                    <Row>
                      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Space
                          direction="vertical"
                          className="create_client_field"
                        >
                          <Text>Floor</Text>
                          <Controller
                            as={Input}
                            name="property[floor]"
                            control={control}
                            placeholder="Floor"
                            type="number"
                            style={{ width: 200 }}
                          />
                          {errors.floor && (
                            <span className="error_style">
                              Floor is required
                            </span>
                          )}
                        </Space>
                      </Col>
                      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Space
                          direction="vertical"
                          className="create_client_field"
                        >
                          <Text>Total Floor</Text>
                          <Controller
                            as={Input}
                            name="property[total_floor]"
                            control={control}
                            placeholder="Total Floor"
                            type="number"
                            style={{ width: 200 }}
                          />
                          {errors.total_floor && (
                            <span className="error_style">
                              Total Floor is required
                            </span>
                          )}
                        </Space>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Space
                          direction="horizontal"
                          className="create_client_field"
                        >
                          <Text>Residential</Text>
                          <Controller
                            name="property[is_residential]"
                            control={control}
                            defaultValue={false}
                            render={(props) => (
                              <Checkbox
                                {...props}
                                checked={props.value}
                                onChange={(e) =>
                                  props.onChange(e.target.checked)
                                }
                              />
                            )}
                          />
                        </Space>
                      </Col>
                      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Space
                          direction="horizontal"
                          className="create_client_field"
                        >
                          <Text>Commercial</Text>
                          <Controller
                            name="property[is_commercial]"
                            control={control}
                            defaultValue={false}
                            render={(props) => (
                              <Checkbox
                                {...props}
                                checked={props.value}
                                onChange={(e) =>
                                  props.onChange(e.target.checked)
                                }
                              />
                            )}
                          />
                        </Space>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Space
                          direction="horizontal"
                          className="create_client_field"
                        >
                          <Text>Plot</Text>
                          <Controller
                            name="property[is_plot]"
                            control={control}
                            defaultValue={false}
                            render={(props) => (
                              <Checkbox
                                {...props}
                                checked={props.value}
                                onChange={(e) =>
                                  props.onChange(e.target.checked)
                                }
                              />
                            )}
                          />
                        </Space>
                      </Col>
                      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Space
                          direction="horizontal"
                          className="create_client_field"
                        >
                          <Text>New</Text>
                          <Controller
                            name="property[is_new]"
                            control={control}
                            defaultValue={false}
                            render={(props) => (
                              <Checkbox
                                {...props}
                                checked={props.value}
                                onChange={(e) =>
                                  props.onChange(e.target.checked)
                                }
                              />
                            )}
                          />
                        </Space>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Space
                          direction="horizontal"
                          className="create_client_field"
                        >
                          <Text>Resell</Text>
                          <Controller
                            name="property[is_resell]"
                            control={control}
                            defaultValue={false}
                            render={(props) => (
                              <Checkbox
                                {...props}
                                checked={props.value}
                                onChange={(e) =>
                                  props.onChange(e.target.checked)
                                }
                              />
                            )}
                          />
                        </Space>
                      </Col>
                      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Space
                          direction="horizontal"
                          className="create_client_field"
                        >
                          <Text>Rent</Text>
                          <Controller
                            name="property[is_rent]"
                            control={control}
                            defaultValue={false}
                            render={(props) => (
                              <Checkbox
                                {...props}
                                checked={props.value}
                                onChange={(e) =>
                                  props.onChange(e.target.checked)
                                }
                              />
                            )}
                          />
                        </Space>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Space
                          direction="horizontal"
                          className="create_client_field"
                        >
                          <Text>Lease</Text>
                          <Controller
                            name="property[is_lease]"
                            control={control}
                            defaultValue={false}
                            render={(props) => (
                              <Checkbox
                                {...props}
                                checked={props.value}
                                onChange={(e) =>
                                  props.onChange(e.target.checked)
                                }
                              />
                            )}
                          />
                        </Space>
                      </Col>
                    </Row>
                  </Card> */}
                    <Col span={24} style={{ textAlign: "center" }}>
                      <Button
                        className="clearFormButton"
                        onClick={() => handleReset()}
                      >
                        Clear form
                      </Button>

                      <Button
                        type="primary"
                        htmlType="submit"
                        className="submitFormButton"
                      >
                        Submit form
                      </Button>
                    </Col>
                  </Row>
                </form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
      {createDialog && (
        <SuccessPage
          createDialog={createDialog}
          setCreateDialog={setCreateDialog}
          // handleDialog={() => setCreateDialog(true)}
        />
      )}

      <HomeFooter />
    </>
  );
};
export default NewProperty;
