/*eslint-disable*/
import React, { useState, useEffect, useRef, useCallback } from "react";
import Highlighter from "react-highlight-words";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Col,
  Row,
  Calendar,
  Badge,
  List,
  Divider,
  Typography,
  Popover,
  Spin,
  Button,
  Input,
  Modal,
  Space,
  notification,
  Popconfirm,
  Select,
} from "antd";
import "../App.css";
import * as Yup from "yup";
import { getAllPreTasks, getAllPostTasks } from "../Actions/tasksAction";
import { getBirthdate } from "../Actions/birthdateAction";
import { UpdatTask } from "../pages/updateTask";
import {
  createNotification,
  getAllNotification,
  deleteNotification,
} from "../Actions/notificationAction";
import MyTable from "../components/table";
import { SearchOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import useResizeObserver from "../components/useResizeObserver";
import { debounce } from "lodash";
import moment from "moment";

const { Title } = Typography;

const Dashboard = () => {
  const dispatch = useDispatch();
  const { isGetPreTasksLoading, isGetPostTasksLoading, preTask, postTask } =
    useSelector((state) => state.tasks);
  const { users } = useSelector((state) => state.users);
  const { birthdate, isGetBirthdateLoading } = useSelector(
    (state) => state.birthdate
  );
  const {
    isGetNotificationsLoading,
    isCreateNotificationLoading,
    isDeleteNotificationLoading,
    notifications,
  } = useSelector((state) => state.notifications);
  const { client_birthday, renewal_property, tasks } = birthdate || {};
  const [createDialog, setCreateDialog] = useState(false);
  const { user, isAuthenticated, getIdTokenClaims } = useAuth0();
  const [token, setToken] = useState();
  const getToken = async () => {
    try {
      const result = await getIdTokenClaims();
      setToken(result.__raw);
    } catch (err) {
      alert(err.message);
    }
  };
  useEffect(() => {
    getToken();
  }, []);
  useEffect(() => {
    if (token) {
      dispatch(getAllPreTasks());
      dispatch(getAllPostTasks());
      dispatch(getAllNotification());
      dispatch(getBirthdate());
    }
  }, [token]);
  const [showTask, setShowTask] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [CurrentPreTask, setCurrentPreTask] = useState({});
  const [CurrentPostTask, setCurrentPostTask] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [size, setSize] = useState({ width: 0, height: 0 });

  const onRowSelect = (record) => {
    setCurrentPreTask(record);
    setCurrentPostTask(record);
    setShowSearch(true);
    setShowTask(true);
  };
  const dateCellRender = (v) => {
    const date = v.date();
    const month = v.month() + 1;
    const monthStr =
      month.toString().length === 1 ? "0" + month.toString() : month;
    const matchingBirthday = client_birthday?.filter(
      (b) => date.toString() === b.date && monthStr === b.month
    );

    const renewalProperty = renewal_property?.filter((b) => {
      if (!b.renewal_date) return false;
      const renewalMoment = moment(b.renewal_date, "DD/MM/YYYY");
      const renewalDay = renewalMoment.date().toString();
      const renewalMonth = renewalMoment.format("MM");

      return renewalDay === date.toString() && renewalMonth === monthStr;
    });

    const tasks_data =
      tasks?.filter((b) => {
        if (!b.due_date) return false;
        const dueDateMoment = moment(b.due_date, "DD/MM/YYYY");
        const dueDateDay = dueDateMoment.date().toString();
        const dueDateMonth = dueDateMoment.format("MM");

        return dueDateDay === date.toString() && dueDateMonth === monthStr;
      }) || [];

    const groupedTasks =
      tasks_data.length > 0
        ? tasks_data.reduce((acc, task) => {
            const { user_name, attachment_type, client_name } = task;

            if (!acc[user_name]) {
              acc[user_name] = [];
            }

            acc[user_name].push({ attachment_type, client_name });
            return acc;
          }, {})
        : {};

    let showGreen = false;
    let showRed = false;

    if (tasks_data && tasks_data.length > 0) {
      showGreen = tasks_data.some((task) => task.is_completed === true);
      showRed = tasks_data.some((task) => task.is_completed === false);
    }
    if (
      matchingBirthday?.length > 0 ||
      renewalProperty?.length > 0 ||
      tasks_data?.length > 0
    ) {
      const popoverContent = (
        <div>
          {matchingBirthday.map((mb, i) => (
            <div key={`birthday-${i}`}>
              <strong>{mb.name}'s Birthday</strong>
            </div>
          ))}
          {renewalProperty.map((rp, i) => (
            <div key={`renewal-${i}`}>
              <strong>{rp.client_name}</strong>'s property renewal on{" "}
              {rp.renewal_date}
            </div>
          ))}
          {Object.entries(groupedTasks).map(([userName, taskList], i) => (
            <div key={`user-${i}`}>
              <strong>{userName}'s Tasks:</strong>
              {taskList.map((task, j) => (
                <div key={`task-${i}-${j}`} style={{ marginLeft: "10px" }}>
                  {task.attachment_type} : {task.client_name}
                </div>
              ))}
            </div>
          ))}
        </div>
      );

      return (
        <div
          style={{
            position: "absolute",
            display: "flex",
            gap: "2px",
            top: 27,
            left: "50%",
            transform: "translate(-50%, -50%)",
            justifyContent: "center",
            // alignItems: "center",
          }}
        >
          {matchingBirthday.length > 0 && (
            <Popover content={popoverContent}>
              <Badge
                color="blue"
                style={{ transform: "scale(0.7)", cursor: "pointer" }}
              />
            </Popover>
          )}

          {renewalProperty.length > 0 && (
            <Popover content={popoverContent}>
              <Badge
                color="purple"
                style={{ transform: "scale(0.7)", cursor: "pointer" }}
              />
            </Popover>
          )}

          {showGreen && (
            <Popover content={popoverContent}>
              <Badge
                status="success"
                style={{ transform: "scale(0.7)", cursor: "pointer" }}
              />
            </Popover>
          )}

          {showRed && (
            <Popover content={popoverContent}>
              <Badge
                status="error"
                style={{ transform: "scale(0.7)", cursor: "pointer" }}
              />
            </Popover>
          )}
        </div>
      );
    }
    // birthdate.forEach(element => {
    //   if (element.date_of_birth) {
    //     const b = element.date_of_birth.slice(0, 2)
    //   //  console.log("Birth Val", b, date.toString())
    //     if (b === date.toString()){
    //       console.log("Birth Val", b)

    //       return (
    //         <div style={{width:'100%'}}>
    //         <li key={element.id}>
    //       <Badge  text={element.name} />
    //     </li>
    //     </div>
    //       )
    //     }
    //   }

    // });
  };
  let searchInput = useRef("");
  const getColumnSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText(selectedKeys[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button>
          </Space>
        </div>
      ),

      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "",

      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    };
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const Taskcolumns = (users, type) => [
    {
      title: "Employee Name",
      dataIndex: "user_name",
      key: "7",
      width: 140,
      ...getColumnSearchProps("user_name"),
      fixed: "left",
      render: (record, object) => {
        return <Typography className="empText">{record}</Typography>;
      },
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "7",
      width: 120,
      // fixed: 'left',
      render: (record, object) => {
        return <Typography style={{ fontSize: "10px" }}>{record}</Typography>;
      },
    },
    {
      title: "id",
      width: 70,
      dataIndex: "id",
      key: "1",
      ...getColumnSearchProps("id"),
    },
    {
      title: "Due Date",
      width: 100,
      dataIndex: "due_date",
      key: "due_date",
      // sorter: (a, b) => a.due_date - b.due_date,
      render: (due_date) => {
        if (!due_date) return "-";
        const formattedDate = moment(due_date, [
          "YYYY-MM-DD",
          "DD/MM/YYYY",
          "MM/DD/YYYY",
          "YYYY-MM-DDTHH:mm:ss.SSSZ",
          "x",
          "X",
        ]).format("DD/MM/YYYY");

        return (
          <div>{formattedDate !== "Invalid date" ? formattedDate : "-"}</div>
        );
      },
    },
    {
      title: "Communication Date",
      dataIndex: "communication_date",
      key: "communication_date",
      width: 120,
      // sorter: (a, b) => a.communication_date - b.communication_date,
      render: (communication_date) => {
        if (!communication_date) return "-";
        const formattedDate = moment(communication_date, [
          "YYYY-MM-DD",
          "DD/MM/YYYY",
          "MM/DD/YYYY",
          "YYYY-MM-DDTHH:mm:ss.SSSZ",
          "x",
          "X",
        ]).format("DD/MM/YYYY");

        return (
          <div>{formattedDate !== "Invalid date" ? formattedDate : "-"}</div>
        );
      },
    },
    {
      title: "Follow up Date",
      dataIndex: "follow_up_date",
      key: "follow_up_date",
      width: 100,
      // sorter: (a, b) => a.follow_up_date - b.follow_up_date,
      render: (follow_up_date) => {
        if (!follow_up_date) return "-";
        const formattedDate = moment(follow_up_date, [
          "YYYY-MM-DD",
          "DD/MM/YYYY",
          "MM/DD/YYYY",
          "YYYY-MM-DDTHH:mm:ss.SSSZ",
          "x",
          "X",
        ]).format("DD/MM/YYYY");

        return (
          <div>{formattedDate !== "Invalid date" ? formattedDate : "-"}</div>
        );
      },
    },
    {
      title: "Site visit Date",
      dataIndex: "site_visit_date",
      key: "site_visit_date",
      width: 100,
      // sorter: (a, b) => a.site_visit_date - b.site_visit_date,
      render: (site_visit_date) => {
        if (!site_visit_date) return "-";
        const formattedDate = moment(site_visit_date, [
          "YYYY-MM-DD",
          "DD/MM/YYYY",
          "MM/DD/YYYY",
          "YYYY-MM-DDTHH:mm:ss.SSSZ",
          "x",
          "X",
        ]).format("DD/MM/YYYY");

        return (
          <div>{formattedDate !== "Invalid date" ? formattedDate : "-"}</div>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "attachment_type",
      key: "attachment_type",
      width: 100,
      render: (attachment_type) => {
        return <div>{attachment_type}</div>;
      },
    },
    {
      title: "Note",
      width: 120,
      dataIndex: "note",
      key: "note",
      filters: [
        {
          text: "note",
          value: "note",
        },
      ],
      onFilter: (value, record) => record.note.indexOf(value) === 0,
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      fixed: "right",
      width: 100,
      render: (id) => <TaskActionComponent id={id} type={type} />,
    },
  ];
  const handlePanelChange = () => {
    dispatch(getBirthdate());
  };
  const handleResize = useCallback(
    debounce(() => {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;

      if (newWidth !== size.width || newHeight !== size.height) {
        setSize({ width: newWidth, height: newHeight });
      }
    }, 200),
    [size]
  );
  useResizeObserver(handleResize);

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Row justify="space-between">
            <Col>
              <Typography style={{ fontSize: "20px", fontWeight: 600 }}>
                Notifications({notifications?.length})
              </Typography>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => setCreateDialog(true)}
                style={{ marginLeft: 20, fontWeight: 500, color: "#FFFFF" }}
              >
                Create
              </Button>
            </Col>
          </Row>
          <Divider style={{ marginTop: "16px" }} />
          <Row>
            <Col span={24}>
              <Spin
                spinning={
                  isGetNotificationsLoading ||
                  isCreateNotificationLoading ||
                  isDeleteNotificationLoading
                }
              >
                <List
                  bordered
                  dataSource={notifications}
                  renderItem={(item) => (
                    <List.Item id={item.id}>
                      <Typography.Text>{item.text}</Typography.Text>
                      <Popconfirm
                        title="Sure to delete?"
                        onConfirm={() => dispatch(deleteNotification(item.id))}
                      >
                        <Button type="link">Delete</Button>
                      </Popconfirm>
                    </List.Item>
                  )}
                  style={{ maxHeight: "300px", overflowY: "auto" }}
                />
              </Spin>
            </Col>
          </Row>
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Spin spinning={isGetBirthdateLoading}>
            <Calendar
              fullscreen={false}
              dateCellRender={dateCellRender}
              onPanelChange={() => handlePanelChange()}
              headerRender={({ value, onChange }) => {
                const month = value.month();

                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      paddingBottom: "15px",
                      width: "100%",
                    }}
                  >
                    <div>
                      <Select
                        value={month}
                        onChange={(newMonth) => {
                          const newValue = value.clone().month(newMonth);
                          onChange(newValue);
                        }}
                        style={{ width: 120 }}
                      >
                        {moment.months().map((month, index) => (
                          <Select.Option key={index} value={index}>
                            {month}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                );
              }}
              style={{ position: "relative" }}
              className="calenderClass"
            />
          </Spin>
        </Col>
      </Row>

      <Row gutter={[20, 0]}>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <Title level={4}>
                Open Tasks({postTask.length})
                {/* for {currentProperty.client.name}'s requirements */}
              </Title>
            </Col>
          </Row>
          <Spin spinning={isGetPostTasksLoading}>
            <MyTable
              columns={Taskcolumns(users, "post")}
              data={postTask}
              onRowSelect={(postTask) => onRowSelect(postTask)}
            />
          </Spin>
        </Col>
        <Col span={24} style={{ paddingBottom: "30px" }}>
          <Row justify="space-between">
            <Col>
              <Title level={4}>
                Completed Tasks({preTask.length})
                {/* for {currentProperty.client.name} */}
              </Title>
            </Col>
          </Row>
          <Spin spinning={isGetPreTasksLoading}>
            <MyTable
              columns={Taskcolumns(users, "pre")}
              data={preTask}
              onRowSelect={(record) => onRowSelect(record)}
            />
          </Spin>
        </Col>
      </Row>
      {createDialog && (
        <CreateNotification
          createDialog={createDialog}
          setCreateDialog={setCreateDialog}

          // handleDialog={() => setCreateDialog(true)}
        />
      )}
    </>
  );
};

const TaskActionComponent = ({ id, type }) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const dispatch = useDispatch();
  const content = (
    <UpdatTask id={id} type={type} setPopoverVisible={setPopoverVisible} />
  );
  return (
    <Popover
      content={content}
      open={popoverVisible}
      trigger="click"
      onOpenChange={setPopoverVisible}
    >
      <Button type="link" onClick={() => setPopoverVisible(!popoverVisible)}>
        Action
      </Button>
    </Popover>
  );
};

export default Dashboard;

const schema = Yup.object().shape({
  text: Yup.string().required("Required"),
});
const { TextArea } = Input;
const { Text } = Typography;

const CreateNotification = (props) => {
  const dispatch = useDispatch();
  const { createDialog, setCreateDialog } = props;
  const { handleSubmit, errors, control, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const handleCreateNotification = (data, event) => {
    event.preventDefault();
    data.published = true;
    dispatch(createNotification(data));
    setCreateDialog(false);
    reset();
  };
  const handleCancel = () => {
    setCreateDialog(false);
    reset();
  };
  return (
    <>
      {/* <Spin
      spinning={
        isCreateNotificationLoading
      }
    ></Spin> */}

      <Modal
        title="Create Notification"
        visible={createDialog}
        onCancel={() => handleCancel()}
        footer={null}
        maskClosable={false}
        centered
      >
        {/* <Row>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}> */}
        <form onSubmit={handleSubmit(handleCreateNotification)}>
          <Col>
            <Col>
              <Text>Note</Text>
              <Controller
                as={TextArea}
                name="text"
                control={control}
                rows={4}
                showCount
                maxLength={100}
              />
              {errors.text && (
                <span className="error_style">Note is required</span>
              )}
              <Button
                type="primary"
                htmlType="submit"
                block
                style={{ top: 10 }}
              >
                Create Notification
              </Button>
            </Col>
          </Col>
        </form>
        {/* </Col>
        </Row> */}
      </Modal>
    </>
  );
};
