import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
// import "./style/custom-antd.css";
import './App.css';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { ConfigProvider } from 'antd';
import { ResizeProvider } from "./components/ResizeContext";

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const redirectUri = process.env.REACT_APP_REDIRECT_URI;

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://bb57634ad6554058b993ccacede7818f@o518363.ingest.sentry.io/5627150",
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    redirectUri={redirectUri.split(" ").join("")}
    useRefreshTokens={true}
    useRefreshTokensFallback={false}
    cacheLocation="localstorage"
  >
    <ConfigProvider>
    <ResizeProvider>
      <App />
      </ResizeProvider>
    </ConfigProvider>
  </Auth0Provider>,
  document.getElementById("root")
);
