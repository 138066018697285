// eslint-disable-next-line
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Card, Typography, Button, Modal } from "antd";
import "./styles/clients.css";
const { Text, Title } = Typography;

export const ViewLead = ({ id, setPopoverVisible }) => {
  const { leads } = useSelector((state) => state.leads);

  const the_lead = leads.filter((lead) => lead.id === id);
  const [open, setOpen] = useState(false);
  if (!leads.find((lead) => lead.id === id)) {
    return null; // Don't render if the lead is not found
  }
  // the_lead[0].area_id = the_lead[0]?.area?.id;
  // the_lead[0].client_id = the_lead[0]?.client?.id;
  const defaultValues = the_lead[0];
  const {
    client,
    max_budget,
    min_budget,
    area,
    areas_of_lead,
    is_lead,
    deal_for,
    home_loan,
    construction_after,
    vastu_complient,
    bhk,
    lead_type,
    types_of_lead,
    facing,
    details,
    is_residential,
    is_commercial,
    is_plot,
    lead_furniture_attributes,
    lead_amenity_attributes,
    lead_nearby_attributes,
    lead_area_attributes,
  } = defaultValues;
  const {
    fix_drawing_room,
    mod_drawing_room,
    fix_bedroom,
    mod_bedroom,
    fix_kitchen,
    mod_kitchen,
    air_condition,
  } = lead_furniture_attributes;
  const {
    water_24hr,
    png,
    security,
    cctv,
    lift,
    common_parking,
    paid_parking,
    garden,
    children_play_area,
    club_house,
    gymnasium,
    common_plot,
  } = lead_amenity_attributes;
  const {
    religious_place,
    park_garden,
    educational_institute,
    hospital_dispansary,
    mall_market,
    bus_stop,
  } = lead_nearby_attributes;
  const { minimum_sqFt, maximum_sqFt } = lead_area_attributes;
  const handleViewLead = () => {
    setPopoverVisible(false);
    setOpen(true);
  };
  const getLastElementofArray = (arr) => {
    const lengthOfArray = arr.length;
    const lastElement = arr[lengthOfArray - 1];
    return lastElement;
  };
  return (
    <>
      <Button
        type="link"
        style={{ cursor: "pointer" }}
        onClick={() => handleViewLead()}
      >
        ViewLead{" "}
      </Button>
      <Modal
        title="View Lead Details"
        visible={open}
        onCancel={() => setOpen(false)}
        footer={null}
        width={600}
      >
        <Row gutter={[0, 15]} style={{ marginTop: "20px" }}>
          <Col span={12}>
            <Typography className="viewProjectTitle">
              Client: <span className="viewProjectText">{client?.name}</span>
            </Typography>
          </Col>

          <Col xs={24} sm={12}>
            <Typography className="viewProjectTitle">
              Budget:{" "}
              <span className="viewProjectText">
                {`₹ ${min_budget}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} -{" "}
                {`${max_budget}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </span>
            </Typography>
          </Col>
          <Col span={24}>
            <Typography className="viewProjectTitle">
              Area:{" "}
              <span className="viewProjectText">
                {areas_of_lead?.map(
                  (area) =>
                    area?.area_name +
                    `${
                      getLastElementofArray(areas_of_lead)?.area_name ===
                      area?.area_name
                        ? ""
                        : ", "
                    }`
                )}
              </span>
            </Typography>
          </Col>

          <Col xs={24} sm={12}>
            <Typography className="viewProjectTitle">
              Constuction After:{" "}
              <span className="viewProjectText">{construction_after}</span>
            </Typography>
          </Col>
          <Col xs={24} sm={12}>
            <Typography className="viewProjectTitle">
              Vastu Complient:{" "}
              <span className="viewProjectText">
                {vastu_complient === 0 ? "Yes" : "No"}
              </span>
            </Typography>
          </Col>
          <Col span={24}>
            <Typography className="viewProjectTitle">
              Is Residential/Commercial/Plot?:{" "}
              <span className="viewProjectText">{is_lead}</span>
            </Typography>
          </Col>

          <Col span={24}>
            <Typography className="viewProjectTitle">
              Is New/Resell/Rent/Lease?:{" "}
              <span className="viewProjectText">{deal_for}</span>
            </Typography>
          </Col>

          <Col
            span={12}
            style={{ display: is_residential === true ? "block" : "none" }}
          >
            <Typography className="viewProjectTitle">
              Home Load? <span className="viewProjectText">{home_loan}</span>
            </Typography>
          </Col>

          <Col
            span={24}
            style={{ display: is_residential === true ? "block" : "none" }}
          >
            <Typography className="viewProjectTitle">
              BHK: <span className="viewProjectText">{bhk}</span>
            </Typography>
          </Col>
          <Col xs={24} sm={12}>
            <Typography className="viewProjectTitle">
              Lead Type:{" "}
              <span className="viewProjectText">
                {types_of_lead?.types?.map(
                  (type) =>
                    type +
                    `${
                      getLastElementofArray(types_of_lead?.types) === type
                        ? ""
                        : ", "
                    }`
                )}
              </span>
            </Typography>
          </Col>
          <Col xs={24} sm={12}>
            <Typography className="viewProjectTitle">
              Facing: <span className="viewProjectText">{facing}</span>
            </Typography>
          </Col>

          <Col span={24}>
            <Typography className="viewProjectTitle">
              Details:{" "}
              <span className="viewProjectText">
                {details
                  ? details.split("\n").map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))
                  : ""}
              </span>
            </Typography>
          </Col>

          <Col
            span={24}
            style={{
              display:
                is_residential === true || is_commercial === true
                  ? "block"
                  : "none",
            }}
          >
            <Typography className="viewProjectTitle">Furniture</Typography>
          </Col>
          <Col span={24}>
            <Row
              gutter={[0, 15]}
              style={{
                display:
                  is_residential === true || is_commercial === true
                    ? "block"
                    : "none",
              }}
            >
              <Col
                span={24}
                style={{ display: is_residential === true ? "block" : "none" }}
              >
                <Typography className="viewProjectTitle">
                  Fix Drawing Room?{" "}
                  <span className="viewProjectText">
                    {fix_drawing_room === true ? "Yes" : "No"}
                  </span>
                </Typography>
              </Col>
              <Col
                xl={8}
                lg={8}
                md={24}
                sm={24}
                xs={24}
                style={{ display: is_residential === true ? "block" : "none" }}
              >
                <Typography className="viewProjectTitle">
                  Modular Drawing Room?{" "}
                  <span className="viewProjectText">
                    {mod_drawing_room === true ? "Yes" : "No"}
                  </span>
                </Typography>
              </Col>
              <Col
                xl={8}
                lg={8}
                md={24}
                sm={24}
                xs={24}
                style={{ display: is_residential === true ? "block" : "none" }}
              >
                <Typography className="viewProjectTitle">
                  Fix Bedroom?{" "}
                  <span className="viewProjectText">
                    {fix_bedroom === true ? "Yes" : "No"}
                  </span>
                </Typography>
              </Col>
              <Col
                xl={8}
                lg={8}
                md={24}
                sm={24}
                xs={24}
                style={{ display: is_residential === true ? "block" : "none" }}
              >
                <Typography className="viewProjectTitle">
                  Modular Bedroom?{" "}
                  <span className="viewProjectText">
                    {mod_bedroom === true ? "Yes" : "No"}
                  </span>
                </Typography>
              </Col>
              <Col
                xl={8}
                lg={8}
                md={24}
                sm={24}
                xs={24}
                style={{ display: is_residential === true ? "block" : "none" }}
              >
                <Typography className="viewProjectTitle">
                  Fix Kitchen?{" "}
                  <span className="viewProjectText">
                    {fix_kitchen === true ? "Yes" : "No"}
                  </span>
                </Typography>
              </Col>
              <Col
                xl={8}
                lg={8}
                md={24}
                sm={24}
                xs={24}
                style={{ display: is_residential === true ? "block" : "none" }}
              >
                <Typography className="viewProjectTitle">
                  Modular Kitchen?{" "}
                  <span className="viewProjectText">
                    {mod_kitchen === true ? "Yes" : "No"}
                  </span>
                </Typography>
              </Col>
              <Col span={24}>
                <Typography className="viewProjectTitle">
                  Air Condition?{" "}
                  <span className="viewProjectText">
                    {air_condition === true ? "Yes" : "No"}
                  </span>
                </Typography>
              </Col>
            </Row>
          </Col>
          <Col
            span={24}
            style={{
              display:
                is_residential === true || is_commercial === true
                  ? "block"
                  : "none",
            }}
          >
            <Typography className="viewProjectTitle">Amenity</Typography>
          </Col>
        </Row>
        <Col span={24}>
          <Row
            gutter={[0, 15]}
            style={{
              display:
                is_residential === true || is_commercial === true
                  ? "block"
                  : "none",
            }}
          >
            <Col span={24}>
              <Typography className="viewProjectTitle">
                Water 24hr?{" "}
                <span className="viewProjectText">
                  {water_24hr === true ? "Yes" : "No"}
                </span>
              </Typography>
            </Col>
            <Col span={24}>
              <Typography className="viewProjectTitle">
                Png?{" "}
                <span className="viewProjectText">
                  {png === true ? "Yes" : "No"}
                </span>
              </Typography>
            </Col>
            <Col span={24}>
              <Typography className="viewProjectTitle">
                Security?{" "}
                <span className="viewProjectText">
                  {security === true ? "Yes" : "No"}
                </span>
              </Typography>
            </Col>
            <Col span={24}>
              <Typography className="viewProjectTitle">
                Cctv?{" "}
                <span className="viewProjectText">
                  {cctv === true ? "Yes" : "No"}
                </span>
              </Typography>
            </Col>
            <Col span={24}>
              <Typography className="viewProjectTitle">
                Lift?{" "}
                <span className="viewProjectText">
                  {lift === true ? "Yes" : "No"}
                </span>
              </Typography>
            </Col>
            <Col span={24}>
              <Typography className="viewProjectTitle">
                Common Parking?{" "}
                <span className="viewProjectText">
                  {common_parking === true ? "Yes" : "No"}
                </span>
              </Typography>
            </Col>
            <Col span={24}>
              <Typography className="viewProjectTitle">
                Paid Parking?{" "}
                <span className="viewProjectText">
                  {paid_parking === true ? "Yes" : "No"}
                </span>
              </Typography>
            </Col>
            <Col span={24}>
              <Typography className="viewProjectTitle">
                Garden?{" "}
                <span className="viewProjectText">
                  {garden === true ? "Yes" : "No"}
                </span>
              </Typography>
            </Col>
            <Col span={24}>
              <Typography className="viewProjectTitle">
                Children Play Area?{" "}
                <span className="viewProjectText">
                  {children_play_area === true ? "Yes" : "No"}
                </span>
              </Typography>
            </Col>
            <Col span={24}>
              <Typography className="viewProjectTitle">
                Club House?{" "}
                <span className="viewProjectText">
                  {club_house === true ? "Yes" : "No"}
                </span>
              </Typography>
            </Col>
            <Col span={24}>
              <Typography className="viewProjectTitle">
                Gymnasium?{" "}
                <span className="viewProjectText">
                  {gymnasium === true ? "Yes" : "No"}
                </span>
              </Typography>
            </Col>
            <Col span={24}>
              <Typography className="viewProjectTitle">
                Common Plot?{" "}
                <span className="viewProjectText">
                  {common_plot === true ? "Yes" : "No"}
                </span>
              </Typography>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Row
            style={{
              display:
                is_residential === true || is_commercial === true
                  ? "block"
                  : "none",
            }}
            gutter={[0, 15]}
          >
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Typography className="viewProjectTitle">Nearby</Typography>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row
            gutter={[0, 15]}
            style={{
              display:
                is_residential === true || is_commercial === true
                  ? "block"
                  : "none",
            }}
          >
            <Col span={24}>
              <Typography className="viewProjectTitle">
                Religious Place?{" "}
                <span className="viewProjectText">
                  {religious_place === true ? "Yes" : "No"}
                </span>
              </Typography>
            </Col>
            <Col span={24}>
              <Typography className="viewProjectTitle">
                Park/Garden?{" "}
                <span className="viewProjectText">
                  {park_garden === true ? "Yes" : "No"}
                </span>
              </Typography>
            </Col>
            <Col span={24}>
              <Typography className="viewProjectTitle">
                Educational/Institute?{" "}
                <span className="viewProjectText">
                  {educational_institute === true ? "Yes" : "No"}
                </span>
              </Typography>
            </Col>
            <Col span={24}>
              <Typography className="viewProjectTitle">
                Hospital/Dispansary?{" "}
                <span className="viewProjectText">
                  {hospital_dispansary === true ? "Yes" : "No"}
                </span>
              </Typography>
            </Col>
            <Col span={24}>
              <Typography className="viewProjectTitle">
                Mall/market?{" "}
                <span className="viewProjectText">
                  {mall_market === true ? "Yes" : "No"}
                </span>
              </Typography>
            </Col>
            <Col span={24}>
              <Typography className="viewProjectTitle">
                Bus Stop?{" "}
                <span className="viewProjectText">
                  {bus_stop === true ? "Yes" : "No"}
                </span>
              </Typography>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[0, 15]}>
            <Col span={24}>
              <Typography className="viewProjectTitle">Area SqFt.</Typography>
            </Col>

            <Col span={24} style={{ marginLeft: "10px" }}>
              <Typography className="viewProjectTitle">
                Minimum SqFt:{" "}
                <span className="viewProjectText">{minimum_sqFt}</span>
              </Typography>
            </Col>
            <Col span={24} style={{ marginLeft: "10px" }}>
              <Typography className="viewProjectTitle">
                Maximum SqFt:{" "}
                <span className="viewProjectText">{maximum_sqFt}</span>
              </Typography>
            </Col>
          </Row>
        </Col>
      </Modal>
    </>
  );
};
