import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Modal, Button, Row, Col, Typography } from "antd";
const { Text, Title } = Typography;
export const SuccessPage = (props) => {
  const { loginWithRedirect } = useAuth0();
  const { createDialog, setCreateDialog } = props;

  return (
    <>
      <Modal
        open={createDialog}
        onCancel={() => setCreateDialog(false)}
        closable={false}
        footer={null}
        centered
      >
        <Row justify="center">
          <Col>
            <CheckCircleOutlined style={{ color: "green" }} />
          </Col>
        </Row>
        <Row justify="center">
          <Col>
            <Title level={4} strong>
              Thank You!
            </Title>
          </Col>
        </Row>
        <Row justify="center">
          <Col>
            <Text strong>Your Property has been Successfully Created</Text>
          </Col>
        </Row>

        {/* <Row justify="center" style={{ paddingTop: 10 }}>
          <Col>
            <Button type="primary" onClick={() => loginWithRedirect()}>
              Login
            </Button>
          </Col>
        </Row> */}
      </Modal>
    </>
  );
};
