// eslint-disable-next-line
import React, { useState, useEffect, useRef, useCallback } from "react";
import Highlighter from "react-highlight-words";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Input,
  Typography,
  Popover,
  Button,
  Modal,
  Spin,
  Space,
  Checkbox,
  Select,
  InputNumber,
  Row,
  Popconfirm,
  Steps,
  Divider,
  message,
  Segmented,
  ConfigProvider,
} from "antd";
import { EnvironmentFilled, LoadingOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { getAllAreas } from "../Actions/areasAction";
import { getClients } from "../Actions/clientActions";
import { getAllTasks, deleteTask } from "../Actions/tasksAction";
import {
  getAllProperties,
  createPropery,
  publishedProperty,
  deletePropery,
} from "../Actions/propertiesActions";
import MyTable from "../components/table";
import "./styles/clients.css";
import { UpdateLandProperties } from "../pages/updateLandProperties";
import { CreateTask } from "../pages/tasks";
import { ViewProperty } from "../pages/viewProperty";
import moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";
import { debounce, sortBy } from "lodash";
import useResizeObserver from "../components/useResizeObserver";
const PropertyType = [
  { value: 11, label: "Godown" },
  { value: 12, label: "Wear House" },
  { value: 13, label: "Residential" },
  { value: 14, label: "Commercial" },
  { value: 15, label: "Agriculture" },
  { value: 16, label: "NonAgriculture" },
  { value: 17, label: "Redevelopment" },
];
// const ZoneType = [
//   { value: 0, label: "R1" },
//   { value: 1, label: "R2" },
//   { value: 2, label: "R3" },
// ];
const PropertyStatus = [
  { value: 0, label: "Available" },
  { value: 1, label: "Sold Out" },
  { value: 2, label: "On hold" },
];
const { Step } = Steps;
const steps = [
  {
    title: "Personal Details",
  },
  {
    title: "Property Details",
  },
];
const schema = Yup.object().shape({
  client_id: Yup.string().required("Required"),
  area_id: Yup.string().required("Required"),
  property_type: Yup.number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Required"),
  cost: Yup.string().required("Required"),
});
const { TextArea } = Input;
const { Text, Title } = Typography;

const LandProperties = () => {
  const dispatch = useDispatch();
  const {
    clients,
    areas,
    properties,
    isGetPropertiesLoading,
    isCreatePropertyLoading,
    isUpdatePropertyLoading,
    isDeletePropertyLoading,
  } = useSelector((state) => {
    const {
      properties,
      isGetPropertiesLoading,
      isCreatePropertyLoading,
      isUpdatePropertyLoading,
      isDeletePropertyLoading,
    } = state.properties;
    const { clients } = state.clients;
    const { areas } = state.areas;
    return {
      clients,
      areas,
      properties,
      isGetPropertiesLoading,
      isCreatePropertyLoading,
      isUpdatePropertyLoading,
      isDeletePropertyLoading,
    };
  });

  useEffect(() => {
    dispatch(getAllProperties("is_plot"));
    dispatch(getClients());
    dispatch(getAllAreas());
  }, []);

  const {
    handleSubmit,
    errors,
    clearErrors,
    control,
    reset,
    getValues,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      is_fix: false,
      is_negotiable: false,
      inbound_property: false,
      available: false,
      is_web_visible: false,
      is_furnished: false,
      published: false,
      is_commercial: false,
      is_residential: false,
      is_plot: false,
      is_new: false,
      is_resell: false,
      is_rent: false,
      is_lease: false,
      fix_kitchen: false,
      mod_kitchen: false,
      fix_drawing_room: false,
      mod_drawing_room: false,
      fix_bedroom: false,
      mod_bedroom: false,
      air_condition: false,
      water_24hr: false,
      png: false,
      security: false,
      cctv: false,
      lift: false,
      common_parking: false,
      paid_parking: false,
      garden: false,
      children_play_area: false,
      club_house: false,
      gymnasium: false,
      common_plot: false,
      religious_place: false,
      park_garden: false,
      educational_institute: false,
      hospital_dispansary: false,
      mall_market: false,
      bus_stop: false,
      created_at: moment(new Date()),
    },
    mode: "all",
  });
  const [createDialog, setCreateDialog] = useState(false);
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [vastuValue, setVastuValue] = useState("Yes");

  const [current, setCurrent] = React.useState(0);
  const next = () => {
    const {
      client_id: cid,
      area_id: aid,
      property_type: PT,
      cost: cst,
    } = getValues(["client_id", "area_id", "property_type", "cost"]);
    if (current === 0 && (!cid || !aid || !PT)) {
      trigger(["client_id", "area_id", "property_type"]);
    } else if (current === 1 && !cst) {
      trigger(["cost"]);
    } else {
      setCurrent(current + 1);
    }
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  // const handleCreatePropery = (data, event) => {
  //   data.is_plot = true;
  //   setTimeout(() => {
  //     message.success("Create Land property successful.");
  //   }, 1000);
  //   dispatch(createPropery(data, "is_plot"));
  //   setCreateDialog(false);
  //   setCurrent(0);
  //   reset();
  // };
  const handleCreatePropery = (data, event) => {
    data.is_plot = true;
    data.vastu_complient = vastuValue === "Yes" ? 0 : 1;
    dispatch(
      createPropery(
        data,
        "is_plot",
        successLandProperty,
        failureLAndProperty
        // IsEmpty
      )
    );
  };
  const successLandProperty = () => {
    setCreateDialog(false);
    setCurrent(0);
    reset();
    setTimeout(() => {
      message.success("You have successfully create land property.");
    }, 2000);
  };
  const failureLAndProperty = () => {
    setTimeout(() => {
      message.info("Something went wrong.");
    }, 2000);
    setCreateDialog(false);
    setCurrent(0);
    reset();
  };
  const handleCancel = () => {
    setCreateDialog(false);
    setCurrent(0);
    reset({ client_id: null, area_id: null, property_type: null, cost: null });
    clearErrors();
  };

  const handleResize = useCallback(
    debounce(() => {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;

      if (newWidth !== size.width || newHeight !== size.height) {
        setSize({ width: newWidth, height: newHeight });
      }
    }, 200),
    [size]
  );
  useResizeObserver(handleResize);

  return (
    <Spin
      spinning={
        isGetPropertiesLoading ||
        isCreatePropertyLoading ||
        isUpdatePropertyLoading ||
        isDeletePropertyLoading
      }
    >
      <Modal
        title="Create Land Property"
        visible={createDialog}
        onCancel={() => handleCancel()}
        footer={null}
        width={800}
        maskClosable={false}
      >
        <Row>
          <Steps
            current={current}
            size="small"
            responsive="true"
            labelPlacement="vertical"
          >
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </Row>
        <form onSubmit={handleSubmit(handleCreatePropery)}>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Client</Text>
              </Row>
              <Controller
                as={Select}
                showSearch
                control={control}
                options={
                  clients &&
                  clients.length > 0 &&
                  clients.map((val, index) => {
                    return { label: val.name, value: val.id };
                  })
                }
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: "100%" }}
                name="client_id"
              />
              {errors.client_id && (
                <span className="error_style">Client is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Property Type</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                options={PropertyType}
                name="property_type"
                style={{ width: "100%" }}
              />
              {errors.property_type && (
                <span className="error_style">Property Type is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Entry Date</Text>
              </Row>
              <Controller
                as={Input}
                name="created_at"
                control={control}
                placeholder="Entry Date"
                type="date"
                format="DD-MM-YYYY"
                // defaultValue={new Date()}
              />
              {errors.created_at && (
                <span className="error_style">Entry Date is required</span>
              )}
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Property No.</Text>
              </Row>
              <Controller
                as={Input}
                name="unique_number"
                control={control}
                placeholder="Property No."
              />
              {errors.unique_number && (
                <span className="error_style">Proprty No is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={16}
              lg={16}
              md={16}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Address</Text>
              </Row>
              <Controller
                as={Input}
                name="address"
                control={control}
                placeholder="Address"
              />
              {errors.unique_number && (
                <span className="error_style">Address is required</span>
              )}
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Landmark</Text>
              </Row>
              <Controller
                as={Input}
                name="landmark"
                control={control}
                placeholder="Landmark"
              />
              {errors.landmark && (
                <span className="error_style">Landmark is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Area</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                showSearch
                options={
                  areas &&
                  areas.length > 0 &&
                  areas.map((val, index) => {
                    return { label: val.name, value: val.id };
                  })
                }
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: "100%" }}
                name="area_id"
              />
              {errors.area_id && (
                <span className="error_style">Area is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Google Link</Text>
              </Row>
              <Controller
                as={Input}
                name="google_link"
                control={control}
                placeholder="City"
              />
              {errors.google_link && (
                <span className="error_style">City is required</span>
              )}
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            {/* <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Zone Type</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                options={ZoneType}
                name="zone_type"
                style={{ width: "100%" }}
              />
              {errors.zone_type && (
                <span className="error_style">Zone Type is required</span>
              )}
            </Col> */}
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Property Status</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                options={PropertyStatus}
                name="property_status"
                style={{ width: "100%" }}
              />
              {errors.property_status && (
                <span className="error_style">Property Status is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Last Communication Date</Text>
              </Row>
              <Controller
                as={Input}
                name="last_communication_date"
                control={control}
                placeholder="Last Communication Date"
                type="date"
                format="DD-MM-YYYY"
                // defaultValue={new Date()}
              />
              {errors.created_at && (
                <span className="error_style">
                  Last Communication Date is required
                </span>
              )}
            </Col>
          </Row>

          <Row>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Divider plain>Property Area</Divider>
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Super plot area Yard</Text>
              </Row>
              <Controller
                as={Input}
                name="property_area_attributes[super_plot_area_sqFt]"
                type="number"
                control={control}
                placeholder="Super plot area Yard"
              />
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Super buildup area Yard</Text>
              </Row>
              <Controller
                as={Input}
                name="property_area_attributes[super_builtup_area_sqFt]"
                type="number"
                control={control}
                placeholder="Super buildup area Yard"
              />
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Less %</Text>
              </Row>
              <Controller
                as={Input}
                name="property_area_attributes[less_sqFt]"
                type="number"
                control={control}
                placeholder="Less %"
              />
            </Col>
            {/* <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Builtup Area Yard</Text>
              </Row>
              <Controller
                as={Input}
                name="property_area_attributes[builtup_area_sqFt]"
                type="number"
                control={control}
                placeholder="Builtup Area Yard"
              />
            </Col> */}
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Carpet area Yard</Text>
              </Row>
              <Controller
                as={Input}
                name="property_area_attributes[carpet_area_sqFt]"
                type="number"
                control={control}
                placeholder="Carpet area Yard"
              />
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Land area sqft</Text>
              </Row>
              <Controller
                as={Input}
                name="property_area_attributes[land_area_sqFt]"
                type="number"
                control={control}
                placeholder="Enter land area sqft"
              />
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Construction Yard</Text>
              </Row>
              <Controller
                as={Input}
                name="property_area_attributes[construction_yard]"
                control={control}
                placeholder="Enter construction yard"
              />
            </Col>
          </Row>

          <Row>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Divider plain>Cost</Divider>
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Cost per Yard</Text>
              </Row>
              <Controller
                name="cost_one"
                control={control}
                render={(props) => (
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      value
                        ? `₹ ${new Intl.NumberFormat("en-IN").format(value)}`
                        : "₹ "
                    }
                    parser={(value) => value.replace(/₹\s?|(,*)/g, "")}
                    {...props}
                  />
                )}
              />
              {errors.cost_one && (
                <span className="error_style">Cost per Yard is required</span>
              )}
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Construction per yard</Text>
              </Row>
              <Controller
                name="cost_two"
                control={control}
                render={(props) => (
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      value
                        ? `₹ ${new Intl.NumberFormat("en-IN").format(value)}`
                        : "₹ "
                    }
                    parser={(value) => value.replace(/₹\s?|(,*)/g, "")}
                    {...props}
                  />
                )}
              />
              {errors.cost_two && (
                <span className="error_style">
                  Construction per yard is required
                </span>
              )}
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Extra cost </Text>
              </Row>
              <Controller
                name="cost_three"
                control={control}
                render={(props) => (
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      value
                        ? `₹ ${new Intl.NumberFormat("en-IN").format(value)}`
                        : "₹ "
                    }
                    parser={(value) => value.replace(/₹\s?|(,*)/g, "")}
                    {...props}
                  />
                )}
              />
              {errors.cost_three && (
                <span className="error_style">Extra cost is required</span>
              )}
            </Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Total Cost </Text>
              </Row>
              <Controller
                name="cost"
                control={control}
                render={(props) => (
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      value
                        ? `₹ ${new Intl.NumberFormat("en-IN").format(value)}`
                        : "₹ "
                    }
                    parser={(value) => value.replace(/₹\s?|(,*)/g, "")}
                    {...props}
                  />
                )}
              />
              {errors.cost && (
                <span className="error_style">Total Cost is required</span>
              )}
            </Col>
          </Row>

          <Divider />

          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Facing</Text>
              </Row>
              <Controller
                as={Input}
                name="facing"
                control={control}
                placeholder="Facing"
              />
              {errors.facing && (
                <span className="error_style">Facing is required</span>
              )}
            </Col>
            {/* <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Vastu Complient</Text>
              </Row>
              <Controller
                as={Input}
                name="vastu_complient"
                control={control}
                placeholder="Vastu Complient"
                type="number"
              />
              {errors.vastu_complient && (
                <span className="error_style">Vastu Complient is required</span>
              )}
            </Col> */}
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Typography>Vastu Compliance</Typography>
              <ConfigProvider
                theme={{
                  components: {
                    Segmented: {
                      itemActiveBg: "none",
                      itemColor: "none",
                      itemSelectedBg: "#c0cdf2",
                      itemSelectedColor: "#000",
                      itemHoverBg: "none",
                      itemHoverColor: "none",
                    },
                  },
                }}
              >
                <Segmented
                  options={["Yes", "No"]}
                  value={vastuValue}
                  onChange={setVastuValue}
                  block
                  className="segmentedForm"
                />
              </ConfigProvider>

              {errors.vastu_complient && (
                <span className="error_style">Vastu Complient is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Construction Year</Text>
              </Row>
              <Controller
                as={Input}
                name="construction_year"
                control={control}
                placeholder="Construction Year"
                type="date"
              />
              {errors.construction_year && (
                <span className="error_style">
                  Construction Year is required
                </span>
              )}
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Visit Time</Text>
              </Row>
              <Controller
                as={Input}
                name="visit_time"
                control={control}
                placeholder="Visit Time"
                type="time"
              />
              {errors.visit_time && (
                <span className="error_style">Visit Time is required</span>
              )}
            </Col>
          </Row>

          <Row gutter={[0, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>About Key</Text>
              </Row>
              <Controller
                as={Input}
                name="about_key"
                control={control}
                placeholder="About Key"
              />
              {errors.about_key && (
                <span className="error_style">About Key is required</span>
              )}
            </Col>
          </Row>

          <Row gutter={[0, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>New</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_new"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Resell</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_resell"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Rent</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_rent"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Lease</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_lease"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="notes_box"
            >
              <Row>
                <Text>Details</Text>
              </Row>
              <Controller
                as={TextArea}
                name="details"
                control={control}
                rows={4}
                showCount
                maxLength={100}
              />
              {errors.details && (
                <span className="error_style">Details is required</span>
              )}
            </Col>
          </Row>

          <Row>
            <Col
              style={{
                display: current === 2 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Fix Drawing Room</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_furniture_attributes[fix_drawing_room]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display: current === 2 ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Water 24hr</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[water_24hr]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display: current === 2 ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Religious Place</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_nearby_attributes[religious_place]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row justify="end" gutter={[8, 16]}>
            <Col span={24}></Col>
            {!isCreatePropertyLoading && current > 0 && (
              <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                Previous
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                Next
              </Button>
            )}

            {current === steps.length - 1 && (
              <>
                {isCreatePropertyLoading ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 24,
                        }}
                        spin
                      />
                    }
                  />
                ) : (
                  <Button type="primary" htmlType="submit">
                    Create
                  </Button>
                )}
              </>
            )}
          </Row>
        </form>
      </Modal>
      {properties && (
        <AllLandProperties
          data={properties}
          handleDialog={() => setCreateDialog(true)}
        />
      )}
    </Spin>
  );
};
export default LandProperties;

const AllLandProperties = (props) => {
  const dispatch = useDispatch();
  const { areas } = useSelector((state) => state.areas);
  const { clients } = useSelector((state) => state.clients);
  const { tasks, isGetTasksLoading } = useSelector((state) => state.tasks);
  const { users } = useSelector((state) => state.users);
  const { handleDialog } = props;
  const [showTask, setShowTask] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [currentProperty, setCurrentProperty] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const onRowSelect = (record) => {
    setCurrentProperty(record);
    setShowSearch(true);
    setShowTask(true);
  };
  const onTaskRowClick = (task) => {};
  // useEffect(() => {
  //   if (createPropery !== {}) {
  //   }
  // }, [currentProperty]);

  useEffect(() => {
    if (currentProperty && Object.keys(currentProperty).length > 0) {
      dispatch(getAllTasks(currentProperty.id));
    }
  }, [currentProperty, dispatch]);

  let searchInput = useRef("");
  const getColumnSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText(selectedKeys[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button>
          </Space>
        </div>
      ),

      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "",

      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    };
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const sortedArea = sortBy(areas, ["name"]);

  const headers = [
    { label: "Area", key: "area.name" },
    { label: "Property", key: "property_type" },
    // { label: "Zone", key: "zone_type" },
    { label: "Address", key: "address" },
    { label: "Property No.", key: "unique_number" },

    {
      label: "Super builtup",
      key: "property_area_attributes.super_builtup_area_sqFt",
    },
    { label: "Less %", key: "property_area_attributes.less_sqFt" },
    { label: "Carpet plot", key: "property_area_attributes.carpet_area_sqFt" },
    { label: "Construction yard", key: "property_area_attributes.construction_yard" },
    { label: "Construction per yard", key: "cost_two" },
    { label: "Cost per yard", key: "cost_one" },
    { label: "Total Cost", key: "cost" },
    { label: "Map", key: "google_link" },
    { label: "Client Name", key: "client_name" },
    { label: "Entry Date", key: "created_at" },
    { label: "Chat", key: "last_communication_date" },
    { label: "Property Status", key: "property_status" },

    // { label: "Super Plot area", key: "property_area_attributes.super_plot_area_sqFt",},
    // { label: "Address", key: "address",},
  ];

  const columns = (areas, clients) => [
    // {
    //   title: "Client Name",
    //   dataIndex: "client_name",
    //   key: "1",
    //   width: '120%',
    //   fixed: "left",
    //   ...getColumnSearchProps("client_name"),
    //   render: (record, object) => {
    //     return <Button type="link">{record} </Button>;
    //   },
    // },
    {
      title: "Area",
      dataIndex: "area",
      key: "1",
      width: 100,
      fixed: "left",
      render: (record, object) => {
        return <div>{record.name}</div>;
      },
      filters:
        sortedArea &&
        sortedArea.length > 0 &&
        sortedArea.map((area, index) => {
          return { text: area.name, value: area.name };
        }),
      onFilter: (value, record) => {
        return record.area.name.indexOf(value) === 0;
      },
    },
    // {
    //   title: "Address",
    //   dataIndex: "address",
    //   key: "6",
    //   width: '30%',
    //   filters: [
    //     {
    //       text: "address",
    //       value: "address",
    //     },
    //   ],
    //   onFilter: (value, record) => record.address.indexOf(value) === 0,
    // },
    {
      title: "Property",
      dataIndex: "property_type",
      key: "2",
      width: 100,
      // ...getColumnSearchProps("property_type"),

      filters: [
        {
          text: "Godown",
          value: "Godown",
        },
        {
          text: "Wear House",
          value: "Wear House",
        },
        {
          text: "Residential",
          value: "Residential",
        },
        {
          text: "Commercial",
          value: "Commercial",
        },
        {
          text: "Agriculture",
          value: "Agriculture",
        },
        {
          text: "NonAgriculture",
          value: "NonAgriculture",
        },
        {
          text: "Redevelopment",
          value: "Redevelopment",
        },
      ],
      onFilter: (value, object, record) => {
        return object.property_type.indexOf(value) === 0;
      },
    },
    // {
    //   title: "Zone",
    //   dataIndex: "zone_type",
    //   key: "3",
    //   width: 80,
    //   ...getColumnSearchProps("zone_type"),
    // },
    // {
    //   title: "DealFor",
    //   dataIndex: "deal_for",
    //   key: "1",
    //   width: '15%',
    // },
    // {
    //     title: "Super Plot area",
    //     dataIndex: "property_area_attributes",
    //     key: "5",
    //     width: '120%',
    //     render: (record, object) => {
    //       return <>{record ? record.super_plot_area_sqFt : ""}</>;
    //     },
    //     sorter: (a, b) => a.super_plot_area_sqFt - b.super_plot_area_sqFt,
    //   },
    {
      title: "Address",
      dataIndex: "address",
      key: "3",
      width: 100,
      sorter: (a, b) => {
        return a?.address?.localeCompare(b?.address);
      },
    },
    {
      title: "Property No.",
      dataIndex: "unique_number",
      key: "4",
      width: 100,
    },
    {
      title: "Super builtup",
      dataIndex: "property_area_attributes",
      key: "4",
      width: 100,
      render: (record, object) => {
        return <>{record ? record.super_builtup_area_sqFt : ""}</>;
      },
      sorter: (a, b) => a.super_builtup_area_sqFt - b.super_builtup_area_sqFt,
    },
    {
      title: "Less %",
      dataIndex: "property_area_attributes",
      key: "5",
      width: 80,
      render: (record, object) => {
        return <>{record ? record.less_sqFt : ""}</>;
      },
      sorter: (a, b) => a.less_sqFt - b.less_sqFt,
    },
    // {
    //   title: "Less SqFt",
    //   dataIndex: "property_area_attributes",
    //   key: "1",
    //   width: '20%',
    //   render: (record, object) => {
    //     return <>{record ? record.less_sqFt : ""}</>;
    //   },
    //   sorter: (a, b) => a.less_sqFt - b.less_sqFt,
    // },
    // {
    //   title: "Builtup Area SqFt",
    //   dataIndex: "property_area_attributes",
    //   key: "1",
    //   width: '20%',
    //   render: (record, object) => {
    //     return <>{record ? record.builtup_area_sqFt : ""}</>;
    //   },
    //   sorter: (a, b) => a.builtup_area_sqFt - b.builtup_area_sqFt,
    // },
    {
      title: "Carpet plot",
      dataIndex: "property_area_attributes",
      key: "6",
      width: 100,
      render: (record, object) => {
        return <>{record ? record.carpet_area_sqFt : ""}</>;
      },
      sorter: (a, b) => a.carpet_area_sqFt - b.carpet_area_sqFt,
    },
    // {
    //   title: "Land Area SqFt",
    //   dataIndex: "property_area_attributes",
    //   key: "1",
    //   width: '20%',
    //   render: (record, object) => {
    //     return <>{record ? record.land_area_sqFt : ""}</>;
    //   },
    //   sorter: (a, b) => a.land_area_sqFt - b.land_area_sqFt,
    // },
    {
      title: "Construction yard",
      dataIndex: "property_area_attributes",
      key: "7",
      width: 100,
      render: (record, object) => {
        return <>{record ? record.construction_yard : ""}</>;
      },
      sorter: (a, b) => a.construction_yard - b.construction_yard,
    },
    {
      title: "Construction per yard",
      dataIndex: "cost_two",
      key: "8",
      width: 120,
      render: (cost_two) => {
        if (cost_two == null) return null;
        const formattedCost = new Intl.NumberFormat("en-IN", {
          // style: "currency",
          // currency: "INR",
          maximumFractionDigits: 0,
        }).format(cost_two);
        return <div>{formattedCost}</div>;
      },
    },
    {
      title: "Cost per yard",
      dataIndex: "cost_one",
      key: "8",
      width: 100,
      render: (cost_one) => {
        if (cost_one == null) return null;
        const formattedCost = new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 0,
        }).format(cost_one);

        return <div>{formattedCost.replace("₹", "₹ ")}</div>;
      },
      sorter: (a, b) => a.cost_one - b.cost_one,
    },
    {
      title: "Total Cost",
      dataIndex: "cost",
      key: "9",
      width: 100,
      render: (cost) => {
        if (cost == null) return null;
        const formattedCost = new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 0,
        }).format(cost);

        return <div>{formattedCost.replace("₹", "₹ ")}</div>;
      },
      sorter: (a, b) => a.cost - b.cost,
    },
    {
      title: "Map",
      dataIndex: "google_link",
      key: "10",
      width: 60,
      render: (id) => (
        <Button type="link" href={id} target="_blank">
          <EnvironmentFilled></EnvironmentFilled>
        </Button>
      ),
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "11",
      width: 100,
      // fixed: "left",
      ...getColumnSearchProps("client_name"),
      render: (record, object) => {
        return <Typography className="propertyText">{record}</Typography>;
      },
    },
    {
      title: "Entry Date",
      dataIndex: "created_at",
      key: "12",
      width: 100,
      sorter: (a, b) => a.created_at - b.created_at,
      render: (created_at) => {
        if (!created_at) return "-";
        const formattedDate = moment(created_at, [
          "YYYY-MM-DD",
          "DD/MM/YYYY",
          "MM/DD/YYYY",
          "YYYY-MM-DDTHH:mm:ss.SSSZ",
          "x",
          "X",
        ]).format("DD/MM/YYYY");

        return (
          <div>{formattedDate !== "Invalid date" ? formattedDate : "-"}</div>
        );
      },
    },
    {
      title: "Chat",
      dataIndex: "last_communication_date",
      key: "13",
      width: 100,
      render: (last_communication_date) => {
        if (!last_communication_date) return "-";
        const formattedDate = moment(last_communication_date, [
          "YYYY-MM-DD",
          "DD/MM/YYYY",
          "MM/DD/YYYY",
          "YYYY-MM-DDTHH:mm:ss.SSSZ",
          "x",
          "X",
        ]).format("DD/MM/YYYY");

        return (
          <div>{formattedDate !== "Invalid date" ? formattedDate : "-"}</div>
        );
      },
      // sorter: (a, b) => a.last_communication_date - b.last_communication_date,
    },
    // {
    //   title: "Vastu",
    //   dataIndex: "vastu_complient",
    //   key: "5",
    //   width: '10%',
    //   sorter: (a, b) => a.vastu_complient - b.vastu_complient,
    // },
    // {
    //   title: "Facing",
    //   dataIndex: "facing",
    //   key: "1",
    //   width: '10%',
    //   filters: [
    //     {
    //       text: "facing",
    //       value: "facing",
    //     },
    //   ],
    //   onFilter: (value, record) => record.facing.indexOf(value) === 0,
    // },
    // {
    //   title: "Address",
    //   dataIndex: "address",
    //   key: "14",
    //   width: '100%',
    // },
    {
      title: "Property Status",
      dataIndex: "property_status",
      key: "15",
      width: 100,
      render: (record) => {
        return <>{record ? record : ""}</>;
      },
    },
    {
      title: "id",
      width: 100,
      dataIndex: "id",
      key: "16",
      ...getColumnSearchProps("id"),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      fixed: "right",
      width: 100,
      render: (id) => (
        <ActionComponent
          id={id}
          published={currentProperty.published}
          type="is_plot"
        />
      ),
    },
  ];

  const Taskcolumns = (users) => [
    {
      title: "User Name",
      dataIndex: "user_name",
      key: "7",
      width: 150,
      ...getColumnSearchProps("user_name"),
      render: (record, object) => {
        return <Button type="link">{record}</Button>;
      },
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      key: "due_date",
      width: 100,
      sorter: (a, b) => a.due_date - b.due_date,
    },
    {
      title: "Communcation Date",
      dataIndex: "communication_date",
      key: "communication_date",
      width: 130,
      sorter: (a, b) => a.communication_date - b.communication_date,
      render: (communication_date) => {
        return <div>{communication_date || "-"}</div>;
      },
    },
    {
      title: "Follow up Date",
      dataIndex: "follow_up_date",
      key: "follow_up_date",
      width: 130,
      sorter: (a, b) => a.follow_up_date - b.follow_up_date,
      render: (follow_up_date) => {
        return <div>{follow_up_date || "-"}</div>;
      },
    },
    {
      title: "Site visit Date",
      dataIndex: "site_visit_date",
      key: "site_visit_date",
      width: 130,
      sorter: (a, b) => a.site_visit_date - b.site_visit_date,
      render: (site_visit_date) => {
        return <div>{site_visit_date || "-"}</div>;
      },
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      width: 100,
      filters: [
        {
          text: "note",
          value: "note",
        },
      ],
      onFilter: (value, record) => record.note.indexOf(value) === 0,
    },
    {
      title: "id",
      width: 100,
      dataIndex: "id",
      key: "21",
      ...getColumnSearchProps("id"),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      fixed: "right",
      width: 100,
      render: (id) => (
        <TaskActionComponent id={id} currentProperty={currentProperty} />
      ),
    },
  ];

  return (
    <>
      <Row className="rowWithPadding" justify="space-between">
        <Col>
          <Title level={4}>All Land Properties({props.data.length})</Title>
        </Col>
        <Col>
          <Row gutter={[16, 0]} align="middle">
            <Col>
              <CSVLink
                data={props.data}
                headers={headers}
                filename={`land_${new Date().toISOString()}.csv`}
              >
                Download
              </CSVLink>
            </Col>
            <Col>
              <Button type="primary" onClick={() => handleDialog()}>
                Create Land Property
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <MyTable
        columns={columns(areas, clients)}
        data={props.data}
        onRowSelect={(record) => onRowSelect(record)}
        rowClassName={(record) =>
          record.property_status === "Onhold" ? "onHoldRow" : ""
        }
      />
      {showSearch && showTask && (
        <>
          <Row>
            <Col span={24}>
              <Row className="rowWithPadding" justify="space-between">
                <Col>
                  <Title level={4}>Task({tasks.length})</Title>
                </Col>
              </Row>
              <Spin spinning={isGetTasksLoading}>
                <MyTable
                  columns={Taskcolumns(users)}
                  data={tasks}
                  onRowSelect={(record) => onTaskRowClick(record)}
                />
              </Spin>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
const ActionComponent = ({ id, published, type }) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const dispatch = useDispatch();
  const [createDialog, setCreateDialog] = useState(false);
  const [current, setCurrent] = React.useState(0);
  // const handleDeleteLand = (id,type) => {
  //   setTimeout(() => {
  //     message.success("Delete Land Property successful.");
  //   }, 1000);
  //   dispatch(deletePropery(id,type));
  // };
  const handleDeleteLand = (property_id, type) => {
    property_id = id;
    dispatch(
      deletePropery(
        property_id,
        "is_plot",
        successDeleteLandProperty,
        failureDeleteLandProperty
      )
    );
  };
  const successDeleteLandProperty = () => {
    setTimeout(() => {
      message.success("You have successfully delete land property.");
    }, 2000);
  };
  const failureDeleteLandProperty = () => {
    setTimeout(() => {
      message.info("Something went wrong.");
    }, 2000);
  };
  const handleUpdatePublished = () => {
    setPopoverVisible(false);
    dispatch(publishedProperty(id, true, type));
  };
  const handleUpdateUnPublished = () => {
    setPopoverVisible(false);
    dispatch(publishedProperty(id, false, type));
  };
  const content = (
    <div>
      <CreateTask
        attachment_id={id}
        attachment_type="Property"
        setPopoverVisible={setPopoverVisible}
      />
      &nbsp;&nbsp;
      <ViewProperty id={id} setPopoverVisible={setPopoverVisible} />
      &nbsp;&nbsp;
      <UpdateLandProperties id={id} setPopoverVisible={setPopoverVisible} />
      &nbsp;&nbsp;
      {published ? (
        <Button type="link" onClick={() => handleUpdateUnPublished()}>
          Un Published
        </Button>
      ) : (
        <Button type="link" onClick={() => handleUpdatePublished()}>
          Published
        </Button>
      )}
      &nbsp;&nbsp;
      <Popconfirm
        title="Sure to delete?"
        // onConfirm={() => dispatch(deletePropery(id, type))}
        onConfirm={() => handleDeleteLand(id, type)}
      >
        <Button type="link" onClick={() => setPopoverVisible(false)}>
          Delete
        </Button>
      </Popconfirm>
    </div>
  );
  return (
    <Popover
      content={content}
      open={popoverVisible}
      trigger="click"
      onOpenChange={setPopoverVisible}
    >
      <Button type="link" onClick={() => setPopoverVisible(!popoverVisible)}>
        Action
      </Button>
    </Popover>
  );
};

const TaskActionComponent = ({ id, currentProperty }) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const dispatch = useDispatch();
  const content = (
    <div>
      <Popconfirm
        title="Sure to delete?"
        onConfirm={() =>
          dispatch(deleteTask(id, { attachment_id: currentProperty.id }))
        }
      >
        <Button type="link" onClick={() => setPopoverVisible(false)}>
          Delete
        </Button>
      </Popconfirm>
    </div>
  );
  return (
    <Popover
      content={content}
      open={popoverVisible}
      trigger="click"
      onOpenChange={setPopoverVisible}
    >
      <Button type="link" onClick={() => setPopoverVisible(!popoverVisible)}>
        Action
      </Button>
    </Popover>
  );
};
