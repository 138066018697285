import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Row, Col, Layout, Button, Image, Drawer } from "antd";
import "../components/header.css";
import logo from "../assets/images/logo.svg";
import { Typography, Menu } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { useBreakpoints } from "./ResponsiveBreakPoints";
import { Link, useHistory, useLocation } from "react-router-dom";

const HomeHeader = () => {
  const history = useHistory();
  const { isMobile, isTablet, isDesktop } = useBreakpoints();
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const { isSuperLarge } = useBreakpoints();

  const [open, setOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const location = useLocation();

  // const handleMenuClick = (onClick) => {
  //   if (onClick) onClick();
  //   setOpen(false);
  // };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Set initial value
    setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  const scrollToSection = (section) => {
    setOpen(false);
    if (section === "post") {
      history.push("/post"); 
      return;
    }
    if (location.pathname === "/") {
      const sectionElement = document.getElementById(section);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      history.push("/");
      setTimeout(() => {
        const sectionId = section?.replace("/", "");
        document
          .getElementById(sectionId)
          ?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  };

  const menuItems = [
    { key: "1", label: "About Us", section: "aboutus" },
    {
      key: "2",
      label: "Property Service",
      section: "service",
    },
    {
      key: "3",
      label: (
        <div className="sideBySideButtons">
          <Button
            className="postProperty"
            onClick={(e) => {
              e.stopPropagation(); 
              history.push("/post");
            }}
          >
            Post Property
          </Button>

          {location.pathname === "/admin" && (
            <Button
              className="signIn"
              onClick={(e) => {
                e.stopPropagation();
                loginWithRedirect();
              }}
            >
              Sign in
            </Button>
          )}
        </div>
      ),
    },
  ];

  return (
    <Row justify="center" style={{ padding: "15px 0px" }}>
      <Col
        xs={22}
        sm={22}
        md={22}
        lg={18}
        xl={16}
        xxl={14}
        style={{ maxWidth: isSuperLarge ? "40%" : "" }}
      >
        <Row align='middle' style={{ position:'relative'}} justify='space-between'>
          <Col>
            <Row gutter={[8,0]}>
              <Col style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={logo}
                  alt="Logo"
                  style={{ height: "100px", cursor: "pointer" }}
                  onClick={() => history.push("/")}
                />
              </Col>
              {!isMobile &&
              <Col>
                <Typography className="logoHeading">Shree Vastu Developers and Management</Typography>
                <Typography className="logoText">RERA: AG/GJ/AHMEDABAD/AHMEDABAD CITY/AUDA/AA01298/271025R1</Typography>
              </Col>}
            </Row>
          </Col>

          {(isDesktop || isTablet) && (
            <Col style={{position:'absolute', right:0}}>
              <Row gutter={20} align="middle" style={{height:'95px', display:'flex', alignContent:'end'}}>
                {menuItems.map(({ key, label, section }) => (
                  <Col key={key} onClick={() => scrollToSection(section)}>
                    {typeof label === "string" ? (
                      <Typography className="navbarText">{label}</Typography>
                    ) : (
                      label
                    )}
                  </Col>
                ))}
              </Row>
            </Col>
          )}

          {isMobile && (
            <Col style={{ textAlign: "right" }}>
              <Button icon={<MenuOutlined />} onClick={() => setOpen(true)} />
            </Col>
          )}
        </Row>

        <Drawer
          title="Menu"
          placement="right"
          closable
          onClose={() => setOpen(false)}
          open={open}
        >
          <Menu mode="vertical" className="menuBar">
            {menuItems.map(({ key, label, section }) => (
              <Menu.Item
                key={key}
                onClick={() => scrollToSection(section)}
                style={{ width: "100%", display: "flex", alignItems: "center" }}
              >
                {typeof label === "string" ? (
                  <Typography className="navbarText">{label}</Typography>
                ) : (
                  label
                )}
              </Menu.Item>
            ))}
          </Menu>
        </Drawer>
      </Col>
    </Row>
  );
};
export default HomeHeader;
