import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Input,
  Typography,
  Button,
  Modal,
  Select,
  Row,
  message,
  Spin,
} from "antd";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { getClients, updateClient } from "../Actions/clientActions";
import "./styles/clients.css";
import { LoadingOutlined } from "@ant-design/icons";
const Gender = [
  { value: 0, label: "Male" },
  { value: 1, label: "Female" },
  { value: 2, label: "Other" },
];
const ClientType = [
  { value: 0, label: "Buyer" },
  { value: 1, label: "Seller" },
  { value: 2, label: " Rent Owner" },
  { value: 3, label: "Rent Client" },
];
const PropertyType = [
  { value: 12, label: "Residential" },
  { value: 13, label: "Commercial" },
  { value: 17, label: "Land" },
];
const phoneRegExp = /^[0][1-9]\d{9}$|^[1-9]\d{9}$/;
const schema = Yup.object().shape({
  name: Yup.string().when("$role", {
    is: "Employee",
    then: (schema) => schema.notRequired(),
    otherwise: (schema) => schema.required("Required"),
  }),
  mobile_number: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .when("$role", {
      is: "Employee",
      then: (schema) => schema.notRequired(),
      otherwise: (schema) => schema.required("Required"),
    }),
});
const { TextArea } = Input;
const { Text } = Typography;
export const UpdateClient = ({
  id,
  setPopoverVisible,
  itemKey,
  currentUser,
}) => {
  const dispatch = useDispatch();
  const { clients, isUpdateClientLoading } = useSelector(
    (state) => state.clients
  );
  const the_client = clients.filter((client) => client.id === id);
  const newDate = the_client[0].date_of_birth
    ? the_client[0].date_of_birth.split("/")
    : {};
  const newDate1 = the_client[0].date_of_anniversary
    ? the_client[0].date_of_anniversary.split("/")
    : {};
  const newDate2 = the_client[0].created_at
    ? the_client[0].created_at.split("/")
    : {};
  the_client[0].date_of_birth =
    newDate.length > 2
      ? `${newDate[2]}-${newDate[1]}-${newDate[0]}`
      : newDate[0];
  the_client[0].date_of_anniversary =
    newDate1.length > 2
      ? `${newDate1[2]}-${newDate1[1]}-${newDate1[0]}`
      : newDate1[0];
  the_client[0].created_at =
    newDate2.length > 2
      ? `${newDate2[2]}-${newDate2[1]}-${newDate2[0]}`
      : newDate2[0];
  const [open, setOpen] = useState(false);
  const { handleSubmit, errors, control, reset, formState } = useForm({
    resolver: yupResolver(schema),
    defaultValues: the_client[0],
    context: { role: currentUser?.role },
    mode: "onChange",
  });
  const { isDirty } = formState;

  useEffect(() => {
    if (the_client?.length > 0) {
      reset(the_client[0], { keepDirty: false, keepValues: true });
    }
  }, [JSON.stringify(the_client), reset]);

  const handleUpdateClient = (data, event) => {
    data.id = id;
    if (itemKey === "23") {
      data.is_developer = true;
      data.client_type = 4;
    }
    // event.preventDefault();
    dispatch(updateClient(data, successUpdateClient, failureUpdateClient));
  };
  const successUpdateClient = () => {
    setOpen(false);
    reset();
    if (itemKey === "23") {
      dispatch(getClients("is_developer=true"));
    } else {
      dispatch(getClients());
    }
    setTimeout(() => {
      message.success(
        `You have successfully update ${
          itemKey === "23" ? "developer" : "client"
        }.`
      );
    }, 2000);
  };
  const failureUpdateClient = (res) => {
    setTimeout(() => {
      if (res?.mobile_number?.length > 0) {
        message.error(`Mobile number ${res.mobile_number[0]}`);
      } else {
        message.info("Something went wrong.");
      }
    }, 500);
    setOpen(false);
    reset();
  };
  const handleOpen = (data) => {
    setPopoverVisible(false);
    setOpen(true);
    reset(data);
    // setTimeout(() => {
    //   message.success("Delete client successful.");
    // }, 1000);
  };
  return (
    <>
      <Button
        type="link"
        style={{ cursor: "pointer" }}
        onClick={() => handleOpen()}
      >
        Edit{" "}
      </Button>
      <Modal
        title={`Edit ${itemKey === "23" ? "Developer" : "Client"} details`}
        visible={open}
        onCancel={() => setOpen(false)}
        footer={null}
        width={700}
        maskClosable={false}
      >
        <form onSubmit={handleSubmit(handleUpdateClient)}>
          {currentUser?.role === "Employee" && !(itemKey === "23")? (
            <Row gutter={[16, 0]}>
              <Col xl={8} lg={8} md={8} sm={8} xs={24}>
                <Row>
                  <Text>Reference</Text>
                </Row>
                <Controller
                  as={Input}
                  name="reference"
                  control={control}
                  placeholder="Reference"
                />
                {errors.reference && (
                  <span className="error_style">Reference is required</span>
                )}
              </Col>
              
            </Row>
          ) : (
            <>
              <Row gutter={[16, 0]}>
                <Col xl={8} lg={8} md={8} sm={8} xs={24}>
                  <Row>
                    <Text>Name</Text>
                  </Row>
                  <Controller
                    as={Input}
                    name="name"
                    control={control}
                    placeholder={
                      itemKey === "23" ? "Developer name" : "Client name"
                    }
                  />
                  {errors.name && (
                    <span className="error_style">
                      {itemKey === "23" ? "Developer" : "Client"} Name is
                      required
                    </span>
                  )}
                </Col>
                <Col xl={8} lg={8} md={8} sm={8} xs={24}>
                  <Row>
                    <Text>E-mail</Text>
                  </Row>
                  <Controller
                    as={Input}
                    name="email"
                    control={control}
                    placeholder="E-mail"
                  />
                  {errors.email && (
                    <span className="error_style">E-mail is required</span>
                  )}
                </Col>
                <Col xl={8} lg={8} md={8} sm={8} xs={24}>
                  <Row>
                    <Text>Reference</Text>
                  </Row>
                  <Controller
                    as={Input}
                    name="reference"
                    control={control}
                    placeholder="Reference"
                  />
                  {errors.reference && (
                    <span className="error_style">Reference is required</span>
                  )}
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}></Col>
                <Col xl={8} lg={8} md={8} sm={8} xs={24}>
                  <Row>
                    <Text>Mobile number</Text>
                  </Row>
                  <Controller
                    as={Input}
                    name="mobile_number"
                    control={control}
                    placeholder="Mobile number"
                    minLength={10}
                    maxLength={10}
                  />
                  {errors.mobile_number && (
                    <span className="error_style">
                      Mobile number is required
                    </span>
                  )}
                </Col>
                <Col xl={8} lg={8} md={8} sm={8} xs={24}>
                  <Row>
                    <Text>Mobile 2</Text>
                  </Row>
                  <Controller
                    as={Input}
                    name="home_number"
                    control={control}
                    placeholder="Mobile 2"
                  />
                  {errors.home_number && (
                    <span className="error_style">Home number is required</span>
                  )}
                </Col>
                <Col xl={8} lg={8} md={8} sm={8} xs={24}>
                  <Row>
                    <Text>Office number</Text>
                  </Row>
                  <Controller
                    as={Input}
                    name="office_number"
                    control={control}
                    placeholder="Office number"
                  />
                  {errors.office_number && (
                    <span className="error_style">
                      Office number is required
                    </span>
                  )}
                </Col>
              </Row>
            </>
          )}
          <Row gutter={[16, 16]}>
            <Col span={24}></Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Row>
                <Text>Address</Text>
              </Row>
              <Controller
                as={Input}
                name="address"
                control={control}
                placeholder="Address"
              />
              {errors.address && (
                <span className="error_style">Address is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}></Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={24}>
              <Row>
                <Text>Area</Text>
              </Row>
              <Controller
                as={Input}
                name="area"
                control={control}
                placeholder="Area"
              />
              {errors.area && (
                <span className="error_style">Area is required</span>
              )}
            </Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={24}>
              <Row>
                <Text>Landmark</Text>
              </Row>
              <Controller
                as={Input}
                name="landmark"
                control={control}
                placeholder="Landmark"
              />
              {errors.landmark && (
                <span className="error_style">Landmark is required</span>
              )}
            </Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={24}>
              <Row>
                <Text>City</Text>
              </Row>
              <Controller
                as={Input}
                name="city"
                control={control}
                placeholder="City"
              />
              {errors.city && (
                <span className="error_style">City is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}></Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={24}>
              <Row>
                <Text>Business</Text>
              </Row>
              <Controller
                as={Input}
                name="business"
                control={control}
                placeholder="Business"
              />
              {errors.business && (
                <span className="error_style">Business is required</span>
              )}
            </Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={24}>
              <Row>
                <Text>Designation</Text>
              </Row>
              <Controller
                as={Input}
                name="designation"
                control={control}
                placeholder="Designation"
              />
              {errors.designation && (
                <span className="error_style">Designation is required</span>
              )}
            </Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={24}>
              <Row>
                <Text>Company</Text>
              </Row>
              <Controller
                as={Input}
                name="company"
                control={control}
                placeholder="Company"
              />
              {errors.company && (
                <span className="error_style">Company is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}></Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={24}>
              <Row>
                <Text>Birthday</Text>
              </Row>
              <Controller
                as={Input}
                name="date_of_birth"
                format="DD-MM-YYYY"
                control={control}
                placeholder="Birthday"
                type="date"
              />

              {errors.date_of_birth && (
                <span className="error_style">Birthday is required</span>
              )}
            </Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={24}>
              <Row>
                <Text>Marriage anniversary</Text>
              </Row>
              <Controller
                as={Input}
                name="date_of_anniversary"
                format="DD-MM-YYYY"
                control={control}
                placeholder="Marriage anniversary"
                type="date"
              />
              {errors.date_of_anniversary && (
                <span className="error_style">
                  Marriage anniversary is required
                </span>
              )}
            </Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={24}>
              <Row>
                <Text>Created At</Text>
              </Row>
              <Controller
                as={Input}
                name="created_at"
                format="DD-MM-YYYY"
                control={control}
                placeholder="Created Date"
                type="date"
              />
              {errors.created_at && (
                <span className="error_style">Created Date is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}></Col>
            <Col xl={8} lg={8} md={8} sm={24} xs={24}>
              <Row>
                <Text>Gender</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                options={Gender}
                style={{ width: "100%" }}
                name="gender"
              />
              {errors.gender && (
                <span className="error_style">Gender is required</span>
              )}
            </Col>
            {itemKey === "23" ? (
              ""
            ) : (
              <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                <Row>
                  <Text>Client Type</Text>
                </Row>
                <Controller
                  as={Select}
                  control={control}
                  options={ClientType}
                  style={{ width: "100%" }}
                  name="client_type"
                />
                {errors.client_type && (
                  <span className="error_style">Client Type is required</span>
                )}
              </Col>
            )}
            <Col xl={8} lg={8} md={8} sm={24} xs={24}>
              <Row>
                <Text>Property Type</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                options={PropertyType}
                name="property_type"
                style={{ width: "100%" }}
              />
              {errors.property_type && (
                <span className="error_style">Property Type is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}></Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Row>
                <Text>Note</Text>
              </Row>
              <Controller
                as={TextArea}
                name="note"
                control={control}
                rows={4}
                showCount
                maxLength={100}
              />
              {errors.note && (
                <span className="error_style">Note is required</span>
              )}
            </Col>
          </Row>
          <Row justify="end" gutter={[0, 16]}>
            <Col span={24}></Col>
            <Col>
              {isUpdateClientLoading ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 24,
                      }}
                      spin
                    />
                  }
                />
              ) : (
                <Button type="primary" htmlType="submit" disabled={!isDirty}>
                  Update {itemKey === "23" ? "Developer" : "Client"}
                </Button>
              )}
            </Col>
          </Row>
        </form>
      </Modal>
    </>
  );
};
