import API from "../Utils/API";

export const getAllLeads = (url, successCallback) => (dispatch) => {
  dispatch({ type: "GET_LEADS_REQUEST" });
  API.get(url)
    .then((response) => {
      dispatch({ type: "GET_LEADS_SUCCESS", payload: response?.data });
      successCallback && successCallback(response?.data);
    })
    .catch((error) => {
      dispatch({ type: "GET_LEADS_FAILURE", payload: error.response?.data });
    });
};

export const createLead =
  (data, url, successCreateLead, failureCreateLead) => (dispatch) => {
    dispatch({ type: "CREATE_LEAD_REQUEST", payload: data });
    API.post("/leads", data)
      .then((response) => {
        dispatch({ type: "CREATE_LEAD_SUCCESS", payload: response });
        // dispatch(getAllLeads(url));
        const SLA = successCreateLead ? successCreateLead() : null;
      })
      .catch((error) => {
        try {
          dispatch({
            type: "CREATE_LEAD_FAILURE",
            payload: error.response.data,
          });

          const FLA = failureCreateLead ? failureCreateLead() : null;
        } catch {}
      });
  };
//         .catch(error => {
//             dispatch({ type: "CREATE_LEAD_FAILURE", payload: error.response.data })
//             const FCP = failureCallBack ? failureCallBack() : null;
//         })
// }

export const getleadtype =
  (successCreateLead, failureCreateLead) => (dispatch) => {
    dispatch({ type: "GET_LEAD_TYPES_REQUEST" });
    API.get("/get_lead_types")
      .then((response) => {
        dispatch({ type: "GET_LEAD_TYPES_SUCCESS", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_LEAD_TYPES_FAILURE",
          payload: error.response?.data,
        });
      });
  };
export const updateLead =
  (data, url, successUpdateLead, failureUpdateLead) => (dispatch) => {
    dispatch({ type: "UPDATE_LEAD_REQUEST", payload: data });
    API.put(`/leads/${data.id}`, data)
      .then((response) => {
        dispatch({ type: "UPDATE_LEAD_SUCCESS", payload: response });
        // dispatch(getAllLeads(url));
        const SLA = successUpdateLead ? successUpdateLead() : null;
      })
      .catch((error) => {
        try {
          dispatch({
            type: "UPDATE_LEAD_FAILURE",
            payload: error.response.data,
          });

          const FLA = failureUpdateLead ? failureUpdateLead() : null;
        } catch {}
      });
  };
//         .catch(error => {
//             dispatch({ type: "UPDATE_LEAD_FAILURE", payload: error.response.data })
//             const FCP = failureCallBack ? failureCallBack() : null;
//         })
// }

export const deleteLead =
  (lead_id, url, successDeleteLead, failureDeleteLead) => (dispatch) => {
    dispatch({ type: "DELETE_LEAD_REQUEST" });
    API.delete(`/leads/${lead_id}`)
      .then((response) => {
        dispatch({ type: "DELETE_LEAD_SUCCESS", payload: response });
        // dispatch(getAllLeads(url));
        const SLA = successDeleteLead ? successDeleteLead() : null;
      })
      .catch((error) => {
        try {
          dispatch({
            type: "DELETE_LEAD_FAILURE",
            payload: error.response.data,
          });

          const FLA = failureDeleteLead ? failureDeleteLead() : null;
        } catch {}
      });
  };
//         .catch(error => {
//             dispatch({ type: "DELETE_LEAD_FAILURE", payload: error.response.data })
//             const FCP = failureCallBack ? failureCallBack() : null;
//         })
// }
