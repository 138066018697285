import React, { useState } from "react";
import { WhatsAppOutlined } from "@ant-design/icons";
import { Button } from "antd";

const WhatsAppBubble = () => {
  const [isOpen, setIsOpen] = useState(false);
  const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER;
  const defaultMessage = process.env.REACT_APP_WHATSAPP_MESSAGE;

  const handleChatClick = () => {
    const encodedMessage = encodeURIComponent(defaultMessage);
    window.open(
      `https://wa.me/${whatsappNumber}?text=${encodedMessage}`,
      "_blank"
    );
  };

  return (
    <div style={styles.container}>
      <div
        style={styles.whatsappButton}
        onClick={() => setIsOpen(!isOpen)}
      >
        <WhatsAppOutlined style={styles.icon} />
      </div>

      {isOpen && (
        <div style={styles.chatBox}>
          <div style={styles.chatHeader}>
            <span>Need help? Chat with us.</span>
            
          </div>
          <div style={styles.chatBody}>
            <Button style={styles.chatButton} onClick={handleChatClick}>
              <WhatsAppOutlined style={{ marginRight: 8, color:'#2DB742' }} />
              SVMD
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    position: "fixed",
    bottom: "25px",
    right: "25px",
    zIndex: 1000,
  },
  whatsappButton: {
    width: "55px",
    height: "55px",
    backgroundColor: "#2DB742",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
    cursor: "pointer",
  },
  icon: {
    fontSize: "28px",
    color: "#fff",
  },
  chatBox: {
    width: "250px",
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
    position: "absolute",
    bottom: "70px",
    right: "0",
    overflow: "hidden",
  },
  chatHeader: {
    backgroundColor: "#2DB742",
    color: "#fff",
    padding: "15px",
    fontSize: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily:'Roboto-500'
  },
  chatBody: {
    padding: "15px",
    display: "flex",
    justifyContent: "left",
  },
  chatButton: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    fontSize: "16px",
    fontFamily:'Roboto-400',
    backgroundColor: "#fff",
    border: "1px solid #e0e0e0",
    padding:'20px 15px',
    color:'#000'
  },
};

export default WhatsAppBubble;
