import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { store, persistor } from "./Utils/store";
import { PersistGate } from "redux-persist/integration/react";
import { Spin } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { Switch, BrowserRouter as Router, Route, useLocation } from "react-router-dom";
import { PrivateRoute } from "./Utils/AppRoute";
import AppView from "./components/AppView";
import Landing from "./pages/Landing";
import NewProperty from "./pages/newProperty";
import WhatsAppBubble from "./components/WhatsappBubble";

function AppContent() {
  const location = useLocation(); // Now inside Router
  const showWhatsApp = ["/", "/post", "/admin"].includes(location.pathname);

  return (
    <>
      <Route exact path="/" component={Landing} layout={Landing} />
      <Route exact path="/admin" component={Landing} layout={Landing} />
      <Route exact path="/post" component={NewProperty} layout={NewProperty} />
      <PrivateRoute exact path="/app" component={AppView} />

      {showWhatsApp && <WhatsAppBubble />}
    </>
  );
}

function App() {
  const { isLoading } = useAuth0(); 

  useEffect(() => {
    if (process.env.REACT_APP_NEW_RELIC_ENABLED === "true") {
      const script = document.createElement("script");
      script.src = "/newrelic.js"; 
      script.async = true;
      document.head.appendChild(script);
    }
  }, []);

  if (isLoading)
    return (
      <Spin
        size="large"
        spinning={isLoading}
        style={{
          margin: "auto",
          marginTop: 200,
          width: "100%",
        }}
      />
    );

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <AppContent />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
