import API from '../Utils/API';

export const getAllNotification = () => dispatch => {
    dispatch({ type: "GET_NOTIFICATIONS_REQUEST" })
    API.get('/notifications')
        .then(response => {
            dispatch({ type: "GET_NOTIFICATIONS_SUCCESS", payload: response.data })
        })
        .catch(error => {
            dispatch({ type: "GET_NOTIFICATIONS_FAILURE", payload: error.response?.data })
        })
}

export const createNotification = (data) => dispatch => {
    dispatch({ type: "CREATE_NOTIFICATION_REQUEST", payload: data })
    API.post('/notifications', data)
        .then(response => {
            dispatch({ type: "CREATE_NOTIFICATION_SUCCESS", payload: response })
            dispatch(getAllNotification())
        })
        .catch(error => {
            dispatch({ type: "CREATE_NOTIFICATION_FAILURE", payload: error.response.data })
        })
}

export const updateNotification = (data) => dispatch => {
    dispatch({ type: "UPDATE_NOTIFICATION_REQUEST", payload: data })
    API.put(`/notifications/${data.id}`, data)
        .then(response => {
            dispatch({ type: "UPDATE_NOTIFICATION_SUCCESS", payload: response })
            dispatch(getAllNotification())
        })
        .catch(error => {
            dispatch({ type: "UPDATE_NOTIFICATION_FAILURE", payload: error.response.data })
        })
}

export const deleteNotification = (notification_id) => dispatch => {
    dispatch({ type: "DELETE_NOTIFICATION_REQUEST" })
    API.delete(`/notifications/${notification_id}`)
        .then(response => {
            dispatch({ type: "DELETE_NOTIFICATION_SUCCESS", payload: response })
            dispatch(getAllNotification())
        })
        .catch(error => {
            dispatch({ type: "DELETE_NOTIFICATION_FAILURE", payload: error.response.data })
        })
}