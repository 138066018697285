import React from "react";
import { Row, Col, Typography, Image, Card, Divider, Carousel } from "antd";
import no_image from "../assets/images/no_image.png";
import location2 from "../assets/images/location2.svg";
import site from "../assets/images/site.svg";
import price from "../assets/images/price.svg";
import areaSquare from "../assets/images/area.svg";
import whatsapp from "../assets/images/whatsapp.svg";
import googleMap from "../assets/images/googleMap.svg";
import "../components/propertycard.css";
const ProjectCard = (props) => {
  const {
    images,
    tag_line,
    builder,
    area,
    property_name,
    parking,
    description,
    project_type,
    google_link,
  } = props.project;
  const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER;
  const defaultMessage = process.env.REACT_APP_WHATSAPP_MESSAGE;
  const image = images && images.length > 0 ? images[0].image_url : null;

  // const description2 = [
  //   {
  //     key: "3BHK Flat",
  //     value: "2500sqft",
  //     google_link:'https://maps.app.goo.gl/sCznTtZ7VHTKkEBv6'
  //   },
  //   {
  //     key: "3BHK Flat",
  //     value: "2500sqft",
  //     google_link:'https://maps.app.goo.gl/NtzXyZgc7xy6EtqCA'
  //   },
  //   {
  //     key: "3BHK Flat",
  //     value: "2500sqft",
  //     google_link:'https://maps.app.goo.gl/sCznTtZ7VHTKkEBv6'
  //   },
  //   {
  //     key: "3BHK Flat",
  //     value: "2500sqft",
  //     google_link:'https://maps.app.goo.gl/sCznTtZ7VHTKkEBv6'
  //   },
  //   {
  //     key: "3BHK Flat",
  //     value: "2500sqft",
  //     google_link:'https://maps.app.goo.gl/NtzXyZgc7xy6EtqCA'
  //   },
  // ];

  const openWhatsApp = () => {
    const encodedMessage = encodeURIComponent(defaultMessage);
    window.open(
      `https://wa.me/${whatsappNumber}?text=${encodedMessage}`,
      "_blank"
    );
  };

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <div style={{ padding: 10, display:'flex', justifyContent:'center' }}>
      <Card
        className="pCard"
        bodyStyle={{ padding: 10, }}
        hoverable
        cover={
          <div>
            <Carousel autoplay>
              {Array.isArray(images) && images.length > 0 ? (
                images?.map((image, index) => (
                  <div key={index}>
                    <img
                      alt={`image-${index}`}
                      src={image.image_url.url || no_image}
                      title="All Images"
                      height={250}
                      width="100%"
                      className="projectImage"
                    />
                  </div>
                ))
              ) : (
                <div>
                  <img
                    alt="no_image"
                    src={no_image}
                    title="View All Images"
                    width="100%"
                    height={250}
                    className="projectImage"
                  />
                </div>
              )}
            </Carousel>
          </div>
        }
      >
        <Row gutter={[0, 4]}>
          <Col span={24}>
            <Typography className="typography1">{builder}</Typography>
          </Col>
          <Col className="custom-scrollbar">
            <Row>
              <Col span={24}>
                <Typography className="propertyServicesText">
                  <span className="projectLocation">
                    <img src={location2} alt="location" />
                    {area && area.name}
                  </span>
                </Typography>
              </Col>

          <Col span={24}>
                <Typography className="propertyServicesText">
                  <span className="projectLocation">
                    <img src={price} alt="Price" /> {property_name}
                  </span>
                </Typography>
              </Col>

              {description && description?.description.length > 0 && (
                <Col span={24}>
                  <Row
                    style={{
                      alignItems: "flex-start",
                      display: "flex",
                    }}
                  >
                    <img
                      src={areaSquare}
                      alt="area"
                      style={{
                        paddingTop: "3px",
                        marginRight: "8px",
                      }}
                    />
                    <span>
                      {description?.description?.map((d, i) => (
                        <Col
                          key={i}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                          span={24}
                        >
                          <Typography className="propertyServicesText">
                            {d.key}
                          </Typography>
                          <Typography className="propertyServicesText">
                            {d.value}{" "}
                          </Typography>
                        </Col>
                      ))}
                    </span>
                  </Row>
                </Col>
              )}
              <Col span={24}>
                <Typography className="propertyServicesText">
                  <span className="projectLocation">
                    <img src={site} /> {tag_line}
                  </span>{" "}
                </Typography>
              </Col>
            </Row>
          </Col>
          <Divider style={{ margin: "10px 0px" }} />
          <Col span={24}>
            <Row justify="center">
              <Col style={{ marginRight: "10px" }}>
                <img
                  src={whatsapp}
                  alt="Whatsapp"
                  onClick={() => openWhatsApp()}
                />
              </Col>
              {isValidUrl(google_link) ? (
                <Col>
                  <img
                    src={googleMap}
                    alt="Google Map"
                    onClick={() => window.open(google_link, "_blank")}
                    style={{ cursor: "pointer" }}
                  />
                </Col>
              ) : null}
            </Row>
          </Col>
        </Row>
        {/* <Row justify="space-between">
          <Col>
            { description && description.description && (
            <Row>
              <img src={Sqft} />
              {description.description.map((d,i) => 
              <Typography className="semi_furnished">{d.value}</Typography>
              )}
            </Row>
            )}
          </Col>
          <Col>
            {project_type === "Residential" ? (
              <Row>
                <img src={Allot} alt="" />
                <Typography className="semi_furnished"> {parking}</Typography>
              </Row>
            ) : null}
          </Col>
        </Row> */}
      </Card>
    </div>
  );
};

export default ProjectCard;
