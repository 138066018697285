/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllProjects, getProjectCount } from "../Actions/projectsAction";

import {
  Layout,
  Typography,
  Spin,
} from "antd";
import "./styles/navbar.css";
import HomeFooter from "../components/Footer";
import HomeHeader from "../components/Header";
import BannerImage from "../components/BannerImage";
import Properties from "../components/Properties";
import ProjectGallery from "../components/ProjectGallery";
import Projects from "../components/Projects";
import AdvanceSearch from "../components/advanceSearch";
import AboutUs from "../components/AboutUs";
import PropertyServices from "../components/PropertyServices";
import { getAllAreas } from "../Actions/areasAction";

const PropertyType = [
  { value: 0, label: "Flat" },
  { value: 1, label: "Pent House" },
  { value: 2, label: "Bunglow" },
  { value: 3, label: "Tenament" },
  { value: 4, label: "Duplex" },
  { value: 5, label: "Row House" },
  { value: 6, label: "Fam House" },
  { value: 7, label: "Office" },
  { value: 8, label: "Shop" },
  { value: 9, label: "Showroom" },
  { value: 10, label: "Godown" },
  { value: 11, label: "Wear House" },
  { value: 12, label: "Residential" },
  { value: 13, label: "Commercial" },
  { value: 14, label: "Agriculture" },
  { value: 15, label: "NonAgriculture" },
  { value: 16, label: "Redevelopment" },
];
const { Text } = Typography;
const { Header, Content, Footer } = Layout;
const Landing = () => {
  const dispatch = useDispatch();
  const { isGetPostPropertiesLoading, postProperties } = useSelector(
    (state) => state.postProperties
  );
  const { areas } = useSelector((state) => state.areas);
  const [advSearch, setAdvanceSearch] = useState(false);

  const {
    projects,
    residentialProject,
    commercialProject,
    landProject,
    landProjectPreLaunch,
    residentialPreLaunch,
    commercialPreLaunch,
    preLeaseProjects,
    allProjects,
    isGetProjectsLoading,
  } = useSelector((state) => {
    const {
      projects,
      residentialProject,
      commercialProject,
      landProject,
      landProjectPreLaunch,
      residentialPreLaunch,
      commercialPreLaunch,
      preLeaseProjects,
      isGetProjectsLoading,
      allProjects
    } = state.projects;
    return {
      projects,
      residentialProject,
      commercialProject,
      landProject,
      landProjectPreLaunch,
      residentialPreLaunch,
      commercialPreLaunch,
      preLeaseProjects,
      isGetProjectsLoading,
      allProjects
    };
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    dispatch(getAllProjects("type=Residential"));
    dispatch(getAllProjects(`type=Residential&pre_launch=true`));
    dispatch(getAllProjects(`type=Commercial`));
    dispatch(getAllProjects(`type=Commercial&pre_launch=true`));
    dispatch(getAllProjects("type=Land"));
    dispatch(getAllProjects("type=Land&pre_launch=true"));
    dispatch(getAllProjects("pre_lease=true"));
    dispatch(getAllAreas());
    dispatch(getProjectCount())
  }, []);

  const projectsData = [
 
    {
      title: "Plot & villa for sale",
      projects: landProject,
    },
    {
      title: "Plot & villa for resell",
      projects: landProjectPreLaunch,
    },
    {
      title: "Residential property for sale",
      projects: residentialProject,
    },
    {
      title: "Residential property pre-launch",
      projects: residentialPreLaunch,
    },
    {
      title: "Commercial property pre-launch",
      projects: commercialPreLaunch,
    },
    {
      title: "Commercial property for sale",
      projects: commercialProject,
    },
    {
      title: "Pre-lease property for sale",
      projects: preLeaseProjects,
    },
  ];
  const scrollToSection = (sectionId) => {
    const targetElement = document.getElementById(sectionId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Layout>
        <Header className="header">
          <HomeHeader onScrollToSection={scrollToSection} />
        </Header>

        <Content>
          <BannerImage
            advSearch={advSearch}
            setAdvanceSearch={setAdvanceSearch}
          />
          {advSearch && <AdvanceSearch></AdvanceSearch>}
          {/* <Properties /> */}
          {/* <ProjectGallery title="Project Gallery" refTag={buyRef} /> */}
          <Spin spinning={isGetProjectsLoading}>
            <div style={{ paddingTop: "80px" }}>
              {projectsData.map(({ title, projects }) => {
                return <Projects title={title} projects={projects} />;
              })}
            </div>
          </Spin>
          {/* <ProjectGallery title="Sell" refTag={sellRef}/> */}
          {/* <ProjectGallery title="Rent" refTag={rentRef} /> */}
          <PropertyServices />
          <AboutUs areas={areas} allProjects={allProjects} />
        </Content>

        <HomeFooter />
      </Layout>
    </>
  );
};
export default Landing;
