import { useEffect } from "react";
import debounce from "lodash/debounce";

const useResizeObserver = (callback, delay = 100) => {
  useEffect(() => {
    const debouncedResize = debounce(callback, delay);
    const resizeObserver = new ResizeObserver(debouncedResize);

    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.disconnect();
    };
  }, [callback, delay]);
};

export default useResizeObserver;
