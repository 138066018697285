import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Input,
  Typography,
  Button,
  Modal,
  Checkbox,
  Select,
  InputNumber,
  Steps,
  Divider,
  Row,
} from "antd";
// import {  EditTwoTone } from "@ant-design/icons";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { updatePropery } from "../Actions/propertiesActions";
import "./styles/clients.css";
import moment from "moment";
const PropertyType = [
  { value: 0, label: "Flat" },
  { value: 1, label: "Pent House" },
  { value: 2, label: "Bunglow" },
  { value: 3, label: "Tenament" },
  { value: 4, label: "Duplex" },
  { value: 5, label: "Row House" },
  { value: 6, label: "Fam House" },
  { value: 7, label: "Office" },
  { value: 8, label: "Shop" },
  { value: 9, label: "Showroom" },
  { value: 10, label: "Godown" },
  { value: 11, label: "Wear House" },
  { value: 12, label: "Residential" },
  { value: 13, label: "Commercial" },
  { value: 14, label: "Agriculture" },
  { value: 15, label: "NonAgriculture" },
  { value: 16, label: "Redevelopment" },
];

const { Step } = Steps;
const steps = [
  {
    title: "Personal Details",
    // content: 'First-content',
  },
  {
    title: "Property Details",
    // content: 'Second-content',
  },
  {
    title: "Amenity",
    // content: 'Last-content',
  },
];

const schema = Yup.object().shape({
  client_id: Yup.string().required("Required"),
  area_id: Yup.string().required("Required"),
  // property_type: Yup.number().transform(value => (isNaN(value) ? undefined : value)).required("Required"),
  cost: Yup.string().required("Required"),
  // gender: yup.string().required("Required"),
  // dete_of_birth: yup.string().required("Required"),
  // date_of_anniversary: yup.string().required("Required"),
  // address: yup.string().required("Required"),
  // landmark: yup.string().required("Required"),
  // area: yup.string().required("Required"),
  // city: yup.string().required("Required"),
  // floor: yup.string().required("Required"),
  // home_number: yup.string().matches(phoneRegExp).required("Required"),
  // office_number: yup.string().matches(phoneRegExp).required("Required"),
  // email: yup.string().email().required("Required"),
  // business: yup.string().required("Required"),
  // company: yup.string().required("Required"),
  // designation: yup.string().required("Required"),
  // reference: yup.string().required("Required"),
  // note: yup.string().required("Required"),
});
const { TextArea } = Input;
const { Text } = Typography;
export const UpdateProperty = ({ id }) => {
  const dispatch = useDispatch();
  const { properties } = useSelector((state) => state.properties);
  const { clients } = useSelector((state) => state.clients);
  const { areas } = useSelector((state) => state.areas);
  const the_property = properties.filter((property) => property.id === id);
  const newDate = the_property[0].created_at
    ? the_property[0].created_at.split("/")
    : {};
  the_property[0].created_at =
    newDate.length > 2
      ? `${newDate[2]}-${newDate[1]}-${newDate[0]}`
      : newDate[0];
  const [open, setOpen] = useState(false);
  the_property[0].area_id = the_property[0].area.id;
  the_property[0].client_id = the_property[0].client.id;
  const { handleSubmit, errors, control, reset, getValues, setValue, trigger } =
    useForm({
      resolver: yupResolver(schema),
      defaultValues: the_property[0],
      mode: "all",
    });
  const watching = getValues([
    "is_residential",
    "is_commercial",
    "is_plot",
    "client_id",
    "area_id",
    "property_type",
    "cost",
  ]);
  const { is_residential, is_commercial, is_plot } = watching;
  const [current, setCurrent] = React.useState(0);

   useEffect(() => {
      if (the_property?.length > 0) {
        const constructionYear = the_property[0].construction_year;
  
        const formattedDate = constructionYear
          ? moment(constructionYear, moment.ISO_8601, true).isValid()
            ? moment(constructionYear).format("YYYY-MM-DD")
            : ""
          : "";
  
        const formattedData = {
          ...the_property[0],
          construction_year: formattedDate,
        };
        reset(formattedData);
      }
    }, [JSON.stringify(the_property), reset]);

  const next = () => {
    const {
      client_id: cid,
      area_id: aid,
      property_type: PT,
      cost: cst,
    } = getValues(["client_id", "area_id", "property_type", "cost"]);
    if (current === 0 && (!cid || !aid || !PT)) {
      trigger(["client_id", "area_id", "property_type"]);
    } else if (current === 1 && !cst) {
      trigger(["cost"]);
    } else {
      // if (Object.keys(errors).length === 0){
      setCurrent(current + 1);
      // }
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const handleUpdateProperty = (data, event) => {
    event.preventDefault();
    data.id = id;
    dispatch(updatePropery(data));
    setOpen(false);
    setCurrent(0);
    reset(data);
  };
  const handleCancel = () => {
    setOpen(false);
    setCurrent(0);
    reset();
  };
  return (
    <>
      <Button
        type="link"
        style={{ cursor: "pointer" }}
        onClick={() => setOpen(true)}
      >
        Edit
      </Button>
      {/* <EditTwoTone
          style={{ cursor: "pointer" }}
          onClick={() => setOpen(true)}
        /> */}
      <Modal
        title="Edit Property details"
        visible={open}
        onCancel={() => handleCancel()}
        footer={null}
        width={800}
        maskClosable={false}
      >
        <Steps
          current={current}
          size="small"
          responsive="true"
          labelPlacement="vertical"
        >
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <form onSubmit={handleSubmit(handleUpdateProperty)}>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Client</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                options={
                  clients &&
                  clients.length > 0 &&
                  clients.map((val, index) => {
                    return { label: val.name, value: val.id };
                  })
                }
                style={{ width: "100%" }}
                name="client_id"
              />
              {errors.client_id && (
                <span className="error_style">Client is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Property Type</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                options={PropertyType}
                name="property_type"
                style={{ width: "100%" }}
              />
              {errors.property_type && (
                <span className="error_style">Property Type is required</span>
              )}
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Flat/Bunglow/Office No.</Text>
              </Row>
              <Controller
                as={Input}
                name="unique_number"
                control={control}
                placeholder="Flat/Bunglow/Office No."
              />
              {errors.unique_number && (
                <span className="error_style">
                  Flat/Bunglow/Office No is required
                </span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Address</Text>
              </Row>
              <Controller
                as={Input}
                name="address"
                control={control}
                placeholder="Address"
              />
              {errors.unique_number && (
                <span className="error_style">Address is required</span>
              )}
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Landmark</Text>
              </Row>
              <Controller
                as={Input}
                name="landmark"
                control={control}
                placeholder="Landmark"
              />
              {errors.landmark && (
                <span className="error_style">Landmark is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Area</Text>
              </Row>
              <Controller
                as={Select}
                control={control}
                options={
                  areas &&
                  areas.length > 0 &&
                  areas.map((val, index) => {
                    return { label: val.name, value: val.id };
                  })
                }
                style={{ width: "100%" }}
                name="area_id"
              />
              {errors.area_id && (
                <span className="error_style">Area is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Google Link</Text>
              </Row>
              <Controller
                as={Input}
                name="google_link"
                control={control}
                placeholder="City"
              />
              {errors.unique_number && (
                <span className="error_style">City is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Entry Date</Text>
              </Row>
              <Controller
                as={Input}
                name="created_at"
                format="DD-MM-YYYY"
                control={control}
                placeholder="Entry Date"
                type="date"
              />
              {errors.created_at && (
                <span className="error_style">Entry Date is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>is Residential?</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_residential"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => {
                          setValue("is_commercial", false);
                          setValue("is_plot", false);
                          return props.onChange(e.target.checked);
                        }}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>is Commercial?</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_commercial"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => {
                          setValue("is_residential", false);
                          setValue("is_plot", false);
                          return props.onChange(e.target.checked);
                        }}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{ display: current === 0 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>is Plot?</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_plot"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => {
                          setValue("is_residential", false);
                          setValue("is_commercial", false);
                          return props.onChange(e.target.checked);
                        }}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display:
                  current === 1 && is_residential === true ? "block" : "none",
              }}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Bhk</Text>
              </Row>
              <Controller
                as={Input}
                name="bhk"
                control={control}
                placeholder="Total Bhk"
              />
              {errors.bhk && (
                <span className="error_style">Bhk is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display:
                  current === 1 && is_residential === true ? "block" : "none",
              }}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Floor</Text>
              </Row>
              <Controller
                as={Input}
                name="floor"
                control={control}
                placeholder="Floor"
                type="number"
              />
              {errors.floor && (
                <span className="error_style">Floor is required</span>
              )}
            </Col>
            <Col
              style={{
                display:
                  current === 1 && is_residential === true ? "block" : "none",
              }}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Total Floor</Text>
              </Row>
              <Controller
                as={Input}
                name="total_floor"
                control={control}
                placeholder="Total Floor"
                type="number"
              />
              {errors.total_floor && (
                <span className="error_style">Total Floor is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display: current === 1 ? "block" : "none",
              }}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Cost</Text>
              </Row>
              <Controller
                name="cost"
                control={control}
                render={(props) => (
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    {...props}
                  />
                )}
              />
              {errors.cost && (
                <span className="error_style">Cost is required</span>
              )}
            </Col>
            <Col
              style={{
                display:
                  current === 1 && is_residential === true ? "block" : "none",
              }}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Society Transfer Fee</Text>
              </Row>
              <Controller
                name="society_transfer_fee"
                control={control}
                render={(props) => (
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    {...props}
                  />
                )}
              />
              {errors.society_transfer_fee && (
                <span className="error_style">
                  Society Transfer Fee is required
                </span>
              )}
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display:
                  current === 1 && is_residential === true ? "block" : "none",
              }}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>is Fix?</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_fix"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display:
                  current === 1 && is_residential === true ? "block" : "none",
              }}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>is Negotiable?</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_negotiable"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Construction Year</Text>
              </Row>
              <Controller
                as={Input}
                name="construction_year"
                control={control}
                placeholder="Construction Year"
                type="date"
              />
              {errors.construction_year && (
                <span className="error_style">
                  Construction Year is required
                </span>
              )}
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Visit Time</Text>
              </Row>
              <Controller
                as={Input}
                name="visit_time"
                control={control}
                placeholder="Visit Time"
                type="time"
                // style={{ width: 200 }}
              />
              {errors.visit_time && (
                <span className="error_style">Visit Time is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>About Key</Text>
              </Row>
              <Controller
                as={Input}
                name="about_key"
                control={control}
                placeholder="About Key"
              />
              {errors.about_key && (
                <span className="error_style">About Key is required</span>
              )}
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Vastu Complient</Text>
              </Row>
              <Controller
                as={Input}
                name="vastu_complient"
                control={control}
                placeholder="Vastu Complient"
                type="number"
              />
              {errors.vastu_complient && (
                <span className="error_style">Vastu Complient is required</span>
              )}
            </Col>
          </Row>

          <Row gutter={[0, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>is New?</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_new"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>is Resell?</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_resell"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>is Rent?</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_rent"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>is Lease?</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_lease"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>

            <Col
              style={{
                display:
                  current === 1 && is_commercial === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>is Furnished?</Text>
                </Col>
                <Col>
                  <Controller
                    name="is_furnished"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
            >
              <Row>
                <Text>Facing</Text>
              </Row>
              <Controller
                as={Input}
                name="facing"
                control={control}
                placeholder="Facing"
              />
              {errors.facing && (
                <span className="error_style">Facing is required</span>
              )}
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 1 ? "block" : "none" }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="notes_box"
            >
              <Row>
                <Text>Details</Text>
              </Row>
              <Controller
                as={TextArea}
                name="details"
                control={control}
                rows={4}
                showCount
                maxLength={100}
              />
              {errors.details && (
                <span className="error_style">Details is required</span>
              )}
            </Col>
          </Row>

          {/* <Button
              type="primary"
              htmlType="submit"
              block
              style={{ top: 10 }}
            >
              Create Property
            </Button> */}
          <Row>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Divider plain>Property Furniture</Divider>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Fix Kitchen?</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_furniture_attributes[fix_kitchen]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Modular Kitchen?</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_furniture_attributes[mod_kitchen]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Fix Drawing Room?</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_furniture_attributes[fix_drawing_room]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[1, 0]}>
                <Col>
                  <Text>Modular Drawing Room?</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_furniture_attributes[mod_drawing_room]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Fix Bedroom?</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_furniture_attributes[fix_bedroom]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Modular Bedroom?</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_furniture_attributes[mod_bedroom]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Air Condition?</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_furniture_attributes[air_condition]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Divider plain>Property Amenity</Divider>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Water 24hr?</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[water_24hr]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Png?</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[png]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Security?</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[security]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>cctv?</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[cctv]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Lift?</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[lift]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Common Parking?</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[common_parking]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Paid parking?</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[paid_parking]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Garden?</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[garden]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Children Play Area?</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[children_play_area]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Club House?</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[club_house]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display:
                  current === 2 && is_residential === true ? "block" : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Gymnasium?</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[gymnasium]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Common Plot?</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_amenity_attributes[common_plot]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Divider plain>Property Nearby</Divider>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Religious Place?</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_nearby_attributes[religious_place]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Park Garden?</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_nearby_attributes[park_garden]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Educational Institute?</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_nearby_attributes[educational_institute]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Hospital/Dispansary?</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_nearby_attributes[hospital_dispansary]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Mall/market?</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_nearby_attributes[mall_market]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display:
                  current === 2 &&
                  (is_residential === true || is_commercial === true)
                    ? "block"
                    : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row gutter={[8, 0]}>
                <Col>
                  <Text>Bus Stop?</Text>
                </Col>
                <Col>
                  <Controller
                    name="property_nearby_attributes[bus_stop]"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col
              style={{ display: current === 2 ? "block" : "none" }}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Divider plain>Property Area</Divider>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{
                display:
                  current === 2 && (is_residential === true || is_plot === true)
                    ? "block"
                    : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Super Plot Area SqFt</Text>
              </Row>
              <Controller
                as={Input}
                name="property_area_attributes[super_plot_area_sqFt]"
                type="number"
                control={control}
                placeholder="Super Plot Area SqFt"
              />
            </Col>
            <Col
              style={{ display: current === 2 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Super Builtup Area SqFt</Text>
              </Row>
              <Controller
                as={Input}
                name="property_area_attributes[super_builtup_area_sqFt]"
                type="number"
                control={control}
                placeholder="Super Builtup Area SqFt"
              />
            </Col>
            <Col
              style={{ display: current === 2 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Less SqFt</Text>
              </Row>
              <Controller
                as={Input}
                name="property_area_attributes[less_sqFt]"
                type="number"
                control={control}
                placeholder="Less SqFt"
              />
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}></Col>
            <Col
              style={{ display: current === 2 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Builtup Area SqFt</Text>
              </Row>
              <Controller
                as={Input}
                name="property_area_attributes[builtup_area_sqFt]"
                type="number"
                control={control}
                placeholder="Builtup Area SqFt"
              />
            </Col>
            <Col
              style={{ display: current === 2 ? "block" : "none" }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Carpet Area SqFt</Text>
              </Row>
              <Controller
                as={Input}
                name="property_area_attributes[carpet_area_sqFt]"
                type="number"
                control={control}
                placeholder="Carpet Area SqFt"
              />
            </Col>
            <Col
              style={{
                display: current === 2 && is_plot === true ? "block" : "none",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Row>
                <Text>Land Area SqFt</Text>
              </Row>
              <Controller
                as={Input}
                name="property_area_attributes[land_area_sqFt]"
                type="number"
                control={control}
                placeholder="Land Area SqFt"
              />
            </Col>
          </Row>
          <Row justify="end" gutter={[8, 16]}>
            <Col span={24}></Col>
            {current > 0 && (
              <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                Previous
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            )}
          </Row>
        </form>
      </Modal>
    </>
  );
};
